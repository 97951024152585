import Hero from "../components/Hero";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BlogList from "../components/BlogList";
import UpdatesPreview from "../components/UpdatesPreview";
import AboutPreview from "../components/AboutPreview";
import WaitlistPreview from "../components/WaitlistPreview";
import PreviewFooter from "../components/PreviewFooter";
import PageFooter from "../components/PageFooter";

const Home = () => {
	return (
		<>
			<div className="content">
				<Hero />
				<Container className="pt-5 pb-5">
					<Row>
						<Col className="col-lg-8 col-12">
							<BlogList />
						</Col>
						<Col className="preview-col m-hidden col-lg-4 col-12">
							<UpdatesPreview />
							<AboutPreview />
							<WaitlistPreview />
							<PreviewFooter />
						</Col>
					</Row>
				</Container>
			</div>
			<PageFooter />
		</>
	);
};

export default Home;
