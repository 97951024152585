const PreviewLightBulb = () => {
	return (
		<svg
			className="about-preview-lightbulb"
			width="53"
			height="45"
			viewBox="0 0 53 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.4649 15.0668C17.3231 17.5181 16.1397 21.7444 15.6494 23.4857C15.5311 23.9083 14.1449 29.0983 16.5454 34.508C17.2554 36.114 18.0162 37.7707 19.7405 38.5653C20.738 39.0386 21.6339 39.0048 25.1502 38.7005C26.7901 38.5653 28.5651 38.3962 30.4585 38.1933C31.7941 36.4859 34.0932 33.071 34.7694 28.2699C34.8877 27.3739 36.274 16.6897 30.4585 13.8496C26.6548 11.9731 22.0735 14.6949 21.4649 15.0668Z"
				fill="#F7D370"
			/>
			<path
				d="M18.7956 37.9737C16.6655 37.1115 15.3807 35.049 14.6031 32.9866C13.7747 30.8396 13.4197 28.5067 13.538 26.2075C13.7409 21.9643 15.5498 17.6703 18.897 14.9655C22.0583 12.3959 26.7411 11.6689 30.2574 14.0019C33.9259 16.4362 34.5852 21.4571 34.619 25.4975C34.6528 29.9943 33.6047 34.7109 30.2743 37.9399C30.0377 38.1596 30.3927 38.5315 30.6294 38.2949C33.7907 35.2181 34.9909 30.8227 35.1093 26.5287C35.2276 22.3193 34.7205 16.9941 31.2549 14.1033C28.009 11.3985 23.191 11.5337 19.7254 13.7314C15.8879 16.1658 13.6226 20.5781 13.1323 25.0242C12.828 27.7797 13.1661 30.6367 14.1805 33.2402C15.0426 35.4379 16.412 37.5341 18.6773 38.447C18.9647 38.5823 19.083 38.092 18.7956 37.9737V37.9737Z"
				fill="#050911"
			/>
			<path
				d="M18.5595 38.2948C17.9847 39.1063 18.103 40.3066 18.9483 40.9152C19.5907 41.3716 20.5374 41.4392 21.2981 41.5407C22.211 41.6759 23.1408 41.7266 24.0706 41.7266C25.7273 41.7097 27.6207 41.5914 29.1591 40.949C30.2749 40.4756 31.1201 39.3599 30.5284 38.1596C30.3932 37.8722 29.9537 38.1258 30.0889 38.4132C31.3568 40.9997 25.3554 41.2195 24.0537 41.2195C22.8534 41.2195 21.6531 41.118 20.4867 40.8983C19.9288 40.7968 19.3033 40.6954 18.9483 40.2051C18.5933 39.698 18.6609 39.0387 18.999 38.5653C19.185 38.2779 18.7454 38.0244 18.5595 38.2948V38.2948Z"
				fill="#050911"
			/>
			<path
				d="M19.1006 41.0335C19.1006 41.8788 19.1175 42.741 19.2189 43.5862C19.2696 43.9751 19.3542 44.3132 19.6754 44.5498C20.1318 44.8879 20.8926 44.871 21.4335 44.9218C22.3464 44.9894 23.2762 44.9894 24.1891 44.9556C25.0513 44.9218 25.9134 44.8541 26.7756 44.7696C27.2828 44.7189 27.8576 44.7358 28.2464 44.347C29.1086 43.5017 29.1255 41.8957 28.9057 40.8307C28.8381 40.5095 28.3478 40.6447 28.4154 40.9659C28.5338 41.5407 28.5676 42.1155 28.4492 42.7072C28.2802 43.5355 28.0435 44.0765 27.1137 44.2455C26.0318 44.4484 24.8146 44.4484 23.7157 44.4653C22.6338 44.4822 21.4166 44.5329 20.3516 44.2794C19.3711 44.0427 19.6247 41.845 19.6247 41.0335C19.6077 40.7123 19.1006 40.7123 19.1006 41.0335V41.0335Z"
				fill="#050911"
			/>
			<path
				d="M19.2698 22.8265C21.2985 24.0944 22.8369 25.954 23.699 28.1855C23.8174 28.4898 24.3076 28.3546 24.1893 28.0503C23.2764 25.6835 21.6704 23.7225 19.5403 22.387C19.2529 22.201 18.9994 22.6406 19.2698 22.8265V22.8265Z"
				fill="#050911"
			/>
			<path
				d="M24.1045 28.1854C25.1019 26.0892 26.5558 24.2972 28.4153 22.911C28.6689 22.725 28.4153 22.2686 28.1618 22.4714C26.2346 23.9084 24.7131 25.768 23.6649 27.9318C23.5297 28.2192 23.9692 28.4728 24.1045 28.1854V28.1854Z"
				fill="#050911"
			/>
			<path
				d="M23.6988 27.5938C23.9523 31.0763 24.0707 34.5588 24.02 38.0413C24.02 38.3625 24.5271 38.3625 24.5271 38.0413C24.5778 34.5588 24.4595 31.0594 24.2059 27.5938C24.1721 27.2557 23.665 27.2557 23.6988 27.5938V27.5938Z"
				fill="#050911"
			/>
			<path
				d="M3.90216 36.1141C6.15057 35.3534 8.38207 34.5927 10.6305 33.8319C10.9348 33.7305 10.7995 33.2402 10.4952 33.3417C8.24683 34.1024 6.01533 34.8631 3.76691 35.6239C3.44571 35.7253 3.58095 36.2156 3.90216 36.1141V36.1141Z"
				fill="#050911"
			/>
			<path
				d="M6.47164 32.733C7.24928 32.5133 8.02693 32.2766 8.80458 32.0568C9.10887 31.9723 8.99054 31.4821 8.66933 31.5666C7.89169 31.7863 7.11404 32.023 6.33639 32.2428C6.0321 32.3442 6.15044 32.8345 6.47164 32.733V32.733Z"
				fill="#050911"
			/>
			<path
				d="M0.842342 27.1035C3.81768 27.0697 6.77612 27.1542 9.73456 27.3401C10.0558 27.3571 10.0558 26.8499 9.73456 26.833C6.77612 26.647 3.80078 26.5794 0.842342 26.5963C0.52114 26.5963 0.52114 27.1035 0.842342 27.1035V27.1035Z"
				fill="#050911"
			/>
			<path
				d="M7.13129 24.8044C7.84132 24.9904 8.56825 25.0749 9.29518 25.0918C9.61638 25.0918 9.61638 24.5847 9.29518 24.5847C8.60206 24.5677 7.92584 24.4832 7.26654 24.3142C6.94533 24.2296 6.81009 24.7199 7.13129 24.8044V24.8044Z"
				fill="#050911"
			/>
			<path
				d="M6.03217 9.06549C8.68631 11.3477 11.1038 13.8497 13.2846 16.5884C13.4874 16.842 13.8424 16.4869 13.6396 16.2334C11.4588 13.4947 9.04132 10.9758 6.38718 8.71048C6.1505 8.49071 5.77859 8.84572 6.03217 9.06549V9.06549Z"
				fill="#050911"
			/>
			<path
				d="M13.4538 13.8666C13.9102 14.3399 14.3498 14.8133 14.8062 15.3035C15.026 15.5402 15.381 15.1852 15.1612 14.9485C14.7048 14.4752 14.2653 14.0018 13.8088 13.5116C13.589 13.2749 13.234 13.6299 13.4538 13.8666V13.8666Z"
				fill="#050911"
			/>
			<path
				d="M3.15828 14.8133C3.98664 15.3035 4.83191 15.76 5.69409 16.1826C5.98148 16.3348 6.23506 15.8952 5.94767 15.7431C5.08549 15.3035 4.24022 14.8471 3.41186 14.3737C3.12447 14.2047 2.87089 14.6442 3.15828 14.8133V14.8133Z"
				fill="#050911"
			/>
			<path
				d="M32.5741 10.9589C35.076 8.45695 37.578 5.95495 40.08 3.45296C40.3167 3.21629 39.9448 2.86127 39.725 3.09795C37.223 5.59994 34.721 8.10193 32.219 10.6039C31.9824 10.8406 32.3374 11.1956 32.5741 10.9589V10.9589Z"
				fill="#050911"
			/>
			<path
				d="M35.0929 12.1253C35.7353 11.652 36.3777 11.1955 37.0201 10.7222C37.2736 10.5362 37.037 10.0967 36.7665 10.2826C36.1241 10.756 35.4817 11.2124 34.8393 11.6858C34.5688 11.8717 34.8224 12.3113 35.0929 12.1253V12.1253Z"
				fill="#050911"
			/>
			<path
				d="M24.7639 7.64545C25.271 5.19417 25.6598 2.72599 25.8965 0.240901C25.9303 -0.0803004 25.4232 -0.0803004 25.3894 0.240901C25.1527 2.67527 24.7808 5.10964 24.2736 7.5102C24.206 7.83141 24.6962 7.96665 24.7639 7.64545V7.64545Z"
				fill="#050911"
			/>
			<path
				d="M38.9815 23.1984C43.5798 22.7758 48.1611 22.3532 52.7594 21.9305C53.0806 21.8967 53.0806 21.3896 52.7594 21.4234C48.1611 21.846 43.5798 22.2686 38.9815 22.6913C38.6603 22.7082 38.6434 23.2153 38.9815 23.1984V23.1984Z"
				fill="#050911"
			/>
			<path
				d="M19.6584 38.9034C22.5154 39.5289 25.4231 39.512 28.2632 38.8696C28.5844 38.802 28.4492 38.3117 28.128 38.3793C25.3724 39.0048 22.5492 39.0217 19.7936 38.4131C19.4724 38.3455 19.3372 38.8358 19.6584 38.9034V38.9034Z"
				fill="#050911"
			/>
			<path
				d="M20.0811 43.1974C22.2619 43.4848 24.4427 43.5525 26.6404 43.4003C26.9616 43.3834 26.9616 42.8762 26.6404 42.8931C24.4427 43.0453 22.2619 42.9777 20.0811 42.6903C19.7599 42.6565 19.7599 43.1636 20.0811 43.1974V43.1974Z"
				fill="#050911"
			/>
			<path
				d="M35.7863 37.196C39.2181 39.6135 42.7175 41.9464 46.2676 44.1948C46.5381 44.3639 46.7917 43.9243 46.5212 43.7553C42.9711 41.5069 39.4717 39.1739 36.0399 36.7565C35.7694 36.5705 35.5158 37.0101 35.7863 37.196V37.196Z"
				fill="#050911"
			/>
		</svg>
	);
};

export default PreviewLightBulb;
