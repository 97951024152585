const BlogItem = ({ tags, createdAt, title, desc, img, url, update }) => {
	return (
		<a href={url} className="blog-item flex-row-left">
			<div className={`blog-item-content ${!img && "full"}`}>
				<div className="blog-item-header flex-row-left">
					{tags &&
						tags.map((tag, index) => (
							<span
								key={index}
								className={`blog-item-tag text-bold-inter label-md rounded flex-column ${tag} ${
									update && "update-item-tag"
								}`}
							>
								{tag}
							</span>
						))}
					<span className="blog-item-date label-md opacity-5 upper">
						{createdAt}
					</span>
				</div>
				<div className="blog-item-title full">
					<span className="text-lg text-medium-inter">{title}</span>
				</div>
				<div className={update ? "update-item-desc" : "blog-item-desc"}>
					<div className="blog-desc-cover" />
					<p className="opacity-5">{desc}</p>
				</div>
				<span className="blog-item-link text-medium-inter text-orange">
					Read More
				</span>
			</div>
			{img && (
				<div
					className="blog-item-img rounded bg-cover"
					style={{ background: `url(${img})` }}
				/>
			)}
		</a>
	);
};

export default BlogItem;
