import { Link } from "react-router-dom";
import TwitterIcon from "./TwitterIcon";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import YoutubeIcon from "./YoutubeIcon";
import LinkedinIcon from "./LinkedinIcon";

const PreviewFooter = ({ className }) => {
	return (
		<div className={`preview-footer flex-column ${className}`}>
			<span className="preview-footer-copy label-md opacity-5">
				Alunalearn &copy; {new Date().getFullYear()}. All rights reserverd
			</span>
			<div className="preview-footer-socials flex-row-center">
				<Link to="/" className="label-md">
					<TwitterIcon />
				</Link>
				<Link to="/" className="label-md">
					<InstagramIcon />
				</Link>
				<Link to="/" className="label-md">
					<FacebookIcon />
				</Link>
				<Link to="/" className="label-md">
					<YoutubeIcon />
				</Link>
				<Link to="/" className="label-md">
					<LinkedinIcon />
				</Link>
			</div>
			<div className="preview-footer-links flex-row-center">
				<Link to="/legal" className="label-md opacity-5">
					Legal
				</Link>
				<Link to="/legal" className="label-md opacity-5">
					Privacy
				</Link>
				<Link to="/contact" className="label-md opacity-5">
					Contact Us
				</Link>
			</div>
		</div>
	);
};

export default PreviewFooter;
