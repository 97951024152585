import Button from "react-bootstrap/Button";
import PreviewLightBulb from "./PreviewLightBulb";

const AboutPreview = () => {
	return (
		<div className="about-preview rounded">
			<PreviewLightBulb />
			<h3 className="about-preview-title title-md text-white text-medium-rubik">
				Why are we building this?
			</h3>
			<p className="about-preview-desc text-lg text-white">
				AlunaLearn seeks to bring about a more modern age of education by
				building the tools to provide a diversity of options. We believe that
				not all teachers are authors.
			</p>
			<Button
				variant="light"
				size="lg"
				className="about-preview-btn text-orange full text-md"
			>
				Learn More
			</Button>
		</div>
	);
};

export default AboutPreview;
