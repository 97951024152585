import { Link } from "react-router-dom";
import PageFooter from "../components/PageFooter";
import RocketMan from "../components/RocketMan";
import Container from "react-bootstrap/Container";

const WaitlistSuccess = () => {
	return (
		<>
			<Container className="content full-page flex-column bg-cream">
				<RocketMan className="rocket-man" />
				<div className="col-md-6 col-12 align-center">
					<h2 className="waitlist-success-title title-md text-medium-rubik">
						We have added you to our waitlist!
					</h2>
					<p className="waitlist-success-desc text-lg opacity-5">
						We’ll let you know when AlunaLearn is ready! For the meantime you
						can{" "}
						<Link to="/" className="text-orange opaque">
							browse our blog
						</Link>{" "}
						or read{" "}
						<Link to="/updates" className="text-orange opaque">
							our updates
						</Link>
						.
					</p>
				</div>
			</Container>
			<PageFooter />
		</>
	);
};

export default WaitlistSuccess;
