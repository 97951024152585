const Logo = ({ className }) => {
	return (
		<svg
			width="214"
			height="32"
			viewBox="0 0 214 32"
			fill="none"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_122_2545)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.34315 1.11767C9.87554 1.20302 10.3388 1.40622 10.7818 1.71101C12.0213 2.57258 14.3987 5.32795 14.3987 5.32795C15.1912 6.12042 15.2075 7.42496 14.3987 8.20118C11.5621 10.924 6.2139 15.9105 3.47478 19.1251C2.30029 20.5028 0.00415039 19.499 0.00415039 17.6865C0.00415039 17.6865 0.690961 7.12422 0.434931 3.61295C0.329268 2.18244 1.03233 1.07297 2.30436 1.11767C4.29977 1.18676 7.67286 0.845389 9.33909 1.11767H9.34315Z"
					fill="#D44513"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M29.3216 19.816C29.7198 20.1777 29.9149 20.7142 29.9149 21.2547C29.9149 21.2547 29.8011 24.8594 29.9149 29.0047C29.9474 30.1263 29.0046 31.0367 27.8829 31.0367C27.8829 31.0367 8.7985 30.8457 2.03199 31.0367C0.910329 31.0692 0 30.1263 0 29.0047V26.5338C0 25.9933 0.207263 25.4731 0.59334 25.0951C8.5384 17.4264 12.1228 13.1918 16.1624 9.52607C16.9955 8.77017 18.1741 8.80675 19.0356 9.52607C21.9576 11.9604 23.4979 14.5085 29.3216 19.812V19.816Z"
					fill="#FF692A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M29.9153 12.4116C29.9153 14.2201 27.5745 15.2645 26.4447 13.8503C24.3843 11.2575 19.0483 6.99436 17.1829 4.58849C16.0734 3.15797 16.8253 1.36982 18.6216 1.11786C21.1453 0.768357 27.8833 1.11786 27.8833 1.11786C29.005 1.11786 29.9682 2.06883 29.9153 3.14984C29.7812 5.9743 29.9153 12.4116 29.9153 12.4116Z"
					fill="#FF9A6B"
				/>
			</g>
			<path
				d="M46.2554 28.1985C45.0581 28.1985 43.9851 27.9726 43.0363 27.5208C42.0876 27.0465 41.3308 26.4139 40.7661 25.6233C40.2013 24.8327 39.9189 23.9517 39.9189 22.9803C39.9189 21.3764 40.5628 20.1114 41.8504 19.1852C43.1606 18.2591 44.8774 17.6265 47.0008 17.2877L51.4397 16.6439V16.034C51.4397 15.153 51.2364 14.4753 50.8298 14.0009C50.4232 13.5265 49.7116 13.2893 48.695 13.2893C47.9496 13.2893 47.351 13.4361 46.8992 13.7298C46.4474 14.0235 46.0972 14.4075 45.8487 14.8819C45.668 15.1756 45.4082 15.3224 45.0694 15.3224H41.8504C41.6019 15.3224 41.4099 15.2546 41.2743 15.1191C41.1388 14.961 41.0823 14.7802 41.1049 14.5769C41.1049 14.1929 41.2517 13.7411 41.5454 13.2215C41.8391 12.702 42.2909 12.1937 42.9008 11.6967C43.5107 11.1772 44.2901 10.748 45.2388 10.4091C46.1876 10.0703 47.351 9.90086 48.7289 9.90086C50.1521 9.90086 51.3719 10.0703 52.3885 10.4091C53.405 10.748 54.2182 11.2224 54.8282 11.8323C55.4381 12.4422 55.8899 13.1538 56.1835 13.967C56.4772 14.7576 56.624 15.6161 56.624 16.5422V27.0126C56.624 27.2611 56.545 27.4644 56.3868 27.6225C56.2287 27.7806 56.0254 27.8597 55.7769 27.8597H52.4562C52.2303 27.8597 52.0383 27.7806 51.8802 27.6225C51.7221 27.4644 51.643 27.2611 51.643 27.0126V25.7588C51.3493 26.188 50.954 26.5947 50.457 26.9787C49.9601 27.3401 49.3614 27.6338 48.6612 27.8597C47.9835 28.0856 47.1815 28.1985 46.2554 28.1985ZM47.6107 24.6745C48.3562 24.6745 49.0226 24.5164 49.6099 24.2001C50.1973 23.8839 50.6604 23.3982 50.9992 22.7431C51.338 22.088 51.5075 21.2635 51.5075 20.2695V19.6935L48.3562 20.2018C47.1364 20.4051 46.2441 20.71 45.6793 21.1167C45.1146 21.5233 44.8322 22.0202 44.8322 22.6076C44.8322 23.0368 44.9565 23.4095 45.2049 23.7258C45.476 24.042 45.8262 24.2792 46.2554 24.4373C46.6846 24.5955 47.1364 24.6745 47.6107 24.6745Z"
				fill="#050911"
			/>
			<path
				d="M61.5738 27.8597C61.3253 27.8597 61.122 27.7806 60.9639 27.6225C60.8058 27.4644 60.7267 27.2611 60.7267 27.0126V4.64875C60.7267 4.40026 60.8058 4.19696 60.9639 4.03883C61.122 3.8807 61.3253 3.80164 61.5738 3.80164H64.9623C65.1882 3.80164 65.3802 3.8807 65.5383 4.03883C65.6965 4.19696 65.7755 4.40026 65.7755 4.64875V27.0126C65.7755 27.2611 65.6965 27.4644 65.5383 27.6225C65.3802 27.7806 65.1882 27.8597 64.9623 27.8597H61.5738Z"
				fill="#050911"
			/>
			<path
				d="M76.5302 28.1985C75.22 28.1985 74.0566 27.9049 73.0401 27.3175C72.0461 26.7076 71.2668 25.8379 70.702 24.7084C70.1599 23.5563 69.8888 22.1671 69.8888 20.5406V11.0868C69.8888 10.8383 69.9679 10.635 70.126 10.4769C70.2841 10.3188 70.4761 10.2397 70.702 10.2397H74.2938C74.5423 10.2397 74.7456 10.3188 74.9037 10.4769C75.0618 10.635 75.1409 10.8383 75.1409 11.0868V20.3373C75.1409 22.8674 76.2478 24.1324 78.4616 24.1324C79.5233 24.1324 80.3591 23.8048 80.969 23.1497C81.6016 22.472 81.9178 21.5346 81.9178 20.3373V11.0868C81.9178 10.8383 81.9969 10.635 82.155 10.4769C82.3131 10.3188 82.5164 10.2397 82.7649 10.2397H86.3567C86.5826 10.2397 86.7746 10.3188 86.9327 10.4769C87.0909 10.635 87.1699 10.8383 87.1699 11.0868V27.0126C87.1699 27.2611 87.0909 27.4644 86.9327 27.6225C86.7746 27.7806 86.5826 27.8597 86.3567 27.8597H83.036C82.7875 27.8597 82.5842 27.7806 82.4261 27.6225C82.268 27.4644 82.1889 27.2611 82.1889 27.0126V25.6911C81.6016 26.5043 80.8448 27.1255 79.9186 27.5547C78.9924 27.9839 77.863 28.1985 76.5302 28.1985Z"
				fill="#050911"
			/>
			<path
				d="M92.2478 27.8597C92.0219 27.8597 91.8299 27.7806 91.6718 27.6225C91.5137 27.4644 91.4346 27.2611 91.4346 27.0126V11.0868C91.4346 10.8383 91.5137 10.635 91.6718 10.4769C91.8299 10.3188 92.0219 10.2397 92.2478 10.2397H95.5685C95.817 10.2397 96.0203 10.3188 96.1784 10.4769C96.3366 10.635 96.4156 10.8383 96.4156 11.0868V12.4083C97.003 11.6854 97.7597 11.0868 98.6859 10.6124C99.6121 10.1381 100.742 9.90086 102.074 9.90086C103.407 9.90086 104.571 10.2058 105.564 10.8157C106.581 11.4031 107.372 12.2728 107.936 13.4249C108.524 14.5543 108.817 15.9323 108.817 17.5588V27.0126C108.817 27.2611 108.727 27.4644 108.546 27.6225C108.388 27.7806 108.196 27.8597 107.97 27.8597H104.379C104.13 27.8597 103.927 27.7806 103.769 27.6225C103.61 27.4644 103.531 27.2611 103.531 27.0126V17.7621C103.531 16.5648 103.238 15.6386 102.65 14.9835C102.086 14.3059 101.25 13.967 100.143 13.967C99.0812 13.967 98.2341 14.3059 97.6016 14.9835C96.9691 15.6386 96.6528 16.5648 96.6528 17.7621V27.0126C96.6528 27.2611 96.5738 27.4644 96.4156 27.6225C96.2575 27.7806 96.0655 27.8597 95.8396 27.8597H92.2478Z"
				fill="#050911"
			/>
			<path
				d="M118.062 28.1985C116.864 28.1985 115.791 27.9726 114.842 27.5208C113.894 27.0465 113.137 26.4139 112.572 25.6233C112.007 24.8327 111.725 23.9517 111.725 22.9803C111.725 21.3764 112.369 20.1114 113.657 19.1852C114.967 18.2591 116.684 17.6265 118.807 17.2877L123.246 16.6439V16.034C123.246 15.153 123.043 14.4753 122.636 14.0009C122.229 13.5265 121.518 13.2893 120.501 13.2893C119.756 13.2893 119.157 13.4361 118.705 13.7298C118.254 14.0235 117.903 14.4075 117.655 14.8819C117.474 15.1756 117.214 15.3224 116.876 15.3224H113.657C113.408 15.3224 113.216 15.2546 113.08 15.1191C112.945 14.961 112.888 14.7802 112.911 14.5769C112.911 14.1929 113.058 13.7411 113.352 13.2215C113.645 12.702 114.097 12.1937 114.707 11.6967C115.317 11.1772 116.096 10.748 117.045 10.4091C117.994 10.0703 119.157 9.90086 120.535 9.90086C121.958 9.90086 123.178 10.0703 124.195 10.4091C125.211 10.748 126.024 11.2224 126.634 11.8323C127.244 12.4422 127.696 13.1538 127.99 13.967C128.283 14.7576 128.43 15.6161 128.43 16.5422V27.0126C128.43 27.2611 128.351 27.4644 128.193 27.6225C128.035 27.7806 127.832 27.8597 127.583 27.8597H124.262C124.037 27.8597 123.844 27.7806 123.686 27.6225C123.528 27.4644 123.449 27.2611 123.449 27.0126V25.7588C123.156 26.188 122.76 26.5947 122.263 26.9787C121.766 27.3401 121.168 27.6338 120.467 27.8597C119.79 28.0856 118.988 28.1985 118.062 28.1985ZM119.417 24.6745C120.162 24.6745 120.829 24.5164 121.416 24.2001C122.003 23.8839 122.467 23.3982 122.805 22.7431C123.144 22.088 123.314 21.2635 123.314 20.2695V19.6935L120.162 20.2018C118.943 20.4051 118.05 20.71 117.485 21.1167C116.921 21.5233 116.638 22.0202 116.638 22.6076C116.638 23.0368 116.763 23.4095 117.011 23.7258C117.282 24.042 117.632 24.2792 118.062 24.4373C118.491 24.5955 118.943 24.6745 119.417 24.6745Z"
				fill="#050911"
			/>
			<path
				d="M133.38 27.8597C133.132 27.8597 132.928 27.7806 132.77 27.6225C132.612 27.4644 132.533 27.2611 132.533 27.0126V4.64875C132.533 4.40026 132.612 4.19696 132.77 4.03883C132.928 3.8807 133.132 3.80164 133.38 3.80164H136.768C136.994 3.80164 137.186 3.8807 137.344 4.03883C137.503 4.19696 137.582 4.40026 137.582 4.64875V27.0126C137.582 27.2611 137.503 27.4644 137.344 27.6225C137.186 27.7806 136.994 27.8597 136.768 27.8597H133.38Z"
				fill="#FF692A"
			/>
			<path
				d="M149.726 28.1985C147.128 28.1985 145.061 27.4757 143.525 26.0299C142.011 24.5842 141.209 22.4833 141.119 19.7274C141.119 19.5467 141.119 19.3095 141.119 19.0158C141.119 18.6996 141.119 18.4511 141.119 18.2703C141.209 16.5309 141.605 15.04 142.305 13.7976C143.005 12.5326 143.988 11.5725 145.253 10.9174C146.518 10.2397 147.997 9.90086 149.692 9.90086C151.589 9.90086 153.171 10.2962 154.436 11.0868C155.723 11.8549 156.683 12.9166 157.316 14.272C157.971 15.6048 158.298 17.1409 158.298 18.8803V19.6596C158.298 19.8855 158.208 20.0775 158.027 20.2357C157.869 20.3938 157.677 20.4728 157.451 20.4728H146.371C146.371 20.4954 146.371 20.5406 146.371 20.6084C146.371 20.6536 146.371 20.6987 146.371 20.7439C146.394 21.4668 146.529 22.1332 146.778 22.7431C147.049 23.3304 147.421 23.8048 147.896 24.1663C148.393 24.5277 148.98 24.7084 149.658 24.7084C150.223 24.7084 150.686 24.6294 151.047 24.4712C151.409 24.3131 151.702 24.1211 151.928 23.8952C152.177 23.6693 152.357 23.4773 152.47 23.3191C152.674 23.0933 152.832 22.9577 152.945 22.9125C153.08 22.8448 153.283 22.8109 153.555 22.8109H157.079C157.304 22.8109 157.485 22.8786 157.621 23.0142C157.779 23.1271 157.847 23.2966 157.824 23.5225C157.801 23.8839 157.609 24.3357 157.248 24.8778C156.909 25.3974 156.401 25.917 155.723 26.4365C155.045 26.9335 154.198 27.3514 153.182 27.6903C152.188 28.0291 151.036 28.1985 149.726 28.1985ZM146.371 17.3893H153.012V17.3216C153.012 16.5084 152.877 15.8081 152.606 15.2207C152.357 14.6108 151.985 14.1364 151.488 13.7976C150.991 13.4587 150.392 13.2893 149.692 13.2893C148.991 13.2893 148.393 13.4587 147.896 13.7976C147.399 14.1364 147.015 14.6108 146.744 15.2207C146.495 15.8081 146.371 16.5084 146.371 17.3216V17.3893Z"
				fill="#FF692A"
			/>
			<path
				d="M166.87 28.1985C165.673 28.1985 164.6 27.9726 163.651 27.5208C162.702 27.0465 161.945 26.4139 161.381 25.6233C160.816 24.8327 160.533 23.9517 160.533 22.9803C160.533 21.3764 161.177 20.1114 162.465 19.1852C163.775 18.2591 165.492 17.6265 167.615 17.2877L172.054 16.6439V16.034C172.054 15.153 171.851 14.4753 171.444 14.0009C171.038 13.5265 170.326 13.2893 169.31 13.2893C168.564 13.2893 167.965 13.4361 167.514 13.7298C167.062 14.0235 166.712 14.4075 166.463 14.8819C166.283 15.1756 166.023 15.3224 165.684 15.3224H162.465C162.216 15.3224 162.024 15.2546 161.889 15.1191C161.753 14.961 161.697 14.7802 161.719 14.5769C161.719 14.1929 161.866 13.7411 162.16 13.2215C162.454 12.702 162.905 12.1937 163.515 11.6967C164.125 11.1772 164.905 10.748 165.853 10.4091C166.802 10.0703 167.965 9.90086 169.343 9.90086C170.767 9.90086 171.986 10.0703 173.003 10.4091C174.02 10.748 174.833 11.2224 175.443 11.8323C176.053 12.4422 176.504 13.1538 176.798 13.967C177.092 14.7576 177.239 15.6161 177.239 16.5422V27.0126C177.239 27.2611 177.159 27.4644 177.001 27.6225C176.843 27.7806 176.64 27.8597 176.391 27.8597H173.071C172.845 27.8597 172.653 27.7806 172.495 27.6225C172.337 27.4644 172.258 27.2611 172.258 27.0126V25.7588C171.964 26.188 171.569 26.5947 171.072 26.9787C170.575 27.3401 169.976 27.6338 169.276 27.8597C168.598 28.0856 167.796 28.1985 166.87 28.1985ZM168.225 24.6745C168.971 24.6745 169.637 24.5164 170.224 24.2001C170.812 23.8839 171.275 23.3982 171.614 22.7431C171.953 22.088 172.122 21.2635 172.122 20.2695V19.6935L168.971 20.2018C167.751 20.4051 166.859 20.71 166.294 21.1167C165.729 21.5233 165.447 22.0202 165.447 22.6076C165.447 23.0368 165.571 23.4095 165.819 23.7258C166.091 24.042 166.441 24.2792 166.87 24.4373C167.299 24.5955 167.751 24.6745 168.225 24.6745Z"
				fill="#FF692A"
			/>
			<path
				d="M182.154 27.8597C181.929 27.8597 181.737 27.7806 181.578 27.6225C181.42 27.4644 181.341 27.2611 181.341 27.0126V11.0868C181.341 10.8609 181.42 10.6689 181.578 10.5108C181.737 10.3301 181.929 10.2397 182.154 10.2397H185.475C185.701 10.2397 185.893 10.3301 186.051 10.5108C186.232 10.6689 186.322 10.8609 186.322 11.0868V12.4422C186.887 11.7419 187.576 11.1998 188.389 10.8157C189.225 10.4317 190.185 10.2397 191.269 10.2397H192.726C192.952 10.2397 193.144 10.3188 193.302 10.4769C193.461 10.635 193.54 10.8383 193.54 11.0868V14.0009C193.54 14.2494 193.461 14.4527 193.302 14.6108C193.144 14.7689 192.952 14.848 192.726 14.848H189.745C188.751 14.848 187.971 15.1304 187.407 15.6951C186.842 16.2373 186.559 17.0166 186.559 18.0332V27.0126C186.559 27.2611 186.48 27.4644 186.322 27.6225C186.164 27.7806 185.961 27.8597 185.712 27.8597H182.154Z"
				fill="#FF692A"
			/>
			<path
				d="M196.88 27.8597C196.654 27.8597 196.462 27.7806 196.304 27.6225C196.146 27.4644 196.066 27.2611 196.066 27.0126V11.0868C196.066 10.8383 196.146 10.635 196.304 10.4769C196.462 10.3188 196.654 10.2397 196.88 10.2397H200.2C200.449 10.2397 200.652 10.3188 200.81 10.4769C200.968 10.635 201.047 10.8383 201.047 11.0868V12.4083C201.635 11.6854 202.392 11.0868 203.318 10.6124C204.244 10.1381 205.373 9.90086 206.706 9.90086C208.039 9.90086 209.202 10.2058 210.196 10.8157C211.213 11.4031 212.003 12.2728 212.568 13.4249C213.156 14.5543 213.449 15.9323 213.449 17.5588V27.0126C213.449 27.2611 213.359 27.4644 213.178 27.6225C213.02 27.7806 212.828 27.8597 212.602 27.8597H209.01C208.762 27.8597 208.559 27.7806 208.4 27.6225C208.242 27.4644 208.163 27.2611 208.163 27.0126V17.7621C208.163 16.5648 207.87 15.6386 207.282 14.9835C206.717 14.3059 205.882 13.967 204.775 13.967C203.713 13.967 202.866 14.3059 202.233 14.9835C201.601 15.6386 201.285 16.5648 201.285 17.7621V27.0126C201.285 27.2611 201.206 27.4644 201.047 27.6225C200.889 27.7806 200.697 27.8597 200.471 27.8597H196.88Z"
				fill="#FF692A"
			/>
			<defs>
				<clipPath id="clip0_122_2545">
					<rect
						width="29.919"
						height="30.0734"
						fill="white"
						transform="translate(0 0.963379)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default Logo;
