import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";

const WaitlistPreview = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [sending, setSending] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSending(true);

		const response = await fetch(
			`${process.env.REACT_APP_SENDGRID_URI}/v3/marketing/contacts`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_KEY}`,
				},
				body: JSON.stringify({
					contacts: [{ email: email }],
					list_ids: [process.env.REACT_APP_SENDGRID_LIST_ID],
				}),
			}
		);
		if (!response.ok) {
			dangerSnackbar("Something went wrong, please try again.");
		} else {
			setEmail("");
			navigate("/waitlist/success");
		}
		setSending(false);
	};

	return (
		<div className="waitlist-preview rounded">
			<h3 className="waitlist-preview-title title-md text-white text-medium-rubik">
				Join the waitlist!
			</h3>
			<p className="waitlist-preview-desc text-lg text-white">
				We can’t wait to show you what AlunaLearn can do. Be the first to know
				when we launch.
			</p>
			<Form onSubmit={handleSubmit}>
				<Form.Control
					className="waitlist-preview-input"
					placeholder="Enter email address"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button
					type="submit"
					size="lg"
					className="waitlist-preview-btn btn-default full text-md"
					disabled={sending}
				>
					Join Waitlist
				</Button>
			</Form>
		</div>
	);
};

export default WaitlistPreview;
