import Skeleton from "@mui/material/Skeleton";

const BlogItemLoader = ({ update }) => {
	return (
		<>
			<div className="blog-item flex-row-left">
				<div className="blog-item-content">
					<div className="blog-item-header flex-row-left">
						<Skeleton
							variant="rounded me-2"
							className="rounded"
							width="100px"
							height="30px"
						/>
						<Skeleton variant="text" className="rounded" width="100px" />
					</div>
					<div className="blog-item-title full">
						<Skeleton
							variant="text"
							className="rounded"
							width="80%"
							height="35px"
						/>
					</div>
					<div className="blog-item-desc">
						<Skeleton
							variant="text"
							className="rounded"
							width="90%"
							height="20px"
						/>
						<Skeleton
							variant="text"
							className="rounded"
							width="70%"
							height="20px"
						/>
					</div>
				</div>
				{!update && (
					<Skeleton
						variant="rounded"
						width="200px"
						height="150px"
						className="blog-item-img rounded "
					/>
				)}
			</div>
			<div className="blog-item flex-row-left">
				<div className="blog-item-content">
					<div className="blog-item-header flex-row-left">
						<Skeleton
							variant="rounded me-2"
							className="rounded"
							width="100px"
							height="30px"
						/>
						<Skeleton variant="text" className="rounded" width="100px" />
					</div>
					<div className="blog-item-title full">
						<Skeleton
							variant="text"
							className="rounded"
							width="80%"
							height="35px"
						/>
					</div>
					<div className="blog-item-desc">
						<Skeleton
							variant="text"
							className="rounded"
							width="90%"
							height="20px"
						/>
						<Skeleton
							variant="text"
							className="rounded"
							width="70%"
							height="20px"
						/>
					</div>
				</div>
				{!update && (
					<Skeleton
						variant="rounded"
						width="200px"
						height="150px"
						className="blog-item-img rounded "
					/>
				)}
			</div>
		</>
	);
};

export default BlogItemLoader;
