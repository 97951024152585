const HeroRobot = () => {
	return (
		<svg
			className="hero-robot"
			width="76"
			height="171"
			viewBox="0 0 76 171"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0.852554 18.3885C6.47057 22.1095 11.2131 26.852 15.007 32.3971C15.8096 33.5645 17.7066 32.47 16.904 31.3027C12.8912 25.4658 7.9298 20.5044 2.01993 16.5645C0.779593 15.6889 -0.314826 17.5859 0.852554 18.3885V18.3885Z"
				fill="white"
			/>
			<path
				d="M15.0808 22.4016C16.2481 23.7878 17.3426 25.2471 18.5099 26.6333C19.3855 27.7277 20.9177 26.1956 20.0421 25.1011C18.8747 23.7149 17.7803 22.2556 16.6129 20.8694C15.7374 19.775 14.2052 21.3801 15.0808 22.4016V22.4016Z"
				fill="white"
			/>
			<path
				d="M8.36713 1.97223C13.4015 6.64175 17.5603 11.9679 20.7705 18.0237C21.4272 19.264 23.3242 18.1696 22.6675 16.9293C19.3843 10.7276 15.0796 5.18252 9.89932 0.44004C8.87786 -0.581418 7.34567 1.02373 8.36713 1.97223V1.97223Z"
				fill="white"
			/>
			<path
				d="M26.3988 28.0927C26.18 35.7536 25.9611 43.4145 25.7422 51.1484C35.446 51.8051 45.0769 52.3888 54.7808 53.0454C54.7808 45.1656 54.7808 37.2858 54.7808 29.406C45.2958 28.9682 35.8838 28.5304 26.3988 28.0927Z"
				fill="white"
				stroke="#050911"
				strokeWidth="2"
			/>
			<path
				d="M39.0215 113.238C40.3349 121.702 40.9185 128.706 41.2104 133.741C42.1589 150.449 41.4293 154.316 39.97 157.38C36.3949 164.895 29.9014 168.032 30.4851 169.054C30.9228 169.71 34.1331 168.981 48.4335 161.466C47.558 144.831 46.7554 128.268 45.8799 111.633C43.6181 112.217 41.3563 112.728 39.0215 113.238Z"
				fill="white"
				stroke="#050911"
				strokeWidth="2"
			/>
			<path
				d="M20.124 59.6119C32.5274 59.6849 44.9309 59.8308 57.3343 59.9038C55.948 76.539 54.6347 93.1012 53.2484 109.736C42.4502 108.861 31.5789 108.058 20.7807 107.183C20.4888 91.3501 20.2699 75.5175 20.124 59.6119Z"
				fill="white"
				stroke="#050911"
				strokeWidth="2"
			/>
			<path
				d="M25.1589 112.655C29.0988 122.213 30.4121 130.457 30.8499 136.002C31.5065 144.174 31.9443 150.449 28.0044 154.607C26.5451 156.213 24.1374 157.745 19.4679 160.882C15.3091 163.655 13.3392 164.676 13.4851 164.968C13.704 165.406 17.7168 164.53 35.2275 158.328C36.3949 152.419 37.3434 144.831 37.1245 135.929C36.8327 126.444 35.3005 118.346 33.6224 112.29C30.8499 112.436 28.0044 112.509 25.1589 112.655Z"
				fill="white"
				stroke="#050911"
				strokeWidth="2"
			/>
			<path
				d="M31.5066 169.711C33.6954 167.886 35.7383 165.917 37.7083 163.801C39.9701 161.32 43.3263 157.526 46.8285 160.809C46.9014 160.226 46.9744 159.642 47.0473 159.058C41.8671 161.612 36.7598 164.165 31.5796 166.646C30.5581 167.157 31.0688 168.689 32.1632 168.689C37.0516 168.762 40.116 160.226 45.1503 161.831C46.4637 162.268 47.0473 160.153 45.734 159.715C43.5452 159.058 41.8671 159.642 40.0431 160.955C37.9272 162.487 34.9358 166.5 32.2362 166.5C32.4551 167.157 32.601 167.886 32.8199 168.543C38.0001 165.989 43.1074 163.436 48.2877 160.955C48.9443 160.663 49.0173 159.715 48.5066 159.204C46.5366 157.38 43.0345 156.723 40.5538 157.891C38.7298 158.693 37.5624 160.809 36.2491 162.196C34.2791 164.311 32.2362 166.208 30.0474 168.105C28.88 169.054 30.4122 170.659 31.5066 169.711V169.711Z"
				fill="#050911"
			/>
			<path
				d="M40.4801 158.329C42.669 158.402 44.7848 158.839 46.8278 159.715C48.0681 160.299 49.2355 158.402 47.9222 157.818C45.5145 156.723 43.1067 156.14 40.4801 156.067C39.0939 156.14 39.0939 158.329 40.4801 158.329V158.329Z"
				fill="#050911"
			/>
			<path
				d="M28.5153 155.775C30.1934 156.213 31.6526 157.088 32.82 158.401C33.7685 159.423 35.3007 157.891 34.3522 156.869C32.893 155.337 31.069 154.243 29.026 153.732C27.7857 153.294 27.202 155.41 28.5153 155.775V155.775Z"
				fill="#050911"
			/>
			<path
				d="M16.6946 164.603C18.9564 162.925 21.2912 161.393 23.6989 159.934C24.7933 159.277 25.9607 158.401 27.1281 158.037C28.9521 157.38 30.0465 157.818 31.7246 158.547C31.7246 157.891 31.7246 157.307 31.7246 156.65C29.3899 157.672 26.7633 157.599 24.3555 158.62C22.0208 159.569 19.832 161.903 17.4242 162.341C17.7161 162.998 18.0079 163.655 18.2998 164.311C22.6045 162.195 27.4199 161.83 31.7246 159.715C32.965 159.058 31.8706 157.234 30.6302 157.818C26.3255 159.934 21.5101 160.298 17.2053 162.414C16.038 162.998 16.9135 164.603 18.0809 164.384C20.7075 163.946 22.8234 161.539 25.304 160.59C27.7847 159.642 30.4843 159.569 32.892 158.547C33.6216 158.255 33.6216 156.942 32.892 156.65C30.1195 155.41 28.8791 154.68 26.1066 156.14C22.4586 158.037 19.0294 160.298 15.7461 162.706C14.4328 163.509 15.5272 165.406 16.6946 164.603V164.603Z"
				fill="#050911"
			/>
			<path
				d="M29.1706 123.818C30.9947 123.161 32.8187 123.234 34.4968 124.11C35.7372 124.766 36.8316 122.869 35.5912 122.213C33.4024 121.045 30.9217 120.826 28.587 121.702C27.2737 122.213 27.8573 124.328 29.1706 123.818V123.818Z"
				fill="#050911"
			/>
			<path
				d="M30.9958 131.114C32.5279 130.968 33.9872 131.333 35.1545 132.354C36.249 133.23 37.7812 131.697 36.6867 130.822C35.0816 129.509 33.1116 128.779 30.9958 128.925C29.6095 128.998 29.6095 131.187 30.9958 131.114V131.114Z"
				fill="#050911"
			/>
			<path
				d="M31.798 140.234C33.1842 140.088 34.4975 140.599 35.3001 141.766C36.0297 142.934 37.9267 141.839 37.1971 140.672C36.1027 138.848 33.9868 137.753 31.8709 138.045C31.2872 138.118 30.7765 138.483 30.7765 139.14C30.7035 139.65 31.2143 140.307 31.798 140.234V140.234Z"
				fill="#050911"
			/>
			<path
				d="M41.2103 123.307C42.5237 122.577 44.2018 122.65 45.4421 123.672C46.5365 124.547 48.0687 123.015 46.9743 122.14C45.0043 120.534 42.3777 120.17 40.1159 121.41C38.8756 122.14 39.97 124.037 41.2103 123.307V123.307Z"
				fill="#050911"
			/>
			<path
				d="M42.4506 137.097C43.6179 136.586 44.9312 136.805 45.8797 137.68C46.9012 138.629 48.5063 137.097 47.4119 136.148C45.7338 134.616 43.3991 134.251 41.2832 135.273C40.0428 135.783 41.2102 137.68 42.4506 137.097V137.097Z"
				fill="#050911"
			/>
			<path
				d="M42.7419 148.77C43.9093 148.041 45.3685 148.041 46.463 148.916C47.5574 149.792 49.0896 148.26 47.9951 147.384C46.1711 145.852 43.6175 145.633 41.6475 146.946C40.4801 147.676 41.5746 149.573 42.7419 148.77V148.77Z"
				fill="#050911"
			/>
			<path
				d="M59.0847 59.8308C57.6255 76.6119 56.1663 93.393 54.707 110.174"
				stroke="#050911"
				strokeWidth="6"
			/>
			<path
				d="M20.5625 107.839C32.747 108.715 44.9316 109.517 57.1161 110.393"
				stroke="#050911"
				strokeWidth="6"
			/>
			<path
				d="M57.1878 28.7491C56.8959 37.0667 56.5311 45.3114 56.2393 53.6289"
				stroke="#050911"
				strokeWidth="6"
			/>
			<path
				d="M58.7199 69.6805C65.5053 68.4402 72.7285 73.7663 73.2392 80.7706C73.9688 89.526 63.9731 91.0581 57.4066 90.1097C56.0204 89.8908 55.4367 92.0066 56.8229 92.2255C64.8487 93.3929 76.1577 91.1311 75.4281 80.7706C74.8444 72.2341 66.3079 66.0324 58.1362 67.5646C56.75 67.7835 57.3337 69.8994 58.7199 69.6805V69.6805Z"
				fill="#050911"
			/>
			<path
				d="M57.1162 93.9765C59.9617 94.5602 62.2235 96.6031 63.0261 99.3756C63.3909 100.762 65.5068 100.178 65.142 98.7919C64.1935 95.2898 61.348 92.5173 57.7729 91.8606C56.3136 91.6417 55.7299 93.6846 57.1162 93.9765V93.9765Z"
				fill="#050911"
			/>
			<path
				d="M56.0214 93.247C57.7725 95.8006 58.5751 98.6461 58.2832 101.783C58.2102 103.17 60.3991 103.17 60.472 101.783C60.6909 98.3543 59.8154 94.9981 57.8454 92.1526C57.1158 90.9852 55.2188 92.0796 56.0214 93.247V93.247Z"
				fill="#050911"
			/>
			<path
				d="M20.051 68.659C15.2355 65.5947 6.40724 74.423 5.31282 78.7277C3.12398 87.483 14.36 86.4616 19.0295 89.38C20.1969 90.1097 21.2913 88.2127 20.124 87.483C16.7677 85.3672 11.8064 86.1697 8.88792 83.5431C5.31282 80.3328 8.59607 76.4659 11.0768 74.2041C12.3901 73.0367 16.8407 69.1698 18.9566 70.4831C20.124 71.3586 21.2913 69.4616 20.051 68.659V68.659Z"
				fill="#050911"
			/>
			<path
				d="M18.8841 88.3585C16.9871 89.0881 15.236 90.1825 13.7768 91.7147C12.6824 92.8821 11.2961 94.7061 12.3905 96.3112C13.1931 97.4786 15.0901 96.3842 14.2875 95.2168C13.7768 94.4872 15.6738 92.8821 16.1845 92.4443C17.133 91.5688 18.2274 90.9121 19.4678 90.4743C20.7811 89.9636 20.2703 87.8477 18.8841 88.3585V88.3585Z"
				fill="#050911"
			/>
			<path
				d="M18.0078 89.8908C16.6216 92.0796 16.1838 94.5603 16.6945 97.041C16.9864 98.4273 19.1023 97.8436 18.8104 96.4573C18.3726 94.4874 18.8104 92.6633 19.9048 90.9852C20.7074 89.8178 18.8104 88.7234 18.0078 89.8908V89.8908Z"
				fill="#050911"
			/>
			<path
				d="M24.94 67.2727C24.5752 72.5989 24.6482 77.998 25.013 83.3242C25.1589 84.7105 27.3478 84.7105 27.2018 83.3242C26.7641 77.998 26.7641 72.5989 27.1289 67.2727C27.2748 65.8865 25.086 65.8865 24.94 67.2727V67.2727Z"
				fill="#050911"
			/>
			<path
				d="M26.5446 67.8565C34.2785 68.2943 41.9394 68.5861 49.6733 68.7321C49.3085 68.3672 48.9437 68.0024 48.5789 67.6376C48.36 73.1097 48.1411 78.6548 47.8493 84.1269C47.7763 85.5131 49.9651 85.5131 50.0381 84.1269C50.257 78.6548 50.4759 73.1097 50.7677 67.6376C50.7677 67.0539 50.257 66.5432 49.6733 66.5432C41.9394 66.3973 34.2785 66.1054 26.5446 65.6677C25.1583 65.5947 25.1583 67.7836 26.5446 67.8565V67.8565Z"
				fill="#050911"
			/>
			<path
				d="M25.6696 84.2727C28.8799 85.1483 32.455 84.9294 35.6653 85.1483C39.6052 85.3671 43.6181 85.659 47.558 85.8779C48.9443 85.9508 48.9443 83.762 47.558 83.689C44.0558 83.4701 40.5537 83.2513 37.0516 83.0324C33.5494 82.8135 29.6095 83.0324 26.1803 82.1568C24.94 81.792 24.3563 83.9079 25.6696 84.2727V84.2727Z"
				fill="#050911"
			/>
			<path
				d="M28.1504 95.5819C29.4637 98.4274 32.3092 95.9467 34.498 96.9682C35.5195 97.4059 35.3006 98.0626 36.6869 98.1355C38.292 98.2085 42.2319 94.9982 43.0345 97.9896C43.2534 98.7922 44.2748 99.0111 44.8585 98.5003C45.5882 98.2815 46.3907 98.0626 47.1203 97.9166C47.9959 99.0111 49.601 97.4059 48.6525 96.3845C46.9744 94.4145 44.8585 95.5089 43.2534 96.9682C43.8371 97.1141 44.4937 97.26 45.0774 97.4789C44.4208 94.9252 41.7212 93.2471 39.1676 94.4145C37.9272 94.9982 37.7813 96.0926 35.8843 95.363C35.5195 95.2171 35.3736 94.6334 35.0088 94.4875C34.2062 94.1956 29.9744 94.6334 29.9744 94.5604C29.4637 93.1742 27.5667 94.2686 28.1504 95.5819V95.5819Z"
				fill="#050911"
			/>
			<path
				d="M32.0157 38.1613C32.1616 37.3587 33.1101 37.1398 33.7668 37.4316C34.7153 37.7964 35.226 38.8909 35.6638 39.6934C36.2475 40.9338 38.1445 39.8394 37.5608 38.599C36.7582 36.9939 35.5179 35.3158 33.5479 35.0969C31.9428 34.951 30.2646 35.8995 29.9728 37.5046C29.608 38.9638 31.7239 39.5475 32.0157 38.1613V38.1613Z"
				fill="#050911"
			/>
			<path
				d="M45.4423 39.1825C45.5882 38.4529 46.2449 38.0152 46.9745 38.307C47.85 38.5988 48.2148 39.6933 48.3607 40.4958C48.5796 41.8821 50.6955 41.2984 50.4766 39.9121C50.1848 38.1611 49.0174 36.337 47.1204 36.0452C45.4423 35.8263 43.6182 36.8478 43.3264 38.5988C43.1075 39.9851 45.2234 40.5688 45.4423 39.1825V39.1825Z"
				fill="#050911"
			/>
			<path
				d="M33.1117 42.7578C35.5924 47.1355 41.5023 47.7922 44.7126 43.8523C45.5881 42.7578 44.0559 41.2256 43.1804 42.3201C40.8456 45.1656 36.8328 44.9467 35.0087 41.6634C34.3521 40.4231 32.4551 41.5175 33.1117 42.7578V42.7578Z"
				fill="#050911"
			/>
		</svg>
	);
};

export default HeroRobot;
