import BlogItem from "../components/BlogItem";
import BlogItemLoader from "../components/BlogItemLoader";

const UpdateList = () => {
	return (
		<div className="update-list">
			<div className="update-list-bar" />
			<div>
				<BlogItem
					update
					tags={["New"]}
					createdAt="January 1, 2023"
					title="Update #5"
					desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
					url="#"
				/>
				<BlogItem
					update
					tags={["Feature"]}
					createdAt="January 1, 2023"
					title="Update #5"
					desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
					url="#"
				/>
				<BlogItem
					update
					tags={["Changed"]}
					createdAt="January 1, 2023"
					title="Update #5"
					desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
					url="#"
				/>
				<BlogItem
					update
					tags={["Improvement"]}
					createdAt="January 1, 2023"
					title="Update #5"
					desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
					url="#"
				/>
				<BlogItem
					update
					tags={["Removed"]}
					createdAt="January 1, 2023"
					title="Update #5"
					desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
					url="#"
				/>
			</div>
			<BlogItemLoader update />
		</div>
	);
};

export default UpdateList;
