import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TwitterIcon from "./TwitterIcon";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import YoutubeIcon from "./YoutubeIcon";
import LinkedinIcon from "./LinkedinIcon";

const PageFooter = ({ className }) => {
	return (
		<div className={`page-footer bg-white flex-column ${className}`}>
			<Container>
				<Row>
					<Col className="page-footer-copy col-md-4 col-12">
						<span className="label-md opacity-5">
							Alunalearn &copy; {new Date().getFullYear()}. All rights reserverd
						</span>
					</Col>
					<Col className="page-footer-socials flex-row-center col-md-4 col-12">
						<Link to="/" className="label-md">
							<TwitterIcon />
						</Link>
						<Link to="/" className="label-md">
							<InstagramIcon />
						</Link>
						<Link to="/" className="label-md">
							<FacebookIcon />
						</Link>
						<Link to="/" className="label-md">
							<YoutubeIcon />
						</Link>
						<Link to="/" className="label-md">
							<LinkedinIcon />
						</Link>
					</Col>
					<Col className="page-footer-links flex-row-right col-md-4 col-12">
						<Link to="/legal" className="label-md opacity-5">
							Legal
						</Link>
						<Link to="/legal" className="label-md opacity-5">
							Privacy
						</Link>
						<Link to="/contact" className="label-md opacity-5">
							Contact Us
						</Link>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default PageFooter;
