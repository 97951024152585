import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroLightBulb from "../components/HeroLightBulb";
import HeroRobot from "../components/HeroRobot";
import Access from "../components/Access";
import Innovation from "../components/Innovation";
import Transparency from "../components/Transparency";
import Collaboration from "../components/Collaboration";
import Empower from "../components/Empower";
import WaitlistForm from "../components/WaitlistForm";
import Jogging from "../components/Jogging";
import PageFooter from "../components/PageFooter";

const About = () => {
	return (
		<div className="content">
			<div className="about-hero bg-cream flex-column">
				<div className="about-hero-content rounded">
					<HeroLightBulb />
					<p className="title-md text-white text-medium-rubik m-0 align-center">
						AlunaLearn is an innovative educational platform that seeks to
						democratize learning and empower learners worldwide through the use
						of technology and innovative teaching methods.
					</p>
					<HeroRobot />
				</div>
			</div>
			<Container className="about-content">
				<h1 className="about-title title-xl text-medium-rubik align-center">
					Why are we building this?
				</h1>
				<Row className="about-why flex-row-left">
					<Col className="m-hidden col-md-5 col-12 flex-column">
						<Access className="about-why-img" />
					</Col>
					<Col className="col-md-7 col-12">
						<h2 className="about-why-title title-lg text-medium-rubik">
							Promoting access and equity
						</h2>
						<p className="about-why-desc text-lg opacity-5 m-0">
							AlunaLearn could aim to provide educational resources to learners
							who may face barriers to accessing traditional educational
							opportunities, such as learners from low-income backgrounds or
							those who live in geographically remote areas.
						</p>
					</Col>
					<Col className="d-hidden col-md-5 col-12 flex-column">
						<Access className="about-why-img" />
					</Col>
				</Row>
				<Row className="about-why flex-row-left">
					<Col className="col-md-7 col-12">
						<h2 className="about-why-title title-lg text-medium-rubik">
							Fostering innovation in education
						</h2>
						<p className="about-why-desc text-lg opacity-5 m-0">
							By leveraging technology and innovative teaching methods,
							AlunaLearn could seek to push the boundaries of what is possible
							in the field of education, allowing for new and exciting learning
							experiences for learners.
						</p>
					</Col>
					<Col className="col-md-5 col-12 flex-column">
						<Innovation className="about-why-img" />
					</Col>
				</Row>
				<Row className="about-why flex-row-left">
					<Col className="m-hidden col-md-5 col-12 flex-column">
						<Access className="about-why-img" />
					</Col>
					<Col className="col-md-7 col-12">
						<h2 className="about-why-title title-lg text-medium-rubik">
							Enhancing transparency and openness
						</h2>
						<p className="about-why-desc text-lg opacity-5 m-0">
							AlunaLearn could prioritize transparency and openness in its
							operations, providing learners with clear and honest information
							about its offerings and processes.
						</p>
					</Col>
					<Col className="d-hidden col-md-5 col-12 flex-column">
						<Transparency className="about-why-img" />
					</Col>
				</Row>
				<Row className="about-why flex-row-left">
					<Col className="col-md-7 col-12">
						<h2 className="about-why-title title-lg text-medium-rubik">
							Encouraging collaboration and community-building
						</h2>
						<p className="about-why-desc text-lg opacity-5 m-0">
							AlunaLearn could facilitate collaboration and community-building
							among learners, instructors, and other stakeholders, creating a
							supportive learning environment that fosters growth and
							development.
						</p>
					</Col>
					<Col className="col-md-5 col-12 flex-column">
						<Collaboration className="about-why-img" />
					</Col>
				</Row>
				<Row className="about-why flex-row-left">
					<Col className="m-hidden col-md-5 col-12 flex-column">
						<Empower className="about-why-img" />
					</Col>
					<Col className="col-md-7 col-12">
						<h2 className="about-why-title title-lg text-medium-rubik">
							Empowering learners to succeed
						</h2>
						<p className="about-why-desc text-lg opacity-5 m-0">
							Ultimately, AlunaLearn could strive to empower learners with the
							skills, knowledge, and confidence they need to succeed in their
							chosen careers and in life more broadly. This could involve a
							focus on practical, applicable skills that can help learners
							thrive in the modern workforce.
						</p>
					</Col>
					<Col className="d-hidden col-md-5 col-12 flex-column">
						<Empower className="about-why-img" />
					</Col>
				</Row>
				<Container>
					<Row className="about-cta rounded bg-black">
						<Col className="col-md-6 col-12">
							<h2 className="about-cta-title title-md text-white text-medium-rubik">
								Join the waitlist!
							</h2>
							<p className="about-cta-desc text-lg text-white">
								We can’t wait to show you what AlunaLearn can do. Be the first
								to know when we launch.
							</p>
							<WaitlistForm />
						</Col>
						<Col className="col-md-6 col-12 flex-column">
							<Jogging className="about-cta-graphic m-hidden" />
						</Col>
					</Row>
				</Container>
			</Container>
			<PageFooter />
		</div>
	);
};

export default About;
