const HeroLightBulb = () => {
	return (
		<svg
			className="hero-lightbulb"
			width="157"
			height="144"
			viewBox="0 0 157 144"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M60.8458 56.9914C52.4001 65.6215 52.2766 76.6322 52.2192 81.1704C52.2069 82.2715 52.2176 95.7493 61.5481 107.301C64.3117 110.732 67.2311 114.253 71.9259 115.058C74.6505 115.558 76.7999 114.894 85.1239 111.873C89.0101 110.481 93.2021 108.918 97.659 107.197C99.7868 102.192 103.141 92.4234 101.662 80.349C101.367 78.1007 97.7888 51.308 81.8511 48.2014C71.4145 46.1238 62.0792 55.6948 60.8458 56.9914Z"
				fill="#F7D370"
			/>
			<path
				d="M69.2516 114.238C63.5296 113.532 59.0767 109.368 55.8528 104.875C52.4511 100.209 50.0759 94.7862 48.8697 89.1375C46.6059 78.7225 48.2013 67.1416 54.5568 58.413C60.5494 50.1328 71.4259 45.3303 81.4625 48.7007C91.9336 52.2181 96.7918 63.9579 99.4974 73.7276C102.499 84.6035 103.022 96.7145 97.0478 106.702C96.617 107.389 97.7189 108.059 98.1387 107.332C103.802 97.8229 103.857 86.3914 101.355 75.9084C98.9085 65.6302 94.2214 53.0541 83.9455 48.2988C74.323 43.8514 62.7345 47.3078 55.7629 54.8843C48.0437 63.2758 45.419 75.4398 47.118 86.5331C48.1699 93.4087 50.8445 100.113 54.9932 105.764C58.5098 110.53 63.1895 114.721 69.2722 115.462C70.0565 115.603 70.0249 114.338 69.2516 114.238V114.238Z"
				fill="#050911"
			/>
			<path
				d="M68.887 115.17C68.0209 117.51 69.0871 120.342 71.5308 121.268C73.384 121.957 75.7222 121.506 77.6317 121.258C79.9319 120.993 82.2181 120.512 84.4714 119.908C88.4755 118.791 92.9872 117.275 96.2982 114.719C98.6948 112.848 100.019 109.595 97.8054 107.07C97.291 106.462 96.3905 107.361 96.9049 107.97C101.657 113.415 87.2558 117.845 84.1011 118.69C81.1923 119.469 78.2176 120.003 75.248 120.228C73.8302 120.344 72.2484 120.505 71.0697 119.547C69.88 118.549 69.6158 116.907 70.1278 115.54C70.3918 114.723 69.162 114.394 68.887 115.17V115.17Z"
				fill="#050911"
			/>
			<path
				d="M71.9766 121.456C72.5255 123.504 73.1263 125.583 73.921 127.565C74.2964 128.475 74.7208 129.239 75.6529 129.604C76.9786 130.127 78.8113 129.592 80.1552 129.364C82.4115 128.935 84.6648 128.331 86.8552 127.656C88.9227 127.015 90.9682 126.291 93.0027 125.526C94.1989 125.074 95.6028 124.742 96.2926 123.547C97.8332 120.939 96.8313 117.036 95.6071 114.597C95.2346 113.863 94.1343 114.509 94.5068 115.243C95.1668 116.559 95.622 117.93 95.7194 119.441C95.8476 121.558 95.6253 123.023 93.4818 124.037C90.9915 125.231 88.0417 126.021 85.3897 126.776C82.7786 127.519 79.8618 128.433 77.116 128.51C74.5861 128.573 73.7736 123.082 73.2466 121.116C72.9971 120.348 71.768 120.677 71.9766 121.456V121.456Z"
				fill="#050911"
			/>
			<path
				d="M60.5634 77.2221C66.3031 78.9775 71.2389 82.4851 74.7773 87.3332C75.2617 87.9938 76.362 87.3477 75.8776 86.6871C72.1284 81.5442 66.9629 77.8346 60.9335 75.9813C60.1163 75.7172 59.7872 76.9471 60.5634 77.2221V77.2221Z"
				fill="#050911"
			/>
			<path
				d="M75.7612 87.0697C76.8172 81.3418 79.1769 76.0549 82.7834 71.4879C83.2772 70.8726 82.3662 69.9311 81.8834 70.5874C78.146 75.3212 75.6663 80.8158 74.5314 86.7405C74.3902 87.5248 75.6201 87.854 75.7612 87.0697V87.0697Z"
				fill="#050911"
			/>
			<path
				d="M74.3932 85.8995C77.2691 94.1745 79.8173 102.537 81.9558 111.01C82.1644 111.788 83.3935 111.459 83.1849 110.681C81.0464 102.208 78.4872 93.8042 75.6223 85.5702C75.3208 84.7727 74.0917 85.1021 74.3932 85.8995V85.8995Z"
				fill="#050911"
			/>
			<path
				d="M31.9503 119.403C36.9052 116.099 41.8192 112.807 46.7741 109.503C47.4457 109.06 46.7996 107.959 46.128 108.403C41.173 111.706 36.2591 114.999 31.3042 118.303C30.5916 118.757 31.2377 119.857 31.9503 119.403V119.403Z"
				fill="#050911"
			/>
			<path
				d="M35.9818 109.541C37.7237 108.503 39.4546 107.424 41.1965 106.387C41.8791 105.984 41.2739 104.873 40.5504 105.287C38.8085 106.324 37.0776 107.403 35.3357 108.44C34.6642 108.884 35.2693 109.995 35.9818 109.541V109.541Z"
				fill="#050911"
			/>
			<path
				d="M18.6833 99.5532C25.872 97.5392 33.0965 95.8229 40.3869 94.3525C41.1763 94.1849 40.8469 92.9558 40.0576 93.1234C32.7672 94.5939 25.5126 96.3621 18.354 98.3241C17.5756 98.5327 17.9049 99.7618 18.6833 99.5532V99.5532Z"
				fill="#050911"
			/>
			<path
				d="M32.4319 89.8977C34.2734 89.8873 36.0899 89.6201 37.8626 89.189C38.641 88.9804 38.3117 87.7513 37.5333 87.9599C35.8425 88.369 34.1489 88.6033 32.4413 88.6217C31.608 88.6255 31.5986 89.9014 32.4319 89.8977V89.8977Z"
				fill="#050911"
			/>
			<path
				d="M19.5479 52.4688C27.4621 56.2762 34.9454 60.7698 42.0088 65.9907C42.6651 66.4736 43.2949 65.3827 42.6386 64.8999C35.5752 59.679 28.0809 55.1443 20.1777 51.3779C19.4614 50.999 18.7906 52.1009 19.5479 52.4688V52.4688Z"
				fill="#050911"
			/>
			<path
				d="M40.6519 59.2848C42.0655 60.1355 43.4381 60.9972 44.8626 61.8889C45.5489 62.3198 46.1787 61.2289 45.4924 60.798C44.0789 59.9473 42.7063 59.0856 41.2818 58.1939C40.5955 57.763 39.9656 58.8539 40.6519 59.2848V59.2848Z"
				fill="#050911"
			/>
			<path
				d="M16.3157 68.2645C18.6416 68.9147 20.9864 69.472 23.3503 69.9363C24.1456 70.1184 24.4747 68.8886 23.6794 68.7065C21.3046 68.2011 18.9597 67.6438 16.6448 67.0346C15.8386 66.8115 15.5095 68.0414 16.3157 68.2645V68.2645Z"
				fill="#050911"
			/>
			<path
				d="M85.0994 39.8223C89.5381 32.1342 93.9769 24.446 98.4156 16.7578C98.8355 16.0306 97.7037 15.4117 97.3248 16.128C92.886 23.8162 88.4472 31.5043 84.0085 39.1925C83.5886 39.9198 84.6795 40.5496 85.0994 39.8223V39.8223Z"
				fill="#050911"
			/>
			<path
				d="M91.9613 41.0135C93.2108 39.4492 94.4712 37.9259 95.7207 36.3616C96.2145 35.7463 95.3555 34.8347 94.8207 35.461C93.5713 37.0253 92.3108 38.5487 91.0614 40.113C90.5266 40.7393 91.4266 41.6398 91.9613 41.0135V41.0135Z"
				fill="#050911"
			/>
			<path
				d="M64.0198 36.864C63.6571 30.5941 62.9967 24.3601 61.9565 18.1839C61.8299 17.3835 60.6008 17.7128 60.7274 18.5132C61.7347 24.5665 62.4141 30.7076 62.7439 36.8546C62.7886 37.6769 64.0645 37.6863 64.0198 36.864V36.864Z"
				fill="#050911"
			/>
			<path
				d="M108.575 65.3233C119.444 61.3132 130.273 57.314 141.142 53.3038C141.898 53.0133 141.569 51.7842 140.813 52.0747C129.943 56.0849 119.115 60.0841 108.246 64.0943C107.478 64.3438 107.767 65.5839 108.575 65.3233V65.3233Z"
				fill="#050911"
			/>
			<path
				d="M71.9458 115.932C79.2758 115.592 86.3115 113.663 92.7772 110.262C93.5117 109.889 92.8656 108.789 92.1311 109.162C85.8593 112.467 79.0284 114.341 71.9552 114.656C71.1329 114.7 71.1235 115.976 71.9458 115.932V115.932Z"
				fill="#050911"
			/>
			<path
				d="M75.7579 126.063C81.2296 125.344 86.5586 124.091 91.7858 122.296C92.5532 122.046 92.2239 120.817 91.4564 121.066C86.2292 122.862 80.9003 124.115 75.4286 124.834C74.6282 124.961 74.9576 126.19 75.7579 126.063V126.063Z"
				fill="#050911"
			/>
			<path
				d="M109.922 101.321C119.809 104.951 129.804 108.332 139.868 111.476C140.633 111.71 140.962 110.48 140.197 110.246C130.133 107.102 120.138 103.721 110.251 100.091C109.475 99.8159 109.146 101.046 109.922 101.321V101.321Z"
				fill="#050911"
			/>
		</svg>
	);
};

export default HeroLightBulb;
