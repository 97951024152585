const LinkedinIcon = ({ className }) => {
	return (
		<svg
			className={className}
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_134_859)">
				<path
					d="M6.28328 4.4611C6.28306 4.90313 6.10726 5.32697 5.79454 5.63937C5.48182 5.95177 5.05781 6.12716 4.61578 6.12694C4.17376 6.12671 3.74992 5.95091 3.43752 5.63819C3.12511 5.32547 2.94973 4.90146 2.94995 4.45944C2.95017 4.01741 3.12598 3.59357 3.4387 3.28117C3.75141 2.96876 4.17542 2.79338 4.61745 2.7936C5.05948 2.79382 5.48331 2.96963 5.79572 3.28235C6.10812 3.59506 6.28351 4.01907 6.28328 4.4611ZM6.33328 7.3611H2.99995V17.7944H6.33328V7.3611ZM11.6 7.3611H8.28328V17.7944H11.5666V12.3194C11.5666 9.26944 15.5416 8.9861 15.5416 12.3194V17.7944H18.8333V11.1861C18.8333 6.04444 12.95 6.2361 11.5666 8.7611L11.6 7.3611Z"
					fill="#050911"
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_134_859">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="translate(0.5 0.294434)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default LinkedinIcon;
