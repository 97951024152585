import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UpdateItem from "./UpdateItem";

const UpdatesPreview = () => {
	return (
		<div className="updates-preview">
			<Row className="updates-preview-header flex-row-left">
				<Col className="col-8">
					<h2 className="text-xl text-medium-rubik m-0">Project Updates</h2>
				</Col>
				<Col className="col-4 flex-row-right">
					<a href="#" className="text-orange text-medium-inter upper">
						See All
					</a>
				</Col>
			</Row>
			<UpdateItem
				title="Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa"
				createdAt="January 1, 2023"
			/>
			<UpdateItem
				title="Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa"
				createdAt="January 1, 2023"
			/>
			<UpdateItem
				title="Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa"
				createdAt="January 1, 2023"
			/>
			<UpdateItem
				title="Lorem ipsum dolor sit amet consectetur adipiscing elit ut et massa"
				createdAt="January 1, 2023"
			/>
		</div>
	);
};

export default UpdatesPreview;
