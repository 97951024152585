const TwitterIcon = ({ className }) => {
	return (
		<svg
			className={className}
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.9683 5.00777C18.3321 5.28918 17.6574 5.47399 16.9666 5.5561C17.6947 5.12063 18.2397 4.43528 18.4999 3.62777C17.8166 4.03443 17.0674 4.31943 16.2866 4.4736C15.7621 3.91242 15.0669 3.54024 14.3091 3.41492C13.5512 3.28961 12.7732 3.41818 12.096 3.78064C11.4187 4.14311 10.8802 4.71917 10.5642 5.41926C10.2481 6.11935 10.1722 6.90425 10.3483 7.65193C8.96251 7.58248 7.60687 7.22237 6.36933 6.59499C5.13179 5.9676 4.04003 5.08697 3.16492 4.01027C2.85516 4.54231 2.69238 5.14712 2.69326 5.76277C2.69326 6.9711 3.30826 8.0386 4.24326 8.6636C3.68993 8.64618 3.14878 8.49675 2.66492 8.22777V8.2711C2.66509 9.07585 2.94356 9.85578 3.45313 10.4787C3.96269 11.1015 4.67199 11.529 5.46076 11.6886C4.9471 11.8278 4.40851 11.8483 3.88576 11.7486C4.10814 12.4413 4.54159 13.0471 5.12542 13.4812C5.70924 13.9153 6.4142 14.1559 7.14159 14.1694C6.41866 14.7372 5.5909 15.1569 4.70566 15.4046C3.82041 15.6522 2.89503 15.723 1.98242 15.6128C3.5755 16.6373 5.43 17.1812 7.32409 17.1794C13.7349 17.1794 17.2408 11.8686 17.2408 7.26277C17.2408 7.11277 17.2366 6.9611 17.2299 6.81277C17.9123 6.31957 18.5013 5.70862 18.9691 5.0086L18.9683 5.00777Z"
				fill="#050911"
				fillOpacity="0.5"
			/>
		</svg>
	);
};

export default TwitterIcon;
