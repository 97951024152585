const RocketMan = ({ className }) => {
	return (
		<svg
			className={className}
			width="531"
			height="402"
			viewBox="0 0 531 402"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.8417 110.771C25.27 104.72 35.3274 103.844 44.5084 105.513C54.3154 107.266 63.2461 111.982 72.3019 115.946C81.6499 120.078 91.1648 123.04 101.431 123.541C111.655 124.084 121.879 122.623 131.812 120.119C152.552 114.903 171.582 104.887 191.488 97.3336C201.462 93.536 211.77 90.2392 222.37 88.7368C228.129 87.9022 233.93 87.6935 239.73 88.3195C245.239 88.9037 250.623 90.1974 256.006 91.5328C267.9 94.4958 279.751 97.0832 291.937 98.5438C304.498 100.046 317.185 100.547 329.83 100.13C330.623 100.088 330.623 98.8359 329.83 98.8777C306.877 99.7123 283.925 97.2501 261.64 91.6998C250.998 89.0289 240.44 86.2746 229.381 86.8171C218.656 87.3597 208.056 90.1974 197.957 93.6612C177.717 100.63 158.687 110.855 138.155 117.073C127.722 120.203 116.913 122.373 105.98 122.414C94.8789 122.456 84.4459 119.869 74.305 115.445C64.2894 111.105 54.4824 105.722 43.5903 104.052C34.3258 102.634 24.4354 103.76 16.9654 109.853C16.3394 110.396 17.2157 111.314 17.8417 110.771Z"
				fill="#D0D5DD"
			/>
			<path
				d="M242.61 72.6284C271.53 85.1063 304.373 83.0197 333.961 73.9639C349.986 69.0395 365.928 62.4458 382.996 62.4876C400.19 62.5293 416.507 68.5387 433.325 71.2095C448.599 73.63 465.417 73.3379 478.521 64.1568C479.189 63.6978 478.563 62.6128 477.895 63.0718C465.793 71.5434 450.435 72.2946 436.246 70.3749C420.18 68.2048 404.739 62.6128 388.505 61.4443C372.271 60.2758 356.956 65.1584 341.724 70.1662C326.533 75.1323 311.176 79.0134 295.192 79.9315C277.498 80.9748 259.595 78.5961 243.278 71.5434C242.527 71.2095 241.901 72.2946 242.61 72.6284Z"
				fill="#D0D5DD"
			/>
			<path
				d="M1.90021 176.666C11.0395 170.156 21.8481 166.15 33.0322 165.065C38.6243 164.522 44.2581 164.731 49.7668 165.69C55.484 166.692 60.9927 168.612 66.4595 170.531C76.6004 174.037 86.6578 176.708 97.3412 174.246C106.564 172.117 114.91 167.318 123.09 162.769C132.73 157.386 142.746 151.877 153.93 150.876C154.723 150.792 154.723 149.54 153.93 149.624C133.982 151.418 119 166.4 100.388 172.117C95.6719 173.578 90.7475 174.329 85.8232 174.037C79.772 173.661 73.8878 171.783 68.2123 169.822C62.495 167.861 56.7777 165.732 50.7683 164.605C45.5936 163.646 40.2936 163.353 35.0771 163.687C22.9748 164.439 11.1647 168.57 1.27423 175.623C0.606519 176.04 1.2325 177.125 1.90021 176.666Z"
				fill="#D0D5DD"
			/>
			<path
				d="M112.531 192.691C128.765 175.539 152.636 168.528 175.714 168.32C189.11 168.195 202.464 170.114 215.526 172.91C227.962 175.539 240.023 178.961 252.876 177.25C266.314 175.456 278.875 169.697 290.894 163.646C291.603 163.27 290.977 162.185 290.268 162.561C278.833 168.32 266.94 173.787 254.211 175.79C247.701 176.791 241.149 176.708 234.639 175.665C228.046 174.621 221.619 172.869 215.067 171.491C191.196 166.484 165.781 164.355 142.328 172.493C130.768 176.499 120.085 182.884 111.655 191.773C111.113 192.399 111.989 193.275 112.531 192.691Z"
				fill="#D0D5DD"
			/>
			<path
				d="M305.959 205.169C312.761 207.548 319.731 205.336 325.949 202.373C332.918 199.035 339.47 194.903 346.606 191.94C353.742 188.977 361.421 186.974 369.183 187.934C376.277 188.81 382.829 191.982 389.047 195.362C394.931 198.576 400.732 202.081 406.992 204.543C413.961 207.256 421.473 208.549 428.943 208.383C444.259 208.049 457.279 200.412 471.259 195.237C484.321 190.396 501.724 188.226 512.198 199.577C512.741 200.161 513.617 199.285 513.075 198.701C504.853 189.812 491.75 188.81 480.482 191.189C465.792 194.277 453.106 203.25 438.333 206.129C430.445 207.673 422.182 207.464 414.379 205.586C407.117 203.834 400.607 200.412 394.097 196.781C388.213 193.484 382.245 190.062 375.777 188.101C369.058 186.056 362.047 186.014 355.244 187.683C346.94 189.728 339.303 193.776 331.833 197.866C324.112 202.039 315.182 207.131 306.209 204.001C305.542 203.709 305.208 204.919 305.959 205.169Z"
				fill="#D0D5DD"
			/>
			<path
				d="M415.84 225.284C433.117 226.327 447.347 214.601 463.664 211.053C468.005 210.094 472.595 209.676 477.019 210.427C480.983 211.095 484.572 212.723 487.994 214.809C493.878 218.398 499.596 223.03 506.773 223.531C515.495 224.116 523.216 218.69 529.726 213.599C530.352 213.098 529.476 212.222 528.85 212.723C522.506 217.647 514.911 223.03 506.398 222.238C499.011 221.57 493.169 216.228 486.951 212.764C472.637 204.752 457.321 211.053 443.591 217.105C434.786 220.944 425.647 224.658 415.881 224.074C415.047 223.99 415.047 225.242 415.84 225.284Z"
				fill="#D0D5DD"
			/>
			<path
				d="M21.9304 305.075C27.9815 299.316 36.9539 297.063 45.0081 299.275C53.438 301.57 58.8632 308.831 65.6237 313.839C79.1031 323.813 97.4652 322.227 112.28 316.343C129.766 309.415 144.33 296.896 161.774 289.843C170.287 286.379 179.51 284.168 188.775 284.794C197.789 285.42 205.467 289.301 212.604 294.559C219.698 299.775 226.375 305.952 234.68 309.249C243.569 312.754 253.584 312.003 261.931 307.371C262.64 306.995 262.014 305.91 261.305 306.286C252.792 311.001 242.609 311.419 233.72 307.496C226.292 304.199 220.199 298.69 213.73 293.849C207.095 288.883 200.042 285.002 191.696 283.834C183.099 282.665 174.335 284.126 166.198 286.838C148.378 292.806 133.689 305.117 116.787 313.004C101.179 320.308 81.1063 323.73 66.2914 312.754C58.9049 307.287 53.1041 299.483 43.5893 297.647C35.535 296.103 26.9799 298.523 21.054 304.199C20.4698 304.741 21.3461 305.66 21.9304 305.075Z"
				fill="#D0D5DD"
			/>
			<path
				d="M182.266 328.362C189.152 323.896 197.331 321.768 205.552 322.436C213.064 323.062 220.158 325.858 227.044 328.654C233.721 331.366 240.482 334.204 247.66 335.373C255.172 336.583 262.767 335.665 269.986 333.453C278.625 330.782 286.637 326.525 294.525 322.227C295.234 321.852 294.608 320.766 293.899 321.142C279.46 328.946 263.601 337.167 246.658 333.912C232.511 331.199 220.075 322.436 205.511 321.184C197.081 320.474 188.651 322.686 181.598 327.277C180.972 327.736 181.598 328.821 182.266 328.362Z"
				fill="#D0D5DD"
			/>
			<path
				d="M339.803 356.656C348.901 348.143 360.085 342.008 372.146 338.878C378.28 337.292 384.582 336.5 390.925 336.541C397.978 336.625 404.989 337.752 412 338.419C425.604 339.713 438.666 337.418 451.395 332.452C463.539 327.736 474.973 319.556 488.035 317.553C503.309 315.216 515.119 325.357 524.843 335.748C525.385 336.333 526.262 335.456 525.719 334.872C517.707 326.317 508.275 317.47 496.09 316.093C482.735 314.59 470.591 322.436 458.99 327.819C445.636 334.037 431.238 338.294 416.423 337.459C409.037 337.042 401.734 335.707 394.305 335.331C388.004 334.997 381.661 335.456 375.484 336.75C361.838 339.546 349.11 346.14 338.927 355.696C338.343 356.322 339.219 357.199 339.803 356.656Z"
				fill="#D0D5DD"
			/>
			<path
				d="M95.4624 92.952C107.147 102.008 122.38 98.0016 135.108 93.8284C150.882 88.6119 166.866 85.3151 183.559 84.9812C184.351 84.9812 184.351 83.7292 183.559 83.7292C168.201 84.0214 153.053 86.6505 138.446 91.4079C125.217 95.7063 108.775 101.757 96.3388 92.0756C95.7128 91.5749 94.8364 92.4512 95.4624 92.952Z"
				fill="#050911"
			/>
			<path
				d="M207.973 17.0833C222.078 16.04 235.683 12.1172 249.329 8.82035C263.518 5.3566 277.749 3.06134 292.397 4.64715C307.17 6.23297 321.484 10.6983 335.965 13.8699C349.903 16.9581 364.051 18.377 378.323 17.6675C394.39 16.8746 410.248 13.4943 425.23 7.73532C425.981 7.44319 425.647 6.23297 424.896 6.52509C397.645 16.9998 367.89 19.2116 339.345 13.2857C325.115 10.3227 311.176 5.98257 296.779 3.89598C282.882 1.89284 269.319 3.01961 255.631 6.02431C239.773 9.48806 224.207 14.5794 207.973 15.7896C207.18 15.9148 207.18 17.1667 207.973 17.0833Z"
				fill="#050911"
			/>
			<path
				d="M433.826 121.664C446.012 117.157 458.656 123.583 470.842 124.752C477.394 125.378 483.904 124.752 490.289 123.249C496.549 121.747 502.642 119.535 509.027 118.575C515.871 117.532 523.174 117.949 529.1 121.831C529.768 122.29 530.394 121.205 529.726 120.745C519.209 113.901 506.356 117.574 495.255 120.662C488.286 122.623 481.317 124.042 474.055 123.75C467.128 123.5 460.493 121.58 453.732 120.161C447.013 118.784 440.086 118.033 433.534 120.453C432.741 120.704 433.075 121.914 433.826 121.664Z"
				fill="#050911"
			/>
			<path
				d="M8.45254 248.278C19.0525 238.847 33.9925 247.193 45.7609 248.988C59.3655 251.074 72.9701 247.318 85.9488 243.688C86.7417 243.479 86.4078 242.269 85.6149 242.477C71.2591 246.484 56.4026 250.448 41.5042 246.901C30.6122 244.314 17.2997 238.805 7.53444 247.402C6.95019 247.903 7.82656 248.779 8.45254 248.278Z"
				fill="#050911"
			/>
			<path
				d="M141.91 365.461C145.29 361.455 150.507 359.285 155.765 359.953C158.77 360.328 161.524 361.706 164.279 362.916C166.574 363.917 168.911 364.752 171.289 365.42C181.514 368.258 192.489 367.84 202.463 364.335C203.214 364.084 202.881 362.874 202.129 363.125C190.945 367.006 178.593 367.006 167.45 362.832C162.609 361.038 158.102 358.158 152.719 358.576C148.17 358.91 143.955 361.08 140.992 364.543C140.491 365.211 141.409 366.087 141.91 365.461Z"
				fill="#050911"
			/>
			<path
				d="M212.94 362.039C220.535 359.828 228.339 358.743 236.268 358.784C237.061 358.784 237.061 357.532 236.268 357.532C228.255 357.491 220.326 358.617 212.606 360.829C211.855 361.08 212.147 362.29 212.94 362.039Z"
				fill="#050911"
			/>
			<path
				d="M222.871 37.657C230.258 43.3326 237.31 49.4254 244.071 55.8939C244.655 56.4364 245.532 55.56 244.947 55.0175C238.145 48.5073 230.967 42.3727 223.497 36.6137C222.871 36.0712 222.245 37.1562 222.871 37.657Z"
				fill="#050911"
			/>
			<path
				d="M150.508 59.6497C151.218 55.7269 151.969 51.8041 152.678 47.8396C152.845 47.0467 151.635 46.7128 151.468 47.5057C150.759 51.4286 150.008 55.3514 149.298 59.3159C149.173 60.1088 150.383 60.4427 150.508 59.6497Z"
				fill="#050911"
			/>
			<circle cx="391.133" cy="82.1017" r="2.42045" fill="#FF692A" />
			<circle cx="74.8898" cy="281.539" r="2.8795" fill="#FF692A" />
			<circle cx="60.9511" cy="16.958" r="6.59365" fill="#FF692A" />
			<circle cx="74.1791" cy="27.7249" r="2.21179" fill="#FF692A" />
			<circle cx="200.544" cy="396.218" r="4.92437" fill="#FF692A" />
			<circle cx="429.401" cy="27.8084" r="4.34012" fill="#FF692A" />
			<circle cx="434.952" cy="19.9211" r="2.67084" fill="#FF692A" />
			<circle cx="67.5858" cy="10.0305" r="6.59365" fill="#F79222" />
			<circle cx="64.289" cy="285.003" r="3.29682" fill="#F79222" />
			<circle cx="180.18" cy="388.665" r="2.92124" fill="#F79222" />
			<circle cx="413.378" cy="10.3226" r="6.9275" fill="#F79222" />
			<circle cx="299.324" cy="120.662" r="1.50235" fill="#F79222" />
			<path
				d="M283.925 32.7326C283.049 34.7775 282.214 36.8641 281.338 38.909C281.045 39.6601 282.256 39.994 282.548 39.2428C283.424 37.1979 284.259 35.1113 285.135 33.0665C285.427 32.3153 284.217 31.9814 283.925 32.7326Z"
				fill="#F79222"
			/>
			<path
				d="M283.716 33.7342C285.26 35.3618 286.471 37.198 287.264 39.2846C287.556 40.0357 288.766 39.7019 288.474 38.9507C287.556 36.6554 286.262 34.6523 284.593 32.8578C284.05 32.2736 283.132 33.15 283.716 33.7342Z"
				fill="#F79222"
			/>
			<path
				d="M281.67 39.9941C282.881 42.2476 284.258 44.3759 285.802 46.3791C286.303 47.0051 287.388 46.3791 286.887 45.7531C285.343 43.7082 283.966 41.5799 282.756 39.3681C282.338 38.6587 281.295 39.2846 281.67 39.9941Z"
				fill="#F79222"
			/>
			<path
				d="M287.346 39.3682C286.846 41.7052 286.387 44.0422 285.886 46.3792C285.719 47.1721 286.929 47.506 287.096 46.7131C287.597 44.3761 288.056 42.0391 288.557 39.7021C288.724 38.9509 287.513 38.6171 287.346 39.3682Z"
				fill="#F79222"
			/>
			<path
				d="M401.483 211.387C400.481 213.223 399.813 215.185 399.521 217.271C399.396 218.064 400.606 218.398 400.732 217.605C401.024 215.644 401.608 213.808 402.568 212.055C402.943 211.304 401.858 210.678 401.483 211.387Z"
				fill="#F79222"
			/>
			<path
				d="M401.4 211.763C402.234 213.975 403.194 216.103 404.279 218.148C404.655 218.857 405.74 218.231 405.364 217.522C404.321 215.56 403.403 213.516 402.61 211.429C402.318 210.678 401.108 211.012 401.4 211.763Z"
				fill="#F79222"
			/>
			<path
				d="M399.271 218.148C400.356 219.692 401.399 221.236 402.484 222.78C402.943 223.448 404.028 222.822 403.569 222.154C402.484 220.61 401.441 219.066 400.356 217.522C399.897 216.854 398.812 217.48 399.271 218.148Z"
				fill="#F79222"
			/>
			<path
				d="M403.82 217.939C402.944 219.149 402.526 220.526 402.526 221.987C402.526 222.78 403.778 222.78 403.778 221.987C403.778 220.735 404.154 219.567 404.905 218.565C405.364 217.897 404.279 217.271 403.82 217.939Z"
				fill="#F79222"
			/>
			<path
				d="M52.396 329.53C51.2692 331.992 50.1424 334.413 49.0157 336.875C48.6818 337.584 49.7668 338.252 50.1007 337.501C51.2275 335.039 52.3542 332.618 53.481 330.156C53.8148 329.447 52.7715 328.821 52.396 329.53Z"
				fill="#F79222"
			/>
			<path
				d="M52.6042 329.864C53.6893 332.451 54.7743 335.039 55.8593 337.626C56.1514 338.377 57.3617 338.044 57.0695 337.292C55.9845 334.705 54.8995 332.118 53.8144 329.53C53.5223 328.779 52.3121 329.113 52.6042 329.864Z"
				fill="#F79222"
			/>
			<path
				d="M48.723 338.002C49.8498 340.214 50.9348 342.467 52.0616 344.679C52.2702 345.055 52.771 345.055 53.0631 344.804C53.9395 344.095 54.3568 342.885 54.8159 341.925C55.4836 340.589 56.1513 339.254 56.819 337.919C57.1946 337.209 56.1096 336.583 55.734 337.293C55.1497 338.461 54.5238 339.63 53.9395 340.798C53.4387 341.8 52.9797 343.177 52.1033 343.886C52.4372 343.928 52.771 343.97 53.1049 344.011C51.9781 341.8 50.8931 339.546 49.7663 337.334C49.4325 336.667 48.3474 337.293 48.723 338.002Z"
				fill="#F79222"
			/>
			<path
				d="M472.428 135.56C471.135 138.022 470.342 140.61 469.966 143.364C469.841 144.157 471.093 144.157 471.218 143.364C471.552 140.818 472.303 138.44 473.472 136.186C473.889 135.477 472.804 134.851 472.428 135.56Z"
				fill="#F79222"
			/>
			<path
				d="M472.26 137.021C473.554 139.525 474.806 142.029 476.1 144.533C476.475 145.242 477.56 144.616 477.185 143.907C475.891 141.403 474.639 138.899 473.346 136.395C472.97 135.685 471.885 136.311 472.26 137.021Z"
				fill="#F79222"
			/>
			<path
				d="M470.049 143.531C471.092 146.035 472.344 148.372 473.846 150.584C474.305 151.252 475.39 150.626 474.931 149.958C473.471 147.83 472.219 145.576 471.259 143.197C470.967 142.488 469.756 142.822 470.049 143.531Z"
				fill="#F79222"
			/>
			<path
				d="M475.725 144.074C474.974 146.411 474.348 148.789 473.93 151.251C473.805 152.044 475.015 152.378 475.141 151.585C475.558 149.165 476.142 146.786 476.935 144.407C477.186 143.656 475.975 143.322 475.725 144.074Z"
				fill="#F79222"
			/>
			<path
				d="M467.587 338.169C467.086 339.838 466.627 341.466 466.126 343.135C465.917 343.928 467.086 344.22 467.336 343.469C467.837 341.8 468.296 340.172 468.797 338.503C469.047 337.71 467.837 337.376 467.587 338.169Z"
				fill="#F79222"
			/>
			<path
				d="M467.502 339.087C468.254 340.756 469.047 342.426 469.798 344.053C470.132 344.804 471.217 344.137 470.883 343.427C470.132 341.758 469.339 340.089 468.588 338.461C468.254 337.71 467.169 338.336 467.502 339.087Z"
				fill="#F79222"
			/>
			<path
				d="M466.461 343.928C466.836 345.889 467.754 347.642 469.048 349.145C469.59 349.771 470.467 348.852 469.924 348.268C468.756 346.933 468.005 345.347 467.671 343.636C467.504 342.801 466.294 343.135 466.461 343.928Z"
				fill="#F79222"
			/>
			<path
				d="M469.756 343.761C469.214 345.514 468.963 347.308 469.005 349.102C469.047 349.895 470.299 349.895 470.257 349.102C470.215 347.391 470.424 345.722 470.966 344.095C471.217 343.343 470.007 343.01 469.756 343.761Z"
				fill="#F79222"
			/>
			<path
				d="M36.6618 117.991C35.7437 119.994 34.8674 121.997 33.9493 124.001C33.6154 124.71 34.7004 125.378 35.0343 124.627C35.9524 122.623 36.8288 120.62 37.7469 118.617C38.0807 117.908 36.9957 117.24 36.6618 117.991Z"
				fill="#F79222"
			/>
			<path
				d="M34.1164 125.044C35.0763 127.506 35.9944 129.927 36.9542 132.389C37.2463 133.14 38.4565 132.806 38.1644 132.055C37.2046 129.593 36.2865 127.172 35.3267 124.71C35.0345 123.959 33.8243 124.293 34.1164 125.044Z"
				fill="#F79222"
			/>
			<path
				d="M36.6632 118.075C37.9569 120.328 39.2506 122.623 40.5443 124.877C40.9616 125.586 42.0049 124.96 41.6293 124.251C40.3356 121.997 39.0419 119.702 37.7482 117.449C37.3309 116.739 36.2459 117.365 36.6632 118.075Z"
				fill="#F79222"
			/>
			<path
				d="M40.8358 124.334C39.5004 126.838 38.3319 129.426 37.3303 132.055C37.0382 132.806 38.2484 133.14 38.5405 132.389C39.5004 129.843 40.6271 127.339 41.9208 124.96C42.2964 124.251 41.2114 123.625 40.8358 124.334Z"
				fill="#F79222"
			/>
			<path
				d="M351.238 156.468C352.073 154.882 353.659 153.797 355.495 153.881C356.997 153.922 358.124 154.966 359.459 155.467C361.796 156.301 362.506 154.799 364.259 153.672C366.637 152.086 368.933 155.633 371.478 155.592C372.939 155.55 374.149 154.841 374.191 153.296C373.857 153.463 373.565 153.672 373.231 153.839C374.525 154.507 375.818 155.174 377.07 155.884C377.78 156.259 378.406 155.174 377.696 154.799C376.403 154.131 375.109 153.463 373.857 152.754C373.44 152.545 372.939 152.796 372.897 153.296C372.772 156.259 367.013 152.044 365.928 151.919C363.466 151.585 362.506 154.632 360.378 154.298C358.124 153.922 356.705 152.295 354.285 152.712C352.448 153.046 350.946 154.173 350.111 155.842C349.819 156.552 350.863 157.178 351.238 156.468Z"
				fill="#F79222"
			/>
			<path
				d="M367.221 147.996C369.099 145.91 372.062 148.539 374.191 148.789C376.653 149.081 378.572 146.452 380.951 147.788C382.913 148.914 386.502 151.585 387.92 148.163C388.213 147.412 387.002 147.078 386.71 147.829C385.709 150.25 382.12 146.87 380.909 146.369C379.282 145.659 378.197 146.244 376.736 146.953C374.984 147.788 373.732 147.496 372.062 146.744C371.311 146.411 370.56 146.118 369.725 145.993C368.432 145.826 367.263 146.077 366.345 147.078C365.803 147.704 366.679 148.581 367.221 147.996Z"
				fill="#F79222"
			/>
			<path
				d="M333.335 49.5506C333.919 48.4656 334.587 48.1735 335.338 48.6743C335.672 48.7995 336.006 48.9664 336.34 49.1751C336.716 49.342 337.091 49.5924 337.467 49.7176C338.134 49.9262 338.802 50.0097 339.47 49.8428C341.264 49.342 342.182 47.0885 344.102 47.1302C345.897 47.1719 347.19 48.8829 349.068 48.0066C349.861 47.631 350.404 46.9633 351.155 46.5459C352.824 45.6278 354.869 45.9617 356.288 47.1302C356.914 47.631 357.79 46.7546 357.164 46.2538C355.286 44.7097 352.699 44.2507 350.529 45.4609C349.569 46.0034 348.776 47.1719 347.524 47.005C346.815 46.9215 346.189 46.4207 345.521 46.1704C344.519 45.7948 343.601 45.6696 342.6 46.1286C341.348 46.7129 340.513 48.3404 339.136 48.6325C337.383 49.0081 336.215 47.2136 334.587 47.1719C333.043 47.1302 331.249 48.6325 332.292 50.1766C332.709 50.8443 333.794 50.2184 333.335 49.5506Z"
				fill="#FF692A"
			/>
			<path
				d="M347.19 42.1642C347.691 39.4516 352.407 42.0807 354.076 41.7886C355.161 41.6217 355.954 40.8705 356.955 40.4949C358.541 39.8689 360.085 40.1193 361.546 40.9957C362.255 41.413 362.881 40.328 362.172 39.9107C360.92 39.1595 359.585 38.7839 358.166 38.9091C357.54 38.9508 356.955 39.1178 356.413 39.3264C354.744 40.0359 354.076 40.8288 351.989 40.328C349.986 39.8272 346.564 38.8256 346.022 41.8303C345.813 42.6232 347.023 42.9571 347.19 42.1642Z"
				fill="#FF692A"
			/>
			<path
				d="M31.2375 192.983C31.78 190.354 35.4107 193.943 36.1202 194.402C36.9131 194.945 37.7894 195.32 38.7493 195.112C40.0012 194.903 40.9611 193.86 42.2548 193.568C44.3414 193.109 46.4279 193.776 47.9303 195.279C48.5145 195.863 49.3909 194.945 48.8067 194.402C47.513 193.109 45.9272 192.316 44.091 192.19C43.1729 192.107 42.2548 192.232 41.3784 192.524C40.0012 192.983 38.9162 194.319 37.2469 193.651C35.4942 192.942 34.8265 191.147 32.8233 190.938C31.5296 190.813 30.3194 191.314 30.069 192.65C29.8604 193.442 31.0706 193.776 31.2375 192.983Z"
				fill="#FF692A"
			/>
			<path
				d="M45.1349 189.979C46.053 188.727 50.2679 191.231 51.4782 191.523C53.4396 191.982 55.4427 191.982 57.4458 191.731C58.2387 191.606 57.9049 190.396 57.112 190.521C53.6482 190.98 50.9774 190.062 47.8475 188.81C46.4286 188.268 45.0097 188.059 44.0499 189.353C43.5908 189.979 44.6759 190.605 45.1349 189.979Z"
				fill="#FF692A"
			/>
			<path
				d="M146.876 277.449C147.544 276.405 148.587 275.738 149.881 275.738C150.966 275.738 151.634 276.405 152.593 276.697C153.553 277.031 154.346 276.823 155.181 276.28C156.808 275.195 157.601 273.86 159.813 273.943C161.816 273.985 163.736 274.945 165.489 275.821C166.198 276.197 166.824 275.112 166.115 274.736C162.818 273.067 158.811 271.481 155.64 274.193C154.93 274.778 154.847 274.945 153.762 275.153C152.552 275.404 152.677 275.237 151.759 274.861C150.966 274.527 150.215 274.402 149.338 274.486C147.794 274.653 146.584 275.529 145.791 276.781C145.374 277.49 146.459 278.116 146.876 277.449Z"
				fill="#FF692A"
			/>
			<path
				d="M160.773 279.869C162.735 278.158 166.449 281.747 168.869 281.413C170.33 281.205 170.997 280.12 171.957 279.118C173.794 277.157 176.548 276.865 178.801 278.325C179.469 278.743 180.095 277.699 179.427 277.24C177.8 276.197 175.88 275.863 174.002 276.406C173.168 276.656 172.416 277.032 171.749 277.574C170.747 278.409 170.121 279.911 168.702 280.161C165.656 280.704 162.776 276.447 159.897 278.993C159.313 279.535 160.189 280.412 160.773 279.869Z"
				fill="#FF692A"
			/>
			<path
				d="M103.851 12.2006C104.185 10.8234 104.978 9.73839 106.314 9.15414C107.482 8.65336 108.442 8.98722 109.652 8.94548C110.988 8.86202 111.989 8.19431 112.782 7.19274C113.992 5.69039 114.785 4.14631 116.496 3.14474C118.458 2.01798 120.711 1.80932 122.881 2.47703C123.674 2.72742 123.966 1.5172 123.215 1.2668C119.209 0.0565772 115.244 1.60066 112.824 4.98095C111.864 6.31637 111.155 7.65179 109.318 7.69352C108.192 7.73526 107.232 7.48487 106.147 7.86045C104.311 8.48643 103.059 10.0305 102.641 11.8667C102.433 12.6596 103.643 12.9935 103.851 12.2006Z"
				fill="#FF692A"
			/>
			<path
				d="M109.736 16.9999C107.941 12.2424 116.538 12.4928 119.125 11.5747C122.339 10.4479 125.093 8.4448 127.806 6.44167C128.431 5.98262 127.806 4.89759 127.18 5.35664C125.343 6.73379 123.465 8.06922 121.462 9.15425C118.958 10.4897 116.58 10.907 113.867 11.3243C110.988 11.7834 107.232 13.7865 108.567 17.292C108.817 18.0432 110.028 17.751 109.736 16.9999Z"
				fill="#FF692A"
			/>
			<path
				d="M276.163 256.249C279.334 247.944 284.718 242.519 290.268 236.885C297.571 229.498 311.134 215.769 324.947 217.939C336.716 219.775 335.422 231.084 351.572 239.305C366.805 247.109 372.647 239.389 387.837 247.777C390.091 249.029 405.991 258.085 409.622 275.195C411.124 282.289 410.748 292.013 407.618 292.931C404.071 293.974 402.861 273.526 388.672 265.513C377.822 259.378 362.882 262.341 361.672 266.723C360.378 271.356 375.36 274.402 383.831 290.51C387.545 297.521 388.338 303.906 389.465 312.67C390.508 320.933 391.677 337.876 387.837 338.878C384.666 339.712 382.955 328.403 371.312 319.139C365.177 314.256 358.625 312.253 345.521 308.247C333.711 304.658 327.743 302.905 324.53 305.826C319.773 310.166 323.445 321.183 324.113 323.145C328.787 337.167 340.722 343.009 339.846 344.512C338.677 346.39 316.893 340.964 299.115 323.562C283.925 308.664 266.94 280.328 276.163 256.249Z"
				fill="#FF692A"
			/>
			<path
				d="M412.418 304.616C412.251 305.784 411.374 312.295 415.214 316.468C419.47 321.058 424.645 317.553 429.862 322.102C434.661 326.275 434.035 332.535 435.496 332.409C437.165 332.284 439.71 323.813 436.121 318.68C434.995 317.052 432.24 315.591 426.774 312.754C421.181 309.832 420.43 309.958 418.97 308.372C415.339 304.449 415.881 299.316 414.588 299.316C413.586 299.316 412.71 302.404 412.418 304.616Z"
				fill="#FF692A"
			/>
			<path
				d="M376.528 353.609C374.191 357.115 374.441 360.871 374.65 364.835C374.9 368.883 375.067 372.18 377.446 375.143C380.158 378.523 384.665 379.942 385.249 379.191C385.709 378.607 383.664 377.021 381.827 373.557C381.619 373.14 378.906 368.007 379.031 361.997C379.157 354.486 383.58 350.02 382.453 349.227C381.619 348.601 378.405 350.771 376.528 353.609Z"
				fill="#FF692A"
			/>
			<path
				d="M393.263 284.418C400.274 289.05 396.435 299.775 401.025 306.035C401.484 306.661 402.569 306.035 402.11 305.409C397.353 298.898 401.401 288.299 393.889 283.332C393.221 282.873 392.595 283.958 393.263 284.418Z"
				fill="#FF692A"
			/>
			<path
				d="M390.759 254.204C398.271 259.963 403.487 268.184 405.574 277.406C405.741 278.199 406.951 277.866 406.784 277.073C404.614 267.516 399.189 259.086 391.427 253.16C390.717 252.618 390.091 253.703 390.759 254.204Z"
				fill="#F79222"
			/>
			<path
				d="M366.554 277.323C372.564 282.248 377.279 288.549 380.2 295.769C380.493 296.52 381.703 296.186 381.411 295.435C378.364 288.007 373.649 281.538 367.43 276.447C366.846 275.946 365.928 276.822 366.554 277.323Z"
				fill="#F79222"
			/>
			<path
				d="M383.122 302.655C384.249 305.659 385 308.706 385.292 311.877C385.375 312.67 386.627 312.67 386.544 311.877C386.21 308.581 385.459 305.409 384.29 302.321C384.082 301.57 382.871 301.903 383.122 302.655Z"
				fill="#F79222"
			/>
			<path
				d="M318.897 309.582C317.854 315.883 319.064 322.185 322.486 327.61C322.903 328.278 323.988 327.652 323.571 326.984C320.358 321.893 319.106 315.883 320.107 309.916C320.232 309.123 319.022 308.789 318.897 309.582Z"
				fill="#F79222"
			/>
			<path
				d="M324.155 331.032C325.657 333.786 327.577 336.249 329.872 338.377C330.457 338.919 331.333 338.043 330.749 337.501C328.537 335.414 326.701 333.035 325.24 330.406C324.864 329.697 323.779 330.323 324.155 331.032Z"
				fill="#F79222"
			/>
			<path
				d="M276.246 267.433C275.912 271.397 276.287 275.32 277.414 279.159C277.623 279.952 278.833 279.619 278.624 278.826C277.539 275.07 277.164 271.314 277.498 267.433C277.581 266.64 276.329 266.64 276.246 267.433Z"
				fill="#F79222"
			/>
			<path
				d="M278.667 283.416C279.334 286.671 280.545 289.676 282.297 292.514C282.715 293.181 283.8 292.555 283.382 291.888C281.713 289.133 280.545 286.254 279.877 283.082C279.71 282.331 278.5 282.665 278.667 283.416Z"
				fill="#F79222"
			/>
			<path
				d="M169.704 22.55C168.535 25.7217 167.117 30.0618 165.739 35.32C164.32 40.7869 160.982 55.1844 161.316 74.1307C161.65 92.4093 165.239 105.847 168.535 117.824C173.668 136.52 179.928 149.958 183.642 157.762C183.642 157.762 195.453 182.759 215.025 209.551C218.489 214.267 222.912 220.234 222.912 220.234C226.126 224.533 228.88 228.122 230.799 230.709C232.344 232.462 234.305 234.423 236.851 236.301C241.608 239.848 246.324 241.643 249.621 242.561C255.046 237.929 260.471 233.255 265.896 228.622C268.358 230.959 270.862 233.255 273.324 235.592C276.746 232.086 279.793 229.29 282.172 227.245C292.521 218.273 301.076 213.682 303.079 212.597C304.79 211.679 306.251 210.97 307.252 210.511C305.708 207.423 304.164 204.334 302.62 201.205C305.124 199.034 308.004 196.739 311.217 194.486C315.557 191.439 319.73 188.977 323.528 187.057C323.486 183.552 323.027 178.252 320.732 172.41C319.021 168.028 316.976 165.065 316.1 163.813C306.46 149.916 334.879 188.226 297.738 135.226C283.34 114.694 276.162 104.386 272.865 100.13C262.558 86.6503 254.462 77.4276 248.703 70.8757C237.685 58.3143 232.719 53.6404 228.713 50.2183C223.246 45.4609 217.195 40.3279 208.056 35.5704C202.297 32.6075 197.539 30.9799 190.612 28.6012C182.057 25.6799 174.837 23.7603 169.704 22.55Z"
				fill="white"
			/>
			<path
				d="M168.411 23.3011C159.355 51.2197 159.355 81.3502 165.865 109.811C167.492 116.948 169.537 124 171.958 130.886C173.168 134.392 174.629 137.814 175.922 141.277C176.381 142.488 176.465 143.698 177.007 144.908C177.8 146.786 178.635 148.664 179.469 150.542C181.014 154.047 182.641 157.511 184.352 160.975C186.063 164.397 188.15 167.652 189.694 171.116C190.278 172.409 190.779 173.786 191.447 175.038C192.323 176.624 193.617 177.834 194.535 179.379C196.454 182.592 198.291 185.889 200.294 189.06C209.433 203.792 219.824 217.688 230.466 231.335C230.967 231.961 231.843 231.084 231.342 230.458C221.953 218.398 212.772 206.17 204.509 193.359C202.464 190.187 200.502 187.016 198.583 183.76C197.665 182.216 196.747 180.631 195.87 179.045C194.994 177.501 193.825 176.416 192.907 174.913C192.156 173.661 191.613 172.326 191.071 170.99C190.32 169.196 189.318 167.568 188.4 165.857C186.647 162.644 185.02 159.389 183.476 156.092C181.848 152.628 180.304 149.123 178.76 145.617C178.092 144.073 177.842 142.446 177.216 140.86C176.548 139.149 175.839 137.438 175.171 135.685C172.625 128.966 170.414 122.122 168.577 115.153C164.905 101.048 162.777 86.5667 162.568 71.9605C162.359 55.5599 164.613 39.1592 169.704 23.5097C169.829 22.8837 168.619 22.5499 168.411 23.3011Z"
				fill="#050911"
			/>
			<path
				d="M168.994 23.3013C175.964 23.8438 183.058 26.2642 189.61 28.5595C196.078 30.8547 202.463 33.3587 208.389 36.7807C220.199 43.6247 230.632 52.5971 240.147 62.3624C249.954 72.4198 258.885 83.4787 267.523 94.5377C276.12 105.555 284.341 116.948 292.271 128.466C301.034 141.277 309.464 154.34 317.727 167.527C318.144 168.195 319.229 167.569 318.812 166.901C303.663 142.822 288.014 119.034 270.612 96.4991C253.794 74.6733 235.765 52.3884 212.145 37.5736C205.718 33.5256 198.833 30.6044 191.738 27.9752C184.56 25.3461 176.715 22.6335 169.036 22.0076C168.201 22.0076 168.201 23.2595 168.994 23.3013Z"
				fill="#050911"
			/>
			<path
				d="M232.26 230.959C251.707 211.137 273.575 193.735 297.237 179.212C303.914 175.122 310.674 171.283 317.602 167.694C318.311 167.318 317.685 166.233 316.976 166.609C292.396 179.462 269.36 195.279 248.619 213.682C242.693 218.941 236.934 224.408 231.384 230.083C230.799 230.667 231.676 231.544 232.26 230.959Z"
				fill="#050911"
			/>
			<path
				d="M231.051 231.919C236.017 237.47 242.485 241.392 249.705 243.145C250.498 243.354 250.832 242.144 250.039 241.935C242.986 240.182 236.768 236.426 231.927 231.001C231.384 230.417 230.508 231.335 231.051 231.919Z"
				fill="#050911"
			/>
			<path
				d="M317.393 168.028C320.648 173.745 322.526 180.046 322.944 186.598C322.985 187.391 324.237 187.391 324.196 186.598C323.778 179.796 321.817 173.328 318.478 167.402C318.103 166.692 317.018 167.318 317.393 168.028Z"
				fill="#050911"
			/>
			<path
				d="M250.456 242.811C273.033 222.196 297.488 203.75 323.57 187.767C324.238 187.349 323.612 186.264 322.944 186.682C296.82 202.707 272.24 221.236 249.579 241.935C248.995 242.477 249.872 243.354 250.456 242.811Z"
				fill="#050911"
			/>
			<path
				d="M266.188 229.374C268.484 231.627 270.779 233.881 273.074 236.176C273.324 236.426 273.742 236.426 273.95 236.176C280.711 228.998 288.306 222.655 296.611 217.355C298.614 216.061 300.659 214.851 302.745 213.682C304.081 212.931 306.627 212.222 307.419 210.803C308.254 209.384 307.002 207.464 306.334 206.254C305.166 204.126 303.789 202.164 302.286 200.328C301.786 199.702 300.909 200.578 301.41 201.204C302.871 202.999 304.164 204.877 305.249 206.88C305.5 207.297 306.042 208.007 306.084 208.508C305.917 209.259 305.75 209.968 305.583 210.719C305.375 210.845 305.166 210.928 304.957 211.053C304.123 211.679 303.038 212.055 302.119 212.597C298.489 214.642 294.942 216.854 291.52 219.233C284.842 223.948 278.666 229.332 273.074 235.258C273.366 235.258 273.658 235.258 273.95 235.258C271.655 233.004 269.36 230.751 267.065 228.455C266.48 227.913 265.604 228.789 266.188 229.374Z"
				fill="#050911"
			/>
			<path
				d="M171.373 126.504C181.639 114.402 193.658 103.927 205.969 93.9531C218.321 83.9792 231.258 74.6312 245.739 67.9958C246.49 67.662 245.823 66.577 245.113 66.9108C230.757 73.4627 217.946 82.7272 205.676 92.576C193.157 102.675 180.929 113.317 170.496 125.586C169.954 126.254 170.83 127.13 171.373 126.504Z"
				fill="#050911"
			/>
			<path
				d="M245.989 73.8387C244.612 73.9222 244.028 71.627 245.572 71.5018C246.115 71.46 246.74 71.7104 246.949 72.2529C247.241 73.0458 246.49 73.8805 245.697 73.8805C245.113 73.8805 245.071 74.8403 245.697 74.8403C246.991 74.8403 248.118 73.6301 247.909 72.3364C247.742 71.2514 246.615 70.5837 245.572 70.6254C244.445 70.7088 243.569 71.627 243.694 72.7537C243.819 73.8805 244.821 74.9238 245.989 74.8403C246.615 74.7568 246.615 73.797 245.989 73.8387Z"
				fill="#050911"
			/>
			<path
				d="M309.715 166.15C309.757 166.15 309.757 166.15 309.798 166.108C309.673 166.108 309.548 166.066 309.423 166.066C309.882 166.275 309.632 165.399 309.59 165.273C309.59 165.148 309.632 164.981 309.673 164.856C309.798 164.606 310.007 164.355 310.258 164.23C310.8 163.98 311.384 164.397 311.551 164.94C311.885 166.025 310.299 166.567 309.882 165.607C309.632 165.065 308.839 165.524 309.089 166.066C309.59 167.235 311.301 167.318 312.135 166.442C313.095 165.399 312.386 163.646 311.092 163.27C309.715 162.895 308.38 164.355 308.713 165.691C308.839 166.233 309.548 166.901 310.091 166.358C310.508 165.941 310.091 165.023 309.465 165.273C308.922 165.482 309.173 166.358 309.715 166.15Z"
				fill="#050911"
			/>
			<path
				opacity="0.15"
				d="M169.704 22.8002C176.297 27.0568 185.228 33.3166 194.993 41.7047C201.044 46.9212 216.736 60.9014 236.35 88.0272C257.633 117.448 268.274 132.138 270.403 148.539C271.488 157.094 272.99 181.465 251.498 211.429C260.387 204.668 269.318 197.866 278.207 191.105C282.088 197.49 285.927 203.875 289.808 210.26C285.635 213.348 281.462 216.395 277.289 219.483C279.333 221.945 281.378 224.366 283.423 226.828C285.927 224.407 288.932 221.82 292.396 219.274C297.737 215.351 302.87 212.555 307.294 210.51C305.625 207.297 303.997 204.042 302.328 200.829C309.506 196.113 316.684 191.356 323.861 186.64C323.486 183.093 322.609 177.96 320.314 172.201C317.643 165.44 314.263 160.933 312.969 159.18C303.621 146.535 308.17 149.582 288.598 122.08C274.534 102.3 266.772 91.2406 254.336 76.9265C246.365 67.7455 241.065 61.6944 232.594 54.2243C223.58 46.2535 212.646 36.7804 195.953 29.8529C185.311 25.5127 175.963 23.6765 169.704 22.8002Z"
				fill="#050911"
			/>
			<path
				opacity="0.11"
				d="M303.705 144.032C303.329 146.703 302.578 150.792 300.7 155.425C296.36 166.15 289.683 170.824 275.744 184.929C269.151 191.606 259.344 201.497 260.262 202.582C261.138 203.625 271.863 195.362 290.601 183.009C301.576 175.748 310.799 170.114 316.85 166.442C312.468 158.972 308.087 151.502 303.705 144.032Z"
				fill="#050911"
			/>
			<path
				opacity="0.08"
				d="M317.727 167.694C317.143 167.819 318.562 172.368 316.851 178.043C315.599 182.216 313.47 184.22 308.254 190.521C306 193.234 302.704 197.323 298.781 202.582C306.96 196.99 315.14 191.397 323.319 185.805C319.605 171.032 318.228 167.61 317.727 167.694Z"
				fill="#050911"
			/>
			<path
				d="M247.244 304.282C247.369 305.493 247.828 306.452 248.538 307.621C251.542 312.462 256.508 315.425 260.097 317.136C258.678 321.601 256.967 328.028 255.715 335.874C254.547 343.427 253.17 352.232 254.964 352.817C255.59 353.025 257.426 352.441 274.495 318.638C276.874 313.923 278.835 310 280.129 307.329C271.031 303.239 261.975 299.108 252.878 295.018C252.001 295.686 246.785 299.483 247.244 304.282Z"
				fill="#050911"
				stroke="#050911"
				strokeWidth="0.834639"
			/>
			<path
				d="M228.214 211.971C220.535 217.313 215.569 222.488 211.146 227.162C209.351 229.04 200.755 238.179 191.699 252.535C187.692 258.878 184.729 264.47 182.685 268.685C182.935 270.396 183.519 272.858 185.063 275.321C186.774 278.075 188.694 279.285 191.699 281.956C195.371 285.211 196.707 287.131 198.835 289.301C202.966 293.557 208.767 296.771 220.202 303.072C234.557 310.96 242.278 313.839 244.657 314.716C247.578 315.759 250.04 316.552 251.793 317.094C252.627 313.213 253.629 309.082 254.881 304.742C256.008 300.86 257.218 297.23 258.428 293.85C249.539 287.339 240.4 280.036 231.135 271.773C227.922 268.936 224.834 266.056 221.871 263.218C224.876 259.588 229.091 254.83 234.432 249.697C235.976 248.195 239.231 245.107 243.196 241.852C248.204 237.804 255.757 232.378 266.19 227.371C267.985 215.393 265.856 204.209 259.304 200.579C251.125 196.072 238.689 204.71 228.214 211.971Z"
				fill="white"
			/>
			<path
				d="M219.785 218.189C205.012 230.709 192.784 246.192 184.104 263.51C183.227 265.305 181.975 267.308 181.809 269.311C181.642 271.439 182.935 273.067 184.104 274.694C186.9 278.492 190.071 282.039 193.452 285.336C200.63 292.222 208.893 297.897 217.573 302.697C228.256 308.623 239.566 313.338 250.833 318.012C251.584 318.304 251.918 317.094 251.167 316.802C232.722 309.123 213.9 301.278 198.501 288.216C195.038 285.253 191.741 282.081 188.778 278.617C187.234 276.781 185.69 274.903 184.354 272.942C183.52 271.732 182.894 270.605 183.102 269.061C183.311 267.558 184.187 266.139 184.855 264.762C188.861 256.625 193.66 248.904 199.169 241.685C205.471 233.38 212.69 225.826 220.661 219.108C221.287 218.565 220.411 217.689 219.785 218.189Z"
				fill="#050911"
			/>
			<path
				d="M255.174 232.587C240.86 241.852 228.341 253.578 218.2 267.266C217.741 267.934 218.826 268.56 219.285 267.892C229.3 254.371 241.695 242.77 255.8 233.63C256.468 233.255 255.842 232.17 255.174 232.587Z"
				fill="#050911"
			/>
			<path
				d="M221.162 263.844C232.889 274.444 244.95 284.627 257.386 294.35C258.012 294.851 258.888 293.975 258.262 293.474C245.826 283.75 233.724 273.568 222.039 262.968C221.455 262.425 220.578 263.302 221.162 263.844Z"
				fill="#050911"
			/>
			<path
				d="M257.428 294.183C254.799 301.612 252.754 309.207 251.252 316.927C251.085 317.72 252.295 318.054 252.462 317.261C253.964 309.541 256.051 301.946 258.638 294.517C258.889 293.766 257.678 293.432 257.428 294.183Z"
				fill="#050911"
			/>
			<path
				opacity="0.11"
				d="M244.365 227.913C238.606 228.414 234.266 232.629 220.87 251.241C215.737 258.377 211.439 264.595 208.142 269.52C212.9 272.858 216.655 275.613 219.034 277.407C232.347 287.381 237.939 292.973 249.248 299.066C251.71 300.401 253.755 301.361 254.965 301.904C255.925 299.275 256.885 296.646 257.803 294.016C254.631 291.262 251.46 288.55 248.246 285.795C239.399 278.283 230.636 271.022 221.914 263.969C225.544 258.878 228.966 255.039 231.47 252.41C238.857 244.689 246.202 239.473 251.919 235.466C253.88 234.089 255.55 233.004 256.635 232.295C251.251 228.163 247.036 227.663 244.365 227.913Z"
				fill="#050911"
			/>
			<path
				opacity="0.08"
				d="M246.035 238.805C242.446 239.64 237.647 241.267 232.764 244.522C220.829 252.493 216.822 264.679 215.654 268.852C222.081 273.86 228.716 278.909 235.602 283.959C242.279 288.883 248.914 293.557 255.383 298.064C256.342 296.687 257.302 295.268 258.22 293.891C249.331 287.423 240.109 279.953 230.886 271.398C227.965 268.727 225.169 266.014 222.498 263.343C223.958 261.424 225.544 259.462 227.214 257.459C233.473 250.031 239.9 243.896 246.035 238.805Z"
				fill="#050911"
			/>
			<path
				d="M325.031 290.637C329.455 294.81 333.92 299.025 338.344 303.198C337.175 329.823 339.387 336.083 341.724 336.542C345.355 337.251 351.573 324.69 354.285 314.799C356.33 307.288 356.539 300.694 356.33 296.229C349.403 290.052 342.475 283.876 335.548 277.658C332.042 281.998 328.537 286.338 325.031 290.637Z"
				fill="#050911"
				stroke="#050911"
				strokeWidth="0.834639"
			/>
			<path
				d="M238.187 215.561C238.562 222.488 243.403 226.703 243.403 226.703C246.408 229.332 249.788 230.042 251.457 230.25C252.793 230.793 254.128 231.377 255.505 231.92C257.592 234.924 260.68 239.181 264.77 244.022C270.195 250.532 277.498 258.294 295.359 272.984C304.165 280.203 315.516 289.092 329.246 298.858C331.207 297.898 333.544 296.479 335.881 294.392C341.014 289.844 343.393 284.544 344.478 281.539C342.058 279.202 338.469 275.696 334.295 271.356C315.766 252.201 303.414 235.801 293.982 223.198C287.764 214.893 279.793 203.834 270.988 190.48C250.831 195.988 237.686 206.672 238.187 215.561Z"
				fill="white"
			/>
			<path
				d="M244.656 228.289C247.994 229.457 251.291 230.584 254.63 231.753C255.381 232.003 255.715 230.793 254.964 230.543C251.625 229.374 248.328 228.247 244.99 227.079C244.239 226.828 243.905 228.039 244.656 228.289Z"
				fill="#050911"
			/>
			<path
				d="M253.253 230.501C268.944 252.076 289.267 269.52 310.342 285.67C316.393 290.303 322.528 294.893 328.662 299.442C329.33 299.943 329.914 298.816 329.288 298.357C308.172 282.707 286.93 266.849 268.986 247.569C263.769 241.977 258.887 236.051 254.38 229.875C253.879 229.249 252.794 229.875 253.253 230.501Z"
				fill="#050911"
			/>
			<path
				d="M277.873 201.414C292.646 224.074 309.297 245.441 327.66 265.305C332.959 271.064 338.426 276.656 343.977 282.123C344.561 282.707 345.437 281.789 344.853 281.247C325.531 262.3 307.837 241.727 291.979 219.817C287.472 213.599 283.132 207.256 278.917 200.788C278.499 200.12 277.414 200.746 277.873 201.414Z"
				fill="#050911"
			/>
			<path
				d="M343.268 281.33C340.847 288.508 336.173 295.185 329.121 298.357C328.369 298.691 329.037 299.776 329.747 299.442C337.091 296.145 341.932 289.134 344.436 281.664C344.728 280.913 343.518 280.579 343.268 281.33Z"
				fill="#050911"
			/>
			<path
				opacity="0.09"
				d="M277.916 200.746C275.537 197.532 273.534 194.903 272.199 193.192C270.863 195.654 269.027 199.786 268.86 204.919C268.484 216.52 276.998 221.487 301.411 248.529C312.053 260.297 317.686 267.392 331.416 281.414C334.63 284.669 337.342 287.381 339.304 289.301C340.806 286.672 342.308 284.043 343.852 281.414C330.623 268.143 320.149 256.166 312.303 246.693C304.749 237.553 297.989 229.374 291.02 219.191C289.225 216.646 284.676 209.843 277.916 200.746Z"
				fill="#050911"
			/>
			<path
				opacity="0.09"
				d="M285.303 212.472C285.887 215.352 287.097 220.235 289.476 225.91C296.362 242.102 306.544 248.946 324.865 269.186C328.37 273.067 333.795 279.202 340.139 287.131C341.599 285.044 343.06 282.999 344.52 280.913C343.185 279.661 341.057 277.7 338.469 275.196C333.712 270.605 320.817 257.793 296.195 226.536C293.524 223.198 289.768 218.357 285.303 212.472Z"
				fill="#050911"
			/>
			<path
				d="M149.841 175.581C149.006 175.915 147.754 176.624 144.04 182.592C140.034 189.019 137.321 193.401 135.318 200.328C133.565 206.504 133.148 212.138 133.273 216.729C133.565 218.064 133.774 220.026 133.106 222.196C132.23 224.95 130.644 225.618 127.639 229.374C126.429 230.876 124.426 233.422 122.84 236.885C121.087 240.766 121.964 241.267 120.294 245.065C118.291 249.614 115.328 252.743 114.828 253.244C112.073 256.124 110.738 256.207 109.528 258.711C108.15 261.549 108.568 264.22 108.693 265.013C108.776 265.597 109.653 270.688 112.616 271.314C113.409 271.481 114.661 271.398 115.161 270.646C115.954 269.478 114.118 267.85 113.784 265.013C113.492 262.425 114.619 260.339 115.328 259.045C117.457 255.08 119.919 255.665 122.172 252.368C124.342 249.113 122.298 248.111 124.384 241.977C125.553 238.555 126.721 235.133 129.851 232.587C131.27 231.418 132.647 230.792 133.941 228.831C135.068 227.12 135.485 225.409 135.652 224.241C137.947 227.913 140.367 230.292 142.12 231.752C144.457 233.672 147.128 235.925 149.382 235.091C150.592 234.632 151.677 233.255 151.468 232.295C151.343 231.752 150.842 231.585 149.548 230.417C149.548 230.417 149.048 229.958 147.921 228.748C147.17 227.955 146.085 226.494 145.125 223.74C145.5 223.322 146.46 222.404 147.754 222.321C149.173 222.279 150.258 223.281 151.343 224.366C153.388 226.369 153.304 227.579 154.598 228.789C155.6 229.749 157.436 230.751 161.067 230.5C160.9 230.041 160.607 229.29 160.232 228.455C158.938 225.659 158.27 225.367 158.187 224.199C158.145 223.281 158.521 223.03 160.232 220.109C161.692 217.605 162.402 216.353 162.444 215.685C162.652 213.223 160.524 212.389 160.065 209.551C159.648 207.172 160.691 205.252 161.442 203.75C165.782 194.945 168.537 189.353 167.744 185.138C166.533 178.544 156.017 172.994 149.841 175.581Z"
				fill="#050911"
			/>
			<path
				d="M147.921 177.083C141.87 183.26 137.989 191.105 135.401 199.285C132.897 207.256 131.019 216.353 134.984 224.157C135.36 224.866 136.445 224.24 136.069 223.531C132.146 215.852 134.274 206.755 136.82 198.951C139.324 191.189 143.038 183.844 148.797 177.96C149.34 177.375 148.463 176.499 147.921 177.083Z"
				fill="#050911"
			/>
			<path
				d="M141.661 213.14C142.162 212.889 142.663 212.681 143.164 212.43C143.539 212.639 143.915 212.889 144.291 213.098C144.75 213.515 145.167 214.058 145.459 214.6C146.043 215.602 146.335 216.812 146.252 217.98C146.085 220.234 144.457 221.987 144.04 224.157C143.79 225.492 144.666 226.452 145.417 227.454C146.711 229.165 148.213 230.75 149.841 232.169C150.425 232.712 151.343 231.836 150.717 231.293C149.674 230.333 148.631 229.373 147.713 228.288C146.21 226.577 144.875 225.117 145.918 222.738C146.836 220.651 147.796 218.69 147.379 216.311C147.045 214.475 146.002 212.597 144.374 211.596C142.997 210.761 141.328 210.928 140.576 212.472C140.201 213.223 141.286 213.849 141.661 213.14Z"
				fill="#050911"
			/>
			<path
				d="M135.068 224.324C137.488 228.205 140.827 231.418 144.875 233.547C146.085 234.173 148.38 235.633 149.841 235.007C151.218 234.423 150.968 232.754 150.592 231.627C150.342 230.876 149.131 231.21 149.382 231.961C150.342 235.007 146.294 232.879 145.501 232.462C144.207 231.794 142.997 231.001 141.828 230.083C139.533 228.288 137.655 226.118 136.111 223.656C135.735 223.03 134.65 223.656 135.068 224.324Z"
				fill="#050911"
			/>
			<path
				d="M163.821 186.014C161.359 194.277 159.815 202.79 159.106 211.387C159.064 211.637 159.314 211.93 159.565 211.971C162.194 212.681 161.818 215.936 160.858 217.772C159.899 219.525 158.396 220.985 157.645 222.863C156.31 226.244 160.274 227.329 160.191 230.292C160.399 230.083 160.608 229.874 160.817 229.666C157.061 229.457 153.889 227.454 152.011 224.199C150.676 221.862 149.132 221.361 146.461 221.528C145.668 221.57 145.668 222.822 146.461 222.78C147.462 222.738 148.673 222.613 149.591 223.114C150.384 223.573 150.634 224.366 151.051 225.075C151.761 226.285 152.679 227.37 153.764 228.247C155.809 229.916 158.188 230.751 160.775 230.918C161.109 230.918 161.401 230.625 161.401 230.292C161.443 228.539 160.274 227.496 159.314 226.16C157.311 223.364 160.608 220.777 161.902 218.398C163.321 215.811 163.279 211.637 159.857 210.761C160.024 210.97 160.149 211.178 160.316 211.345C160.984 202.832 162.569 194.486 164.99 186.306C165.24 185.597 164.03 185.263 163.821 186.014Z"
				fill="#050911"
			/>
			<path
				d="M132.856 217.104C133.148 219.608 132.814 222.07 131.604 224.324C130.477 226.452 128.641 227.996 126.846 229.624C123.675 232.587 122.214 235.842 121.213 240.015C120.128 244.438 118.834 247.986 115.62 251.324C113.2 253.786 110.362 256.082 108.86 259.295C107.399 262.425 107.608 266.056 109.486 268.977C110.571 270.688 114.285 274.11 115.704 270.855C116.288 269.519 115.62 267.85 115.161 266.556C114.744 265.43 114.202 264.303 114.202 263.051C114.202 261.298 115.203 259.796 116.372 258.586C119.209 255.623 122.84 253.953 124.092 249.738C124.802 247.36 124.301 244.981 124.468 242.561C124.676 239.556 126.095 237.01 128.265 234.965C131.562 231.835 135.944 229.248 135.944 224.157C135.944 223.364 134.692 223.364 134.692 224.157C134.65 229.039 129.893 231.46 126.888 234.548C124.551 236.927 123.216 239.931 123.174 243.27C123.132 245.482 123.508 247.568 122.757 249.697C121.922 252.117 120.169 253.745 118.25 255.33C115.787 257.292 112.95 259.587 112.95 263.051C112.95 264.72 113.784 266.223 114.285 267.808C114.41 268.226 114.869 269.352 114.661 269.686C114.243 269.728 113.784 269.811 113.367 269.853C112.616 270.312 112.032 270.229 111.614 269.603C110.696 269.019 109.903 267.266 109.569 266.264C108.234 262.341 110.07 258.836 112.699 255.998C114.911 253.578 117.498 251.533 119.335 248.779C121.296 245.857 121.88 242.394 122.715 239.055C123.341 236.635 124.176 234.298 125.845 232.378C127.305 230.667 129.142 229.373 130.644 227.704C133.315 224.783 134.525 220.985 134.066 217.104C134.024 216.311 132.772 216.311 132.856 217.104Z"
				fill="#050911"
			/>
			<path
				d="M105.773 166.275C104.897 166.776 103.269 167.652 102.935 169.488C102.518 171.533 103.979 173.202 104.187 173.411C103.937 173.703 103.186 174.58 103.102 175.915C103.019 178.127 104.98 179.42 105.064 179.504C104.98 179.587 103.77 180.839 104.187 182.55C104.521 184.011 105.815 184.637 105.982 184.679C105.898 184.929 105.606 186.014 106.149 187.016C106.983 188.518 108.987 188.435 109.195 188.435C110.614 188.351 111.95 187.391 112.576 185.931C113.368 186.39 115.455 187.475 118.293 187.183C120.212 186.974 121.631 186.223 122.383 185.764C123.885 188.393 126.222 191.94 129.686 195.571C132.732 198.742 138.408 204.71 146.837 207.172C157.354 210.26 166.66 206.254 172.002 203.959C186.358 197.783 193.494 186.431 196.123 182.175C200.546 174.913 202.091 168.695 203.426 163.062C205.596 154.131 204.845 150.625 204.135 148.581C203.718 147.329 201.256 140.151 195.58 139.316C190.948 138.607 187.109 142.571 186.65 143.072C185.064 144.741 184.229 146.953 182.56 151.293C181.725 153.505 181.183 155.174 180.932 155.925C178.22 164.731 176.843 169.113 176.467 170.031C175.466 172.535 173.087 178.252 167.37 182.717C161.903 186.974 156.185 187.892 153.64 188.268C149.8 188.81 145.085 189.478 140.077 187.016C134.485 184.261 131.981 179.254 131.313 177.918C129.31 173.787 128.934 169.196 128.642 165.941C128.267 161.768 128.559 158.513 126.138 155.925C125.679 155.466 124.594 154.298 123.468 154.506C121.84 154.84 120.922 157.97 120.087 160.933C119.378 163.437 119.586 163.729 119.002 164.313C117.792 165.524 116.248 164.898 110.948 165.19C107.86 165.399 106.775 165.732 105.773 166.275Z"
				fill="white"
			/>
			<path
				d="M114.411 164.48C109.904 163.646 101.975 164.814 102.601 170.99C103.143 176.541 109.737 177.501 113.702 175.08C114.369 174.663 113.743 173.578 113.076 173.995C109.779 175.998 103.811 175.456 103.811 170.448C103.811 165.607 110.613 165.065 114.077 165.69C114.87 165.857 115.204 164.647 114.411 164.48Z"
				fill="#050911"
			/>
			<path
				d="M103.686 173.035C99.1787 178.544 110.029 185.472 113.242 178.544C113.576 177.835 112.491 177.167 112.157 177.918C109.653 183.26 101.307 177.918 104.604 173.912C105.105 173.328 104.228 172.409 103.686 173.035Z"
				fill="#050911"
			/>
			<path
				d="M103.936 178.627C102.976 180.797 103.644 183.552 105.856 184.679C108.026 185.805 110.738 184.762 111.865 182.675C112.241 181.966 111.156 181.34 110.78 182.049C109.945 183.51 108.067 184.345 106.482 183.593C104.896 182.842 104.312 180.797 105.021 179.253C105.355 178.544 104.27 177.918 103.936 178.627Z"
				fill="#050911"
			/>
			<path
				d="M104.937 184.22C104.77 186.431 106.064 188.435 108.318 188.894C110.404 189.311 112.324 187.934 113.033 186.056C113.325 185.305 112.115 184.971 111.823 185.722C111.281 187.141 109.778 188.059 108.276 187.6C106.732 187.141 106.106 185.722 106.189 184.22C106.231 183.427 104.979 183.427 104.937 184.22Z"
				fill="#050911"
			/>
			<path
				d="M108.903 170.573C109.988 170.448 111.115 170.49 112.033 171.116C112.909 171.7 113.41 172.869 112.909 173.828C112.533 174.538 113.618 175.164 113.994 174.454C114.662 173.161 114.37 171.658 113.368 170.615C112.241 169.405 110.447 169.154 108.861 169.321C108.11 169.447 108.11 170.699 108.903 170.573Z"
				fill="#050911"
			/>
			<path
				d="M112.783 186.89C114.953 186.097 117.04 187.892 119.294 187.641C121.005 187.433 122.507 186.515 123.926 185.68C124.594 185.263 123.968 184.178 123.3 184.595C121.171 185.889 119.335 186.807 116.79 186.056C115.246 185.597 114.035 185.096 112.45 185.68C111.698 185.972 111.99 187.182 112.783 186.89Z"
				fill="#050911"
			/>
			<path
				d="M114.077 165.565C116.247 166.317 119.168 166.442 120.379 164.063C121.046 162.728 120.629 161.1 120.921 159.681C121.255 157.762 122.34 156.176 123.842 154.966C124.468 154.465 123.592 153.588 122.966 154.089C121.339 155.425 120.17 157.261 119.753 159.347C119.544 160.516 119.836 161.893 119.502 163.02C118.751 165.273 116.247 164.981 114.494 164.355C113.66 164.105 113.326 165.315 114.077 165.565Z"
				fill="#050911"
			/>
			<path
				d="M122.924 155.049C126.472 155.508 126.931 161.476 127.515 164.188C128.224 167.735 128.6 171.324 129.268 174.872C130.353 180.547 133.65 185.054 138.908 187.6C144.416 190.229 150.676 190.104 156.435 188.518C162.361 186.849 168.496 183.844 172.335 178.878C172.836 178.252 171.959 177.375 171.459 178.001C167.452 183.176 160.942 186.223 154.682 187.683C148.381 189.144 141.495 188.601 136.237 184.512C130.561 180.13 130.436 173.536 129.309 166.984C128.767 163.896 128.308 160.557 127.181 157.636C126.472 155.8 125.345 154.131 123.3 153.839C122.465 153.713 122.131 154.924 122.924 155.049Z"
				fill="#050911"
			/>
			<path
				d="M122.006 186.515C127.056 192.691 131.939 199.744 138.616 204.293C145.168 208.716 153.264 209.175 160.859 207.923C176.3 205.336 189.404 194.861 196.915 181.34C201.214 173.661 203.759 165.148 204.886 156.426C204.97 155.633 203.718 155.633 203.634 156.426C201.714 171.324 195.204 185.931 183.603 195.779C177.886 200.62 171.083 204.251 163.78 206.004C156.227 207.84 147.505 208.09 140.619 204.042C133.274 199.744 128.141 192.065 122.883 185.597C122.382 185.012 121.506 185.889 122.006 186.515Z"
				fill="#050911"
			/>
			<path
				d="M196.164 139.149C192.7 137.772 189.028 139.566 186.607 142.112C183.77 145.033 182.351 148.914 181.307 152.754C180.097 157.219 179.012 161.643 177.301 165.941C175.465 170.573 173.128 174.955 170.457 179.128C169.998 179.796 171.083 180.422 171.542 179.754C175.841 173.119 179.137 165.899 181.182 158.262C182.267 154.256 182.977 150.083 184.98 146.452C186.983 142.78 191.24 138.523 195.83 140.359C196.581 140.651 196.915 139.441 196.164 139.149Z"
				fill="#050911"
			/>
			<path
				d="M110.781 183.552C111.949 183.635 111.699 185.597 111.49 186.306C111.24 187.057 112.45 187.391 112.7 186.64C113.243 185.054 112.867 182.467 110.781 182.3C109.988 182.258 109.988 183.51 110.781 183.552Z"
				fill="#050911"
			/>
			<path
				d="M111.49 179.712C111.741 180.714 111.365 181.757 110.572 182.342C109.904 182.801 110.53 183.886 111.198 183.427C112.492 182.509 113.118 180.923 112.7 179.379C112.533 178.586 111.323 178.92 111.49 179.712Z"
				fill="#050911"
			/>
			<path
				d="M112.199 175.372C113.326 175.915 112.575 177.709 112.032 178.419C111.532 179.045 112.617 179.671 113.117 179.045C114.077 177.793 114.578 175.122 112.825 174.287C112.116 173.953 111.49 175.038 112.199 175.372Z"
				fill="#050911"
			/>
			<path
				opacity="0.11"
				d="M103.602 175.539C104.27 175.164 105.355 177.501 108.151 178.669C109.194 179.086 110.488 179.379 116.414 178.669C118.334 178.419 120.838 178.085 123.842 177.542C124.426 179.921 125.637 183.51 128.099 187.224C129.142 188.768 133.357 194.778 141.495 198.325C150.217 202.164 158.104 200.871 161.151 200.328C163.738 199.869 170.958 198.492 177.384 192.9C181.683 189.186 184.437 184.679 188.485 172.952C191.239 164.981 194.578 153.922 197.04 140.192C198.709 141.903 201.297 144.991 202.757 149.582C203.467 151.794 204.134 155.049 203.592 160.14C202.465 171.116 196.957 180.255 193.618 184.929C190.154 189.812 183.978 198.241 172.543 203.458C170.999 204.167 162.277 207.965 151.177 207.464C148.631 207.339 146.294 207.214 143.415 206.17C140.285 205.044 137.155 202.122 130.937 196.363C128.349 193.985 125.804 191.481 123.216 187.85C122.715 187.141 122.298 186.515 122.006 186.097C121.13 186.682 119.836 187.349 118.375 187.182C116.414 187.016 115.746 185.597 114.119 185.847C112.7 186.056 112.7 187.224 110.989 187.85C109.653 188.309 107.775 188.267 106.523 187.308C105.605 186.598 105.48 185.805 104.437 181.298C103.435 176.875 103.143 175.79 103.602 175.539Z"
				fill="#050911"
			/>
			<path
				d="M151.302 164.689C150.509 164.856 150.217 166.442 149.716 168.904C149.424 170.407 149.299 171.7 149.257 172.618C147.588 174.58 147.421 176.374 147.379 177.084C147.337 178.544 147.838 179.671 148.798 181.758C149.465 183.26 150.175 184.428 150.676 185.221C148.881 185.639 147.629 186.807 147.546 188.059C147.462 189.269 148.547 190.229 148.965 190.563C150.843 192.191 152.22 190.98 154.599 192.274C156.518 193.317 156.351 194.486 159.147 196.03C160.9 196.99 161.443 196.781 161.651 196.656C162.361 196.197 162.11 194.695 161.985 193.693C161.818 192.483 161.317 190.647 159.94 188.518C161.568 187.934 163.195 187.35 164.781 186.807C163.613 186.932 163.362 186.974 163.362 186.974C163.362 186.932 166.242 185.764 168.704 186.807C170.749 187.684 170.916 189.52 172.627 189.645C173.587 189.728 174.672 189.186 175.131 188.393C176.09 186.765 174.296 184.387 173.879 183.844C172.168 181.591 169.664 180.923 168.537 180.714C168.913 179.88 169.33 178.544 169.413 176.875C169.497 174.037 168.328 171.951 167.786 171.074C167.035 168.612 166.325 166.15 165.574 163.646C164.406 166.359 163.112 167.193 162.11 167.402C160.983 167.652 160.358 167.11 158.521 167.402C156.769 167.694 156.685 168.236 155.684 168.195C153.347 167.944 152.554 164.439 151.302 164.689Z"
				fill="#050911"
			/>
			<path
				d="M152.346 177.877C153.055 178.711 154.182 179.254 155.309 178.962C156.31 178.711 156.978 177.877 157.103 176.875C157.145 176.458 156.519 176.458 156.477 176.875C156.394 177.751 155.643 178.377 154.766 178.419C153.973 178.461 153.306 178.002 152.805 177.418C152.513 177.126 152.095 177.585 152.346 177.877Z"
				fill="white"
			/>
			<path
				d="M161.276 176.499C162.32 178.002 165.7 178.127 165.575 175.748C165.533 175.331 164.907 175.331 164.949 175.748C165.074 177.459 162.528 177.209 161.819 176.166C161.569 175.832 161.026 176.166 161.276 176.499Z"
				fill="white"
			/>
			<path
				d="M159.649 181.132C159.649 181.174 159.691 181.215 159.691 181.257C159.816 181.299 159.983 181.341 160.108 181.382C160.024 181.215 159.899 181.132 159.691 181.132C159.857 181.09 159.983 181.007 160.15 180.965C160.108 180.923 160.066 180.84 160.024 180.798C159.816 180.881 159.649 180.965 159.44 181.048C159.732 181.507 159.607 182.342 159.44 182.801C159.231 183.427 158.355 184.262 157.813 183.427C157.604 183.093 157.061 183.385 157.27 183.761C157.813 184.596 158.939 184.554 159.565 183.844C160.191 183.135 160.525 181.591 159.983 180.756C159.816 180.464 159.315 180.631 159.398 181.007C159.482 181.257 159.607 181.424 159.816 181.549C159.983 181.674 160.233 181.549 160.275 181.382C160.358 181.007 160.233 180.13 159.649 180.381C159.106 180.589 159.315 181.424 159.565 181.758C159.649 181.883 159.857 181.967 159.983 181.883C160.442 181.549 160.442 180.923 159.941 180.673C159.607 180.422 159.315 180.965 159.649 181.132Z"
				fill="white"
			/>
			<path
				d="M159.69 181.257C159.857 182.217 160.191 183.385 161.317 183.552C162.444 183.719 162.778 182.634 162.778 181.758C162.778 181.34 162.152 181.34 162.152 181.758C162.152 182.384 161.943 183.26 161.109 182.843C160.483 182.551 160.399 181.716 160.316 181.09C160.232 180.714 159.606 180.881 159.69 181.257Z"
				fill="white"
			/>
			<path
				d="M148.172 178.085C148.965 173.745 152.095 170.031 156.352 168.779C160.609 167.527 165.533 169.238 167.494 173.328C167.828 174.037 168.913 173.411 168.579 172.702C166.451 168.32 161.235 166.359 156.602 167.444C151.678 168.612 147.88 172.827 146.962 177.752C146.837 178.544 148.047 178.878 148.172 178.085Z"
				fill="#050911"
			/>
			<path
				d="M150.384 171.784C150.342 169.697 150.718 167.611 151.594 165.733C151.678 165.566 151.761 165.441 151.803 165.274C152.095 164.773 152.137 164.689 151.886 165.023C151.636 165.232 151.678 165.399 152.053 165.649C152.137 165.774 152.262 165.941 152.345 166.067C152.971 167.068 153.514 168.111 153.973 169.196C154.265 169.948 155.475 169.614 155.183 168.863C154.766 167.861 152.846 162.77 151.218 163.896C150.426 164.439 149.925 166.275 149.674 167.152C149.215 168.654 149.048 170.24 149.09 171.826C149.174 172.577 150.426 172.577 150.384 171.784Z"
				fill="#050911"
			/>
			<path
				d="M163.112 168.404C163.529 167.694 163.947 166.985 164.406 166.275C164.614 165.983 164.906 165.691 165.032 165.357C165.324 165.19 165.366 165.023 165.199 164.815C165.032 164.522 165.073 164.606 165.282 165.065C165.992 166.859 166.492 168.612 166.701 170.574C166.784 171.367 168.036 171.367 167.953 170.574C167.828 169.196 167.577 167.861 167.202 166.526C166.951 165.774 166.576 164.063 165.866 163.604C165.324 163.271 164.865 163.521 164.489 163.98C163.529 165.107 162.778 166.484 162.027 167.778C161.61 168.445 162.695 169.071 163.112 168.404Z"
				fill="#050911"
			/>
			<path
				d="M147.045 177.418C146.753 184.429 153.096 190.438 160.149 188.978C163.404 188.31 166.409 186.181 167.953 183.219C169.581 180.089 170.499 175.123 168.37 172.034C167.911 171.367 166.826 171.993 167.285 172.66C169.163 175.456 168.245 180.047 166.743 182.843C165.366 185.389 162.653 187.183 159.815 187.767C153.514 189.019 148.047 183.594 148.297 177.418C148.297 176.583 147.045 176.583 147.045 177.418Z"
				fill="#050911"
			/>
			<path
				d="M147.922 188.435C148.923 184.721 153.931 184.929 156.56 186.557C159.356 188.226 160.775 191.481 161.067 194.611C161.151 195.488 161.485 196.364 160.024 195.821C159.481 195.613 158.772 194.82 158.355 194.403C157.603 193.651 156.936 192.858 156.101 192.233C155.183 191.523 154.223 191.106 153.096 190.897C151.344 190.563 148.172 190.772 148.005 188.268C147.963 187.475 146.711 187.475 146.753 188.268C146.878 189.896 147.922 190.981 149.424 191.523C150.593 191.94 151.886 191.857 153.096 192.149C154.599 192.525 155.6 193.318 156.644 194.403C157.687 195.446 159.481 197.783 161.276 197.449C162.778 197.157 162.444 195.446 162.319 194.319C161.86 190.313 159.732 186.348 155.809 184.804C152.512 183.511 147.755 184.262 146.753 188.143C146.503 188.894 147.713 189.228 147.922 188.435Z"
				fill="#050911"
			/>
			<path
				d="M164.573 183.01C166.284 180.715 170.165 181.633 171.959 183.177C173.003 184.053 173.712 185.263 174.046 186.599C174.13 187.016 174.338 187.892 174.213 188.351C173.796 189.562 172.919 189.186 172.168 188.602C171.125 187.809 170.332 186.682 169.038 186.181C168.412 185.931 167.786 185.889 167.119 185.931C166.493 185.931 165.908 186.098 165.282 186.014C164.114 185.848 162.486 184.763 164.448 183.26C165.074 182.759 164.197 181.883 163.571 182.384C162.612 183.135 162.111 184.262 162.528 185.472C163.112 187.058 164.615 187.392 166.159 187.308C167.703 187.225 168.704 187.266 169.956 188.31C170.541 188.811 171.041 189.395 171.709 189.854C172.585 190.48 173.879 191.064 174.839 190.188C177.051 188.143 174.505 183.594 172.752 182.175C170.248 180.13 165.658 179.546 163.571 182.426C163.029 183.01 164.114 183.636 164.573 183.01Z"
				fill="#050911"
			/>
			<path
				d="M150.342 184.971C153.054 184.095 157.186 185.806 158.855 188.017C158.98 188.184 159.23 188.017 159.105 187.851C157.353 185.472 153.179 183.719 150.258 184.679C150.049 184.721 150.133 185.013 150.342 184.971Z"
				fill="white"
			/>
			<path
				d="M164.864 183.177C166.075 181.758 167.827 181.132 169.664 181.507C169.872 181.549 169.956 181.257 169.747 181.215C167.827 180.84 165.908 181.466 164.656 182.968C164.531 183.135 164.739 183.344 164.864 183.177Z"
				fill="white"
			/>
			<path
				d="M151.886 165.399C152.303 166.484 152.596 167.569 152.721 168.696C152.804 168.654 152.888 168.612 152.971 168.571C152.762 168.362 152.554 168.237 152.303 168.112C152.178 168.07 152.053 168.112 152.053 168.237C152.053 168.612 152.011 168.946 152.011 169.322C152.095 169.28 152.178 169.238 152.262 169.197C151.677 168.529 151.218 170.031 151.051 170.323C150.968 170.49 151.218 170.657 151.302 170.49C151.344 170.407 151.886 169.238 152.011 169.405C152.095 169.53 152.262 169.405 152.262 169.28C152.262 168.904 152.303 168.571 152.303 168.195C152.22 168.237 152.136 168.278 152.053 168.32C152.303 168.404 152.47 168.529 152.637 168.738C152.721 168.821 152.929 168.779 152.888 168.612C152.721 167.444 152.47 166.317 152.011 165.232C152.095 165.149 151.803 165.232 151.886 165.399Z"
				fill="white"
			/>
			<path
				d="M164.782 164.856C164.323 165.733 164.072 166.609 163.989 167.611C163.989 167.694 164.114 167.778 164.197 167.778C164.782 167.611 164.865 168.07 164.907 168.571C164.907 168.696 165.074 168.779 165.157 168.696C165.366 169.03 165.533 169.322 165.742 169.656C165.7 169.864 166.034 169.948 166.034 169.739C166.117 169.155 165.742 167.653 164.949 168.487C165.032 168.529 165.116 168.571 165.199 168.612C165.157 168.237 165.074 167.903 164.99 167.527C164.99 167.444 164.907 167.402 164.823 167.402C164.531 167.402 164.323 167.444 164.072 167.527C164.156 167.569 164.197 167.611 164.281 167.694C164.323 166.776 164.573 165.942 165.032 165.107C165.157 164.815 164.865 164.648 164.782 164.856Z"
				fill="white"
			/>
			<path
				d="M190.948 140.108C191.532 144.115 192.659 150.458 194.996 157.97C199.92 173.661 205.721 180.38 213.4 198.575C215.319 203.124 218.032 209.968 220.703 218.606C225.669 220.234 234.891 222.446 246.159 220.484C264.688 217.271 275.58 205.002 278.627 201.288C272.283 189.603 265.898 176.958 259.68 163.395C253.504 149.957 248.121 137.02 243.447 124.709C239.524 123.249 229.258 119.994 216.655 123.082C201.589 126.713 193.285 137.02 190.948 140.108Z"
				fill="#FF692A"
			/>
			<path
				opacity="0.13"
				d="M227.588 121.663C229.174 125.002 231.177 129.55 233.305 135.059C239.982 152.586 242.403 166.984 243.279 172.117C245.157 182.717 246.785 199.159 244.114 220.276C249.914 220.109 254.213 218.773 256.925 217.73C264.688 214.642 270.321 209.258 275.747 204.042C276.999 202.873 277.958 201.83 278.584 201.204C276.79 198.116 274.077 193.275 270.906 187.224C261.725 169.78 255.715 155.299 250.666 143.03C248.871 138.731 246.409 132.555 243.529 125.085C241.234 124.125 238.355 123.165 234.974 122.539C232.22 121.997 229.716 121.747 227.588 121.663Z"
				fill="#050911"
			/>
			<path
				opacity="0.1"
				d="M255.215 154.757C255.925 162.227 256.509 168.486 256.926 172.994C258.512 190.646 258.804 195.529 258.929 198.659C259.263 206.045 259.263 212.096 259.222 216.061C261.809 215.143 265.148 213.64 268.611 211.22C272.951 208.174 275.873 204.835 277.751 202.373C270.239 186.473 262.727 170.615 255.215 154.757Z"
				fill="#050911"
			/>
			<path
				d="M225.582 114.318C223.579 114.903 222.494 117.782 221.826 120.745C220.491 126.796 221.2 131.971 221.451 133.766C222.661 141.736 224.831 147.704 225.54 149.665C227.377 154.506 229.881 160.975 235.347 167.861C238.477 171.783 241.148 175.164 246.073 177.668C248.952 179.17 254.92 182.216 261.054 179.796C261.346 179.671 267.356 177.209 268.9 171.95C269.234 170.782 269.317 169.613 269.442 167.318C269.526 165.941 269.568 163.52 269.276 160.516C268.858 156.551 267.982 153.463 267.314 151.251C264.351 141.444 262.891 136.562 259.802 132.305C257.257 128.799 254.377 124.793 249.62 124.251C249.202 124.209 244.403 123.75 241.566 127.088C239.604 129.425 239.729 132.305 239.771 134.225C239.896 137.897 241.315 140.526 243.527 144.574C245.739 148.58 246.615 149.081 249.244 153.839C252.833 160.349 252.458 160.975 252.291 161.142C251.581 161.768 248.743 159.806 248.117 159.347C247.533 158.93 246.699 158.346 245.655 157.219C242.4 153.588 240.481 148.121 240.481 148.121C238.77 144.741 236.516 139.942 234.221 133.849C234.93 131.136 236.015 129.217 236.892 127.965C238.31 125.962 239.896 124.585 240.272 122.081C240.439 121.121 240.355 120.286 240.272 119.744C240.981 119.076 241.19 118.074 240.814 117.24C240.355 116.28 239.229 115.821 238.144 116.155C238.144 116.029 238.018 114.944 237.058 114.36C236.266 113.859 235.181 113.943 234.388 114.527C233.136 113.567 232.092 113.317 231.341 113.275C230.465 113.233 230.048 113.442 227.961 113.818C226.208 114.277 226.083 114.193 225.582 114.318Z"
				fill="white"
			/>
			<path
				d="M236.057 131.929C237.184 129.133 238.603 126.588 240.147 124C240.94 122.665 241.858 120.996 240.773 119.535C238.311 116.363 234.972 120.411 233.929 122.498C233.553 123.207 234.638 123.833 235.014 123.124C235.64 121.872 236.349 120.328 237.81 119.911C238.227 119.702 238.645 119.702 239.104 119.827C239.229 120.787 239.354 121.747 239.521 122.707C239.396 123.249 238.645 124.125 238.353 124.626C237.017 126.838 235.932 129.175 234.931 131.554C234.555 132.347 235.765 132.639 236.057 131.929Z"
				fill="#050911"
			/>
			<path
				d="M222.412 137.313C221.786 132.848 221.702 128.299 222.203 123.833C222.662 119.744 223.538 113.526 229.214 114.694C230.007 114.861 230.341 113.651 229.548 113.484C225.082 112.566 222.286 115.737 221.535 119.785C220.408 125.67 220.367 131.679 221.201 137.647C221.327 138.44 222.537 138.106 222.412 137.313Z"
				fill="#050911"
			/>
			<path
				d="M227.627 118.075C228.086 116.989 228.587 115.863 229.464 115.028C230.966 113.567 232.886 114.36 234.513 115.153C235.223 115.529 235.849 114.444 235.139 114.068C233.428 113.192 231.508 112.357 229.672 113.317C228.086 114.152 227.252 115.863 226.584 117.407C226.25 118.2 227.335 118.826 227.627 118.075Z"
				fill="#050911"
			/>
			<path
				d="M231.343 118.45C232.887 116.447 234.932 114.36 237.603 115.946C238.312 116.363 238.938 115.278 238.229 114.861C235.057 112.983 232.386 115.028 230.466 117.532C229.966 118.2 230.842 119.076 231.343 118.45Z"
				fill="#050911"
			/>
			<path
				d="M234.138 119.368C234.931 118.534 235.765 117.657 236.85 117.24C238.144 116.697 240.523 117.031 240.022 118.993C239.855 119.786 241.024 120.119 241.232 119.326C241.65 117.615 240.523 116.03 238.812 115.738C236.517 115.32 234.722 116.99 233.262 118.492C232.719 119.076 233.595 119.952 234.138 119.368Z"
				fill="#050911"
			/>
			<path
				d="M234.639 131.304C233.178 134.433 234.973 137.605 236.225 140.485C237.977 144.533 239.814 148.581 242.067 152.336C244.988 157.261 251.332 166.108 257.967 161.058C258.593 160.558 257.967 159.473 257.341 159.973C250.956 164.856 245.03 155.007 242.443 150.459C240.94 147.788 239.605 145.033 238.353 142.237C237.101 139.441 234.305 135.059 235.766 131.93C236.058 131.22 234.973 130.552 234.639 131.304Z"
				fill="#050911"
			/>
			<path
				d="M240.022 128.132C236.35 140.61 247.617 150.291 251.749 160.975C252.041 161.726 253.251 161.392 252.959 160.641C248.953 150.291 237.685 140.526 241.232 128.466C241.441 127.673 240.231 127.339 240.022 128.132Z"
				fill="#050911"
			/>
			<path
				d="M221.159 136.979C223.287 148.664 228.253 159.723 235.557 169.112C239.104 173.62 243.152 178.001 248.744 179.838C253.501 181.382 260.053 182.175 264.477 179.295C269.318 176.207 269.944 169.905 270.152 164.689C270.194 163.896 268.942 163.896 268.9 164.689C268.65 171.408 267.148 178.836 259.177 179.796C251.832 180.672 244.988 177.751 240.022 172.451C230.799 162.644 224.748 149.832 222.327 136.645C222.244 135.852 221.034 136.186 221.159 136.979Z"
				fill="#050911"
			/>
			<path
				d="M240.857 129.801C242.192 124.459 248.619 124.084 252.625 126.421C256.715 128.8 259.511 133.098 261.556 137.229C263.684 141.57 265.228 146.244 266.73 150.834C268.441 156.092 269.735 161.601 268.859 167.151C268.734 167.944 269.944 168.278 270.069 167.485C271.071 161.142 269.485 155.007 267.44 148.998C265.562 143.531 263.726 137.897 260.596 132.973C257.967 128.8 254.044 124.459 248.869 123.75C244.863 123.208 240.606 125.378 239.605 129.467C239.438 130.26 240.648 130.594 240.857 129.801Z"
				fill="#050911"
			/>
			<path
				opacity="0.1"
				d="M227.127 113.776C230.466 112.441 233.721 113.901 234.514 114.277C232.177 117.365 227.211 124.835 226.793 135.393C226.167 150.417 235.14 160.391 238.311 163.938C242.401 168.487 247.743 174.413 254.628 173.578C258.51 173.119 262.182 170.532 264.06 167.235C265.938 163.938 265.813 160.349 263.559 151.085C260.095 137.063 256.298 128.841 256.84 128.591C257.383 128.341 261.89 135.393 265.27 144.241C265.604 145.159 268.4 152.587 269.402 162.811C270.028 169.113 269.485 171.7 268.191 173.995C266.188 177.543 262.933 179.128 261.681 179.671C256.632 181.799 251.791 180.297 249.495 179.504C241.274 176.75 236.892 170.073 232.469 163.354C225.666 152.963 223.789 144.074 221.994 135.519C220.742 129.593 219.907 122.707 223.037 117.657C223.58 116.614 224.79 114.736 227.127 113.776Z"
				fill="#050911"
			/>
			<path
				d="M166.868 93.4522C166.743 95.4136 167.452 97.6254 168.955 102.049C170.624 107.015 172.418 112.315 176.926 117.532C179.805 120.87 183.31 124.96 189.278 126.128C193.994 127.046 197.958 125.711 199.962 124.876C200.421 126.128 201.047 127.464 201.839 128.841C203.926 132.513 206.513 135.101 208.558 136.812C210.102 136.645 213.107 136.019 215.694 133.682C217.698 131.846 218.657 129.676 219.242 128.257C219.951 126.587 220.327 125.127 220.494 124.083C219.242 123.332 217.865 122.331 216.529 120.954C212.356 116.697 211.229 111.647 210.895 109.227C211.438 104.428 212.022 99.5868 212.565 94.7876C213.191 94.8711 214.526 94.9546 215.694 94.1616C216.988 93.2853 217.239 91.8247 217.572 90.1971C217.906 88.5696 218.282 86.483 217.364 84.1042C216.946 83.0609 215.611 79.7224 212.773 79.5137C210.854 79.3468 208.892 80.6405 207.765 82.6436C205.345 80.3066 200.17 76.1752 192.909 75.5075C180.348 74.339 167.578 84.146 166.868 93.4522Z"
				fill="white"
			/>
			<path
				d="M185.397 112.524C185.063 113.4 186.022 114.652 186.732 115.278C188.693 116.989 192.157 116.906 194.661 115.737C198.166 114.11 199.293 110.604 199.794 109.018C200.378 107.266 201.129 104.97 199.919 102.925C198.876 101.131 197.04 100.505 196.33 100.296C195.621 101.757 195.078 102.842 194.661 103.551C194.16 104.428 193.993 104.72 193.701 105.388C193.284 106.264 193.242 106.598 192.658 107.391C190.279 110.479 187.024 111.606 187.024 111.606C186.273 111.773 185.605 111.94 185.397 112.524Z"
				fill="#050911"
			/>
			<path
				d="M167.535 98.4601C170.831 108.768 174.963 120.787 185.605 125.628C189.945 127.589 195.119 127.965 199.543 126.045C204.509 123.917 207.43 118.992 209.434 114.235C212.063 108.017 213.189 101.256 212.772 94.5373C212.73 93.7444 211.478 93.7444 211.52 94.5373C211.937 101.548 210.685 108.601 207.722 114.986C204.843 121.204 200.294 126.17 192.991 126.087C178.301 125.92 172.334 109.728 168.661 98.168C168.494 97.3333 167.326 97.6672 167.535 98.4601Z"
				fill="#050911"
			/>
			<path
				d="M208.224 83.8121C208.266 81.3082 210.853 79.8059 213.148 80.5153C215.277 81.2248 216.612 83.3114 217.071 85.398C217.489 87.4011 217.322 89.7381 216.696 91.6578C216.195 93.2436 215.569 94.0782 213.649 94.0782C212.982 94.0782 212.314 93.8278 211.604 93.8278C210.812 93.8278 210.812 95.0798 211.604 95.0798C213.023 95.0798 214.4 95.7475 215.778 95.038C216.779 94.5373 217.363 93.5357 217.781 92.4924C218.866 89.6546 218.991 85.8153 217.614 83.0192C216.487 80.724 214.067 78.7626 211.396 79.0547C208.934 79.3051 207.014 81.3082 206.972 83.7704C206.972 84.6051 208.224 84.6051 208.224 83.8121Z"
				fill="#050911"
			/>
			<path
				d="M210.687 106.682C210.562 113.567 213.609 120.161 219.034 124.459C219.66 124.96 220.536 124.084 219.91 123.583C214.735 119.493 211.814 113.317 211.939 106.682C211.939 105.889 210.687 105.889 210.687 106.682Z"
				fill="#050911"
			/>
			<path
				d="M199.92 125.377C201.506 129.759 204.093 133.598 207.557 136.687C208.141 137.229 209.059 136.353 208.433 135.81C205.094 132.847 202.632 129.217 201.088 125.043C200.838 124.292 199.628 124.626 199.92 125.377Z"
				fill="#050911"
			/>
			<path
				d="M207.849 137.104C211.438 138.398 215.027 135.351 217.239 132.806C219.576 130.135 221.62 126.671 220.619 123.04C220.41 122.247 219.2 122.581 219.409 123.374C220.243 126.546 218.365 129.634 216.32 131.929C214.401 134.099 211.271 137.02 208.141 135.894C207.432 135.602 207.098 136.812 207.849 137.104Z"
				fill="#050911"
			/>
			<path
				d="M172.21 94.0783C171.668 92.5342 172.92 90.865 173.838 89.7799C175.215 88.0689 177.343 87.6099 178.929 89.3626C180.766 91.3658 181.266 94.9547 181.517 97.5421C181.684 99.3366 181.058 101.632 182.101 103.218C183.228 104.929 185.314 105.93 187.276 106.306C188.069 106.473 188.402 105.262 187.61 105.096C186.566 104.887 185.648 104.553 184.73 103.969C183.228 102.967 182.894 101.882 182.852 100.088C182.81 98.5436 182.769 97.0413 182.518 95.4972C182.101 92.9516 181.391 89.6965 179.305 87.9855C177.552 86.5248 175.382 86.5666 173.671 88.0272C172.044 89.4461 170.166 92.1587 170.958 94.3705C171.292 95.1634 172.503 94.8295 172.21 94.0783Z"
				fill="#050911"
			/>
			<path
				d="M183.769 85.0642C184.061 80.0146 190.363 78.1784 193.284 82.3099C193.743 82.9776 194.828 82.3516 194.369 81.6839C190.738 76.5091 182.893 78.7209 182.517 85.0642C182.475 85.8571 183.727 85.8571 183.769 85.0642Z"
				fill="#050911"
			/>
			<path
				d="M178.094 97.4168C177.51 96.6239 177.176 95.7476 177.135 94.7877C176.801 94.9547 176.509 95.1633 176.175 95.3302C177.135 95.9562 177.635 96.9578 177.594 98.0845C177.844 97.8759 178.136 97.6672 178.387 97.5003C177.343 97.1247 176.676 95.9562 176.884 94.8712C176.592 94.9964 176.258 95.1216 175.966 95.2468C176.467 95.5389 176.884 95.9562 177.176 96.4987C177.302 96.7491 177.385 96.9995 177.427 97.2916C177.427 97.5003 177.427 97.2499 177.385 97.1664C177.302 96.9995 177.218 96.8743 177.135 96.7074C176.801 95.9562 175.716 96.6239 176.05 97.3334C176.3 97.9176 176.717 98.5436 177.468 98.5853C178.094 98.6271 178.637 98.2097 178.72 97.5838C178.846 96.2066 177.761 94.8295 176.634 94.2035C176.3 93.9948 175.799 94.1617 175.716 94.5791C175.382 96.3735 176.383 98.1263 178.094 98.7523C178.512 98.8775 178.887 98.5436 178.887 98.168C178.929 96.5822 178.178 95.1633 176.842 94.3287C176.467 94.0783 175.883 94.4121 175.883 94.8712C175.924 96.0397 176.3 97.1664 177.009 98.1263C177.51 98.7105 178.595 98.0846 178.094 97.4168Z"
				fill="#050911"
			/>
			<path
				d="M189.779 89.3627C189.57 89.1123 189.403 88.8201 189.32 88.4863C189.32 88.4028 189.237 87.902 189.32 87.8603C189.403 87.8186 189.612 88.4863 189.654 88.528C189.737 88.8619 189.696 89.2375 189.529 89.5296C189.821 89.4461 190.113 89.3627 190.405 89.3209C189.362 88.7367 188.861 87.6516 189.028 86.4831C188.694 86.6501 188.402 86.8587 188.068 87.0257C189.237 87.5264 189.904 88.7367 189.612 89.9886C189.863 89.8634 190.113 89.6965 190.363 89.5713C189.737 89.3627 189.237 88.8201 189.111 88.1524C189.111 88.1107 189.111 87.7351 189.07 87.7351C188.986 87.7768 188.903 87.8186 188.777 87.8603C188.819 87.9438 188.903 88.069 188.944 88.1524C189.111 88.6115 189.111 89.0705 189.07 89.5713C189.445 89.4044 189.779 89.2792 190.155 89.1123C189.779 88.8201 189.487 88.4863 189.278 88.0272C188.903 87.3178 187.818 87.9438 188.193 88.6532C188.444 89.154 188.819 89.613 189.278 89.9886C189.737 90.3642 190.28 90.1138 190.363 89.5296C190.447 88.6949 190.363 87.5682 189.737 86.9422C189.237 86.4414 188.318 86.3997 187.985 87.1509C187.317 88.6115 188.736 90.3225 190.029 90.7815C190.363 90.9067 190.739 90.6563 190.781 90.3642C191.24 88.6115 190.363 86.6918 188.652 85.9824C188.193 85.7737 187.776 86.0241 187.692 86.5249C187.484 88.1107 188.318 89.6548 189.696 90.4477C189.946 90.6146 190.405 90.5311 190.572 90.239C190.906 89.4461 190.989 88.6532 190.697 87.8603C190.447 87.2343 189.904 86.5666 189.195 86.6501C187.359 86.8587 188.152 89.4878 188.903 90.3225C189.445 90.865 190.322 89.9469 189.779 89.3627Z"
				fill="#050911"
			/>
			<path
				d="M200.671 125.252C200.671 125.294 200.671 125.294 200.629 125.336C200.879 125.21 201.13 125.043 201.38 124.918C201.338 124.918 201.297 124.877 201.255 124.877C201.38 125.169 201.505 125.502 201.63 125.795C201.714 125.628 202.841 125.085 203.175 124.793C203.926 124.167 204.552 123.332 205.136 122.54C206.43 120.829 207.389 118.951 208.182 116.989C207.765 116.864 207.389 116.781 206.972 116.655C205.72 120.244 204.844 125.252 201.338 127.38C201.63 127.673 201.923 127.965 202.215 128.257C203.049 127.005 203.884 125.711 204.719 124.459C205.094 123.875 204.218 123.166 203.717 123.708C203.049 124.417 202.34 125.169 201.672 125.878C201.964 126.17 202.256 126.462 202.549 126.754C203.175 126.003 203.759 125.21 204.301 124.417C204.719 123.833 203.8 123.124 203.3 123.666C202.715 124.292 202.131 124.751 201.38 125.127C201.714 125.294 202.006 125.502 202.34 125.669C202.34 125.544 202.298 125.419 202.298 125.294C202.215 124.501 200.963 124.501 201.046 125.294C201.046 125.419 201.088 125.544 201.088 125.669C201.13 126.128 201.589 126.462 202.048 126.212C202.882 125.753 203.55 125.252 204.218 124.543C203.884 124.292 203.55 124.042 203.216 123.791C202.715 124.501 202.215 125.21 201.672 125.878C201.171 126.504 202.006 127.38 202.549 126.754C203.216 126.045 203.926 125.294 204.593 124.584C204.26 124.334 203.926 124.084 203.592 123.833C202.757 125.085 201.923 126.379 201.088 127.631C200.754 128.173 201.422 128.799 201.964 128.507C205.804 126.17 206.805 120.995 208.182 117.031C208.433 116.238 207.264 115.988 206.972 116.697C206.096 118.825 204.969 120.912 203.467 122.665C202.757 123.499 201.63 123.958 200.963 124.71C200.796 124.877 200.629 124.918 200.545 125.127C200.337 125.502 200.504 125.878 200.921 126.045C200.963 126.045 201.004 126.087 201.046 126.087C201.38 126.212 201.714 125.962 201.797 125.669C201.797 125.628 201.797 125.628 201.839 125.586C202.173 124.835 200.963 124.501 200.671 125.252Z"
				fill="#050911"
			/>
			<path
				d="M205.302 124C205.678 123.583 205.928 123.165 206.053 122.665C206.262 121.913 205.302 121.663 204.927 122.164C204.05 123.332 203.174 124.501 202.297 125.669C201.797 126.295 202.882 126.921 203.382 126.295C204.259 125.127 205.135 123.958 206.012 122.79C205.636 122.623 205.26 122.456 204.885 122.289C204.801 122.581 204.676 122.832 204.467 123.082C203.883 123.75 204.801 124.626 205.302 124Z"
				fill="#050911"
			/>
			<path
				d="M186.022 113.651C184.854 111.314 186.106 110.438 188.443 109.812C188.735 109.728 189.027 109.686 189.277 109.561C190.279 109.102 190.863 108.101 191.281 107.141C191.948 105.722 192.324 104.178 193.2 102.884C195.036 100.088 198.291 100.213 199.335 103.552C200.378 106.89 199.084 110.604 196.873 113.15C195.788 114.402 194.452 115.571 192.866 116.113C191.489 116.614 190.279 116.447 188.944 115.863C187.984 115.445 186.231 114.611 185.981 113.526C185.814 112.733 184.603 113.067 184.77 113.86C185.229 116.03 188.484 117.365 190.446 117.657C192.574 117.991 194.577 116.948 196.205 115.612C199.836 112.608 202.089 107.308 200.336 102.717C198.458 97.751 193.367 99.5872 191.489 103.51C190.905 104.72 190.613 106.765 189.653 107.641C188.443 108.768 186.899 108.351 185.647 109.227C183.894 110.479 184.061 112.691 184.937 114.402C185.313 115.028 186.398 114.402 186.022 113.651Z"
				fill="#050911"
			/>
			<path
				d="M185.188 112.357C191.656 112.19 194.786 106.181 196.205 100.672C196.414 99.8792 195.204 99.5454 194.995 100.338C193.743 105.263 191.03 110.938 185.188 111.105C184.395 111.147 184.395 112.399 185.188 112.357Z"
				fill="#050911"
			/>
			<path
				d="M168.036 97.7926C167.285 98.2099 162.736 93.2438 160.817 86.6084C159.398 81.7675 159.815 77.4691 160.316 74.7565C161.025 72.5865 162.027 70.2495 163.404 67.8708C169.33 57.6882 178.678 52.889 183.519 50.8441C182.517 51.804 181.349 53.0559 180.264 54.6417C178.762 56.7283 177.802 58.6897 177.176 60.3173C178.595 59.107 180.222 57.8968 181.975 56.7283C185.898 54.0992 189.695 52.2213 192.992 50.8858C192.491 53.0142 191.949 55.1425 191.448 57.2291C192.617 56.5614 194.244 55.6016 196.247 54.6417C198.459 53.5984 203.008 51.4284 203.717 52.3882C203.926 52.6803 203.676 53.0142 203.3 54.2244C202.799 55.8102 202.632 57.1456 202.549 58.022C203.842 57.8134 205.303 57.6464 206.889 57.6047C208.642 57.5212 210.227 57.6047 211.646 57.7299C211.146 59.6496 210.645 61.5275 210.144 63.4472C211.146 63.8228 212.564 64.5322 214.025 65.7007C217.948 68.9141 219.075 73.2542 219.367 74.9652C219.993 79.0549 218.991 82.4769 218.24 84.3549C216.237 80.0982 212.272 78.4289 210.019 79.4722C208.642 80.0982 207.056 82.1014 207.223 84.1462C207.264 84.6053 207.39 85.0226 207.515 85.3147C206.054 83.9793 203.842 82.1848 200.963 80.5155C198.083 78.8463 194.87 76.9683 190.613 76.4258C182.601 75.3825 176.341 79.7644 174.672 80.9746C172.084 82.8108 167.87 85.7738 167.118 90.4895C166.367 94.454 168.621 97.5004 168.036 97.7926Z"
				fill="#F79222"
			/>
			<path
				opacity="0.11"
				d="M199.794 79.4724C201.088 82.3936 202.841 86.9424 203.801 92.6597C204.802 98.3352 206.054 105.638 203.008 113.734C201.965 116.489 199.502 121.789 193.66 126.755C195.037 126.713 197.416 126.463 199.92 125.127C204.009 122.999 205.804 119.452 206.346 118.2C208.308 120.119 210.937 123.124 212.94 127.381C214.067 129.759 214.776 131.971 215.194 133.807C216.32 132.806 218.407 130.636 219.367 127.214C219.742 125.878 219.868 124.668 219.868 123.667C217.447 122.331 215.903 120.704 215.027 119.66C214.651 119.201 210.937 114.527 211.146 108.059C211.229 105.722 211.73 105.68 212.105 102.091C212.439 98.8777 212.272 96.1652 212.105 94.3707C214.234 95.2471 215.319 94.8297 215.82 94.5376C216.779 93.9534 217.03 92.9101 217.906 87.6101C218.365 84.7723 218.616 83.3534 218.699 82.6023C219.2 78.5543 219.45 76.5094 219.2 74.8819C218.49 70.2496 215.527 67.1615 214.526 66.2016C212.857 64.5741 211.146 63.656 210.019 63.1552C210.52 61.4024 210.979 59.608 211.479 57.8552C209.101 60.6513 206.889 62.5709 205.387 63.8229C201.631 66.8276 199.461 67.537 196.873 70.4165C194.828 72.6701 193.66 74.9653 192.992 76.5094C195.287 77.5527 197.541 78.5125 199.794 79.4724Z"
				fill="#050911"
			/>
			<path
				opacity="0.17"
				d="M182.601 51.3033C181.182 51.9293 177.843 53.6821 175.506 57.3962C174.922 58.3143 173.629 60.6096 173.253 63.8229C172.836 67.3701 173.712 70.1662 174.213 71.5433C174.38 68.0796 175.214 62.4875 178.553 56.7285C179.847 54.5584 181.265 52.764 182.601 51.3033Z"
				fill="#050911"
			/>
			<path
				opacity="0.08"
				d="M212.105 94.5792C211.98 94.5375 211.729 94.9966 210.811 98.1682C209.559 102.467 208.891 105.638 208.516 107.224C207.973 109.519 207.055 112.774 205.344 116.697C208.307 117.281 211.27 117.866 214.233 118.408C213.148 116.029 211.854 112.607 211.354 108.351C210.561 101.048 212.522 94.6627 212.105 94.5792Z"
				fill="#050911"
			/>
		</svg>
	);
};

export default RocketMan;
