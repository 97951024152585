import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroBg from "./HeroBg";
import WaitlistForm from "./WaitlistForm";

const Hero = () => {
	return (
		<section className="hero bg-cream">
			<Container className="hero-container">
				<Row>
					<Col className="hero-col col-lg-6 col-12 flex-row-left">
						<div className="hero-content">
							<h3 className="text-medium-rubik title-lg">
								Building a more <span className="text-orange">open</span>,{" "}
								<span className="text-orange-light">transparent</span>, and{" "}
								<span className="text-orange-dark">engaging</span> way for
								learning
							</h3>
							<p className="hero-desc text-lg opacity-5">
								AlunaLearn is an educational brand intent on reforming the way
								that we see education and building a more open, transparent, and
								engaging way for everyone to learn.
							</p>
							<WaitlistForm />
						</div>
					</Col>
					<Col className="hero-col hero-bg-container col-lg-6 col-12">
						<HeroBg className="hero-bg" />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Hero;
