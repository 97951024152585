import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";
import Container from "react-bootstrap/esm/Container";
import PageFooter from "../components/PageFooter";

const Contact = () => {
	return (
		<>
			<div className="content full-page flex-column bg-cream">
				<Container className="flex-column">
					<Form className="contact-form col-lg-4 col-md-8 col-12 align-center">
						<h1 className="contact-title title-xl text-medium-rubik">
							Contact Us
						</h1>
						<p className="contact-desc text-lg opacity-5 align-center">
							Illum eos error enim possimus nisi ipsa sint explicabo delectus
							voluptatum ex.
						</p>
						<Form.Control type="text" placeholder="Name" />
						<Form.Control type="email" placeholder="Email" />
						<Form.Control as="textarea" type="text" placeholder="Message..." />
						<Button type="submit" className="contact-btn btn-default full">
							Submit
						</Button>
					</Form>
				</Container>
			</div>
			<PageFooter />
		</>
	);
};

export default Contact;
