import PageFooter from "../components/PageFooter";
import Container from "react-bootstrap/Container";

const Legal = () => {
	return (
		<>
			<Container className="content full-page bg-white flex-column">
				<div className="legal-container col-md-5 col-12">
					<h1 className="legal-title title-xl text-medium-rubik">
						Legal/Privacy
					</h1>
					<span className="legal-desc text-lg opacity-5">
						Last Updated: January 1, 2023
					</span>
					<h2 className="legal-header title-md text-medium-rubik">
						Ad magnam facilis ea et et at
					</h2>
					<p className="legal-content text-lg opacity-5">
						Est dolor omnis eum enim fuga delectus dolor aliquid. Dolorem
						molestiae amet et quisquam id sint sunt itaque ad. Numquam quasi
						beatae non nulla optio beatae dolor sint aut hic veritatis
						architecto odit debitis. Culpa nesciunt autem labore dolor eos alias
						voluptatem rem hic officia vero. Dignissimos quia et molestias.
						Fugit aut nam qui nesciunt qui et voluptatem fugit accusamus.
						Corporis quia distinctio et dolor excepturi quidem harum magni rerum
						maiores et. Et at omnis eius quia corporis iste magnam voluptatem
						qui cupiditate sapiente numquam aut. Sint velit magnam perferendis
						ut eligendi eveniet dolorem illum sunt provident voluptas adipisci
						ipsam. Dolorem nulla dolorem ut provident asperiores dolor veniam
						cum numquam modi explicabo. Quos aut quia est consequuntur. Facere
						nesciunt rerum voluptatem est reprehenderit corrupti vel ipsam sequi
						optio consectetur quam. Sit sed sint placeat incidunt animi in
						perferendis commodi illum. Pariatur repellendus necessitatibus
						voluptates magnam aliquam quia et quam adipisci. Laboriosam sed
						distinctio quam quo ut ducimus et quia officia nihil. Officiis eaque
						quod molestias. Rem repellat vel repellendus nihil perspiciatis quia
						autem. Autem id eum aut dolores aspernatur id unde mollitia maiores
						nesciunt.
					</p>
					<h2 className="legal-header title-md text-medium-rubik">
						Ad magnam facilis ea et et at
					</h2>
					<p className="legal-content text-lg opacity-5">
						Est dolor omnis eum enim fuga delectus dolor aliquid. Dolorem
						molestiae amet et quisquam id sint sunt itaque ad. Numquam quasi
						beatae non nulla optio beatae dolor sint aut hic veritatis
						architecto odit debitis. Culpa nesciunt autem labore dolor eos alias
						voluptatem rem hic officia vero. Dignissimos quia et molestias.
						Fugit aut nam qui nesciunt qui et voluptatem fugit accusamus.
						Corporis quia distinctio et dolor excepturi quidem harum magni rerum
						maiores et. Et at omnis eius quia corporis iste magnam voluptatem
						qui cupiditate sapiente numquam aut. Sint velit magnam perferendis
						ut eligendi eveniet dolorem illum sunt provident voluptas adipisci
						ipsam. Dolorem nulla dolorem ut provident asperiores dolor veniam
						cum numquam modi explicabo. Quos aut quia est consequuntur.
					</p>
				</div>
			</Container>
			<PageFooter />
		</>
	);
};

export default Legal;
