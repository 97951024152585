import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutPreview from "../components/AboutPreview";
import WaitlistPreview from "../components/WaitlistPreview";
import PreviewFooter from "../components/PreviewFooter";
import UpdateList from "../components/UpdateList";

const Updates = () => {
	return (
		<div className="content">
			<div className="updates-hero bg-cream flex-row-left full">
				<Container>
					<h1 className="updates-title title-lg text-medium-rubik">
						Product Updates and More
					</h1>
					<p className="opacity-5 m-0">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					</p>
				</Container>
			</div>
			<Container className="pt-5 pb-5">
				<Row>
					<Col className="col-lg-8 col-12">
						<UpdateList />
					</Col>
					<Col className="preview-col col-lg-4 col-12">
						<AboutPreview />
						<WaitlistPreview />
						<PreviewFooter />
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Updates;
