const YoutubeIcon = ({ className }) => {
	return (
		<svg
			className={className}
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_134_858)">
				<path
					d="M18.4526 5.70944C18.8334 7.19444 18.8334 10.2944 18.8334 10.2944C18.8334 10.2944 18.8334 13.3944 18.4526 14.8794C18.2409 15.7003 17.6217 16.3461 16.8376 16.5644C15.4134 16.9611 10.5001 16.9611 10.5001 16.9611C10.5001 16.9611 5.58925 16.9611 4.16258 16.5644C3.37508 16.3428 2.75675 15.6978 2.54758 14.8794C2.16675 13.3944 2.16675 10.2944 2.16675 10.2944C2.16675 10.2944 2.16675 7.19444 2.54758 5.70944C2.75925 4.8886 3.37841 4.24277 4.16258 4.02444C5.58925 3.62777 10.5001 3.62777 10.5001 3.62777C10.5001 3.62777 15.4134 3.62777 16.8376 4.02444C17.6251 4.2461 18.2434 4.8911 18.4526 5.70944V5.70944ZM8.83342 13.2111L13.8334 10.2944L8.83342 7.37777V13.2111Z"
					fill="#050911"
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_134_858">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="translate(0.5 0.294434)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default YoutubeIcon;
