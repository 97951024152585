import { React } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "remixicon/fonts/remixicon.css";
import "./index.css";

import ScrollToTop from "./components/ScrollToTop";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Updates from "./pages/Updates";
import About from "./pages/About";
import Contact from "./pages/Contact";
import WaitlistSuccess from "./pages/WaitlistSuccess";
import Legal from "./pages/Legal";
import NotFound from "./pages/NotFound";
import Post from "./pages/Post";

const root = createRoot(document.getElementById("root"));

export default function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/*" element={<Layout />}>
					<Route index element={<Home />} />
					<Route path="updates" element={<Updates />} />
					<Route path="about" element={<About />} />
					<Route path="contact" element={<Contact />} />
					<Route path="waitlist/success" element={<WaitlistSuccess />} />
					<Route path="legal" element={<Legal />} />
					<Route path="blog/:id" element={<Post />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

root.render(
	<SnackbarProvider>
		<App />
	</SnackbarProvider>
);
