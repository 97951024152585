import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Logo from "../components/Logo";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Layout = () => {
	const [showNav, setShowNav] = useState(false);

	return (
		<>
			<div className="main-nav bg-white flex-row-left">
				<Container>
					<Row className="flex-row-left">
						<Col>
							<Link to="/" className="nav-brand">
								<Logo className="nav-logo" />
							</Link>
						</Col>
						<Col>
							<div className="nav-lg flex-row-right">
								<Nav.Link href="/about/" className="text-regular-inter">
									About
								</Nav.Link>
								<Nav.Link href="/updates/" className="text-regular-inter">
									Updates
								</Nav.Link>
								<Nav.Link href="#">
									<Button
										variant="dark"
										className="nav-btn text-medium-inter text-sm rounded"
									>
										Join Waitlist
									</Button>
								</Nav.Link>
							</div>
							<div className="nav-sm flex-row-right">
								<div
									className="nav-toggle flex-column"
									onClick={() => setShowNav(!showNav)}
								>
									<div className={`fries ${showNav ? "active" : ""}`}>
										<div className="top-fry" />
										<div className="mid-fry" />
										<div className="bottom-fry" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className={`nav-panel bg-white ${showNav && "active"}`}>
				<Container>
					<Nav.Link href="/about/" className="text-regular-inter text-xl">
						About
					</Nav.Link>
					<Nav.Link href="/updates/" className="text-regular-inter text-xl">
						Updates
					</Nav.Link>
				</Container>
				<div className="nav-panel-footer full flex-column">
					<Container>
						<Button
							variant="dark"
							className="nav-panel-btn text-medium-inter text-sm rounded"
						>
							Join Waitlist
						</Button>
					</Container>
				</div>
			</div>
			<Outlet />
		</>
	);
};

export default Layout;
