import PageFooter from "../components/PageFooter";
const NotFound = () => {
	return (
		<>
			<div className="content full-page bg-cream flex-column">
				<h1 className="not-found-title title-xl text-medium-rubik">
					404 - That's an error.
				</h1>
				<p className="text-lg opacity-5">Sorry, that page does not exist.</p>
			</div>
			<PageFooter />
		</>
	);
};

export default NotFound;
