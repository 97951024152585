import { useEffect, useState } from "react";
import blog1 from "../assets/blog1.png";
import BlogItemLoader from "./BlogItemLoader";
import BlogItem from "./BlogItem";

const BlogList = () => {
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchPosts = async () => {
		setLoading(true);
		const response = await fetch(`${process.env.REACT_APP_SERVER_URI}/feed`);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			setPosts(data.posts);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchPosts();
	}, []);

	return (
		<>
			<div className="blog-highlight">
				<div
					className="blog-highlight-img rounded bg-cover"
					style={{ background: `url(${blog1})` }}
				/>
				<div className="blog-highlight-header flex-row-left">
					<span className="blog-item-tag text-bold-inter label-md rounded flex-column">
						Highlight
					</span>
					<span className="blog-item-date label-md opacity-5 upper">
						January 1, 2023
					</span>
				</div>
				<div className="blog-item-title full">
					<span className="title-md text-medium-inter">
						The Classroom Model
					</span>
				</div>
				<div className="blog-item-desc">
					<div className="blog-desc-cover" />
					<p className="opacity-5">
						The traditional classroom model that we are familiar with today has
						been around for centuries, but is it really the best way to educate
						students? While the classroom model has its benefits, it could be
						argued that it doesn’t model that we are familiar with today has
						been around for centuries, but is it really the best way to educate
						students? While the classroom model has its benefits, it could be
						argued that it doesn’t
					</p>
				</div>
				<a href="#" className="text-medium-inter text-orange">
					Read More
				</a>
			</div>
			<div className="blog-item-list">
				<h2 className="title-lg blog-items-title text-medium-rubik">Blog</h2>
				{loading ? (
					<BlogItemLoader />
				) : (
					posts.map((post, index) => (
						<BlogItem
							key={index}
							tags={["New"]}
							createdAt={post.created_at}
							title={post.title}
							desc={post.desc}
							img={post.img_url ? post.img_url : null}
							url={`/blog/${post.url}`}
						/>
					))
				)}
			</div>
		</>
	);
};

export default BlogList;
