import { Link } from "react-router-dom";
const UpdateItem = ({ title, createdAt }) => {
	return (
		<div className="update-item">
			<Link to="/" className="update-item-title text-medium-inter">
				{title}
			</Link>
			<span className="blog-item-date label-md opacity-5 upper">
				{createdAt}
			</span>
		</div>
	);
};

export default UpdateItem;
