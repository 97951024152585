const Jogging = ({ className }) => {
	return (
		<svg
			className={className}
			width="460"
			height="338"
			viewBox="0 0 460 338"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M63.1993 276.409C63.9399 275.981 64.7194 275.552 65.4599 275.123C66.1225 274.772 65.5379 273.759 64.8753 274.11C64.1348 274.538 63.3552 274.967 62.6147 275.396C61.9521 275.786 62.5368 276.799 63.1993 276.409Z"
				fill="white"
			/>
			<path
				d="M52.7536 281.437C59.8861 278.397 67.0186 275.357 74.1512 272.317C81.2837 269.277 87.9485 266.276 95.9385 267.094C103.227 267.835 110.398 270.602 117.219 273.174C124.352 275.864 131.328 278.904 138.11 282.334C139.435 282.996 140.643 280.969 139.279 280.307C128.522 274.928 117.258 270.173 105.721 266.782C100.148 265.145 94.3015 264.054 88.4941 264.873C82.6868 265.691 77.2302 268.458 71.8906 270.758C65.1088 273.642 58.3271 276.526 51.5843 279.449C50.1812 279.995 51.3895 282.022 52.7536 281.437Z"
				fill="white"
			/>
			<path
				d="M118.856 298.508C151.478 298.508 184.062 298.508 216.684 298.508C226 298.508 235.315 298.508 244.63 298.508C246.15 298.508 246.15 296.17 244.63 296.17C212.007 296.17 179.424 296.17 146.801 296.17C137.486 296.17 128.171 296.17 118.856 296.17C117.336 296.17 117.336 298.508 118.856 298.508Z"
				fill="white"
			/>
			<path
				d="M223.583 306.148C264.469 306.148 305.354 306.148 346.239 306.148C357.893 306.148 369.508 306.148 381.161 306.148C382.681 306.148 382.681 303.809 381.161 303.809C340.276 303.809 299.391 303.809 258.506 303.809C246.852 303.809 235.237 303.809 223.583 303.809C222.102 303.809 222.102 306.148 223.583 306.148Z"
				fill="white"
			/>
			<path
				d="M281.345 297.612C327.024 297.612 372.665 297.612 418.344 297.612C431.323 297.612 444.302 297.612 457.281 297.612C458.801 297.612 458.801 295.273 457.281 295.273C411.601 295.273 365.961 295.273 320.282 295.273C307.303 295.273 294.324 295.273 281.345 295.273C279.825 295.273 279.825 297.612 281.345 297.612Z"
				fill="white"
			/>
			<path
				d="M143.059 285.296C149.997 287.751 156.896 290.363 163.716 293.13C165.12 293.714 165.704 291.454 164.34 290.869C157.519 288.102 150.621 285.491 143.683 283.035C142.28 282.528 141.656 284.789 143.059 285.296Z"
				fill="white"
			/>
			<path
				d="M56.5337 304.199C74.4624 304.199 92.4301 304.199 110.359 304.199C111.879 304.199 111.879 301.86 110.359 301.86C92.4301 301.86 74.4624 301.86 56.5337 301.86C55.0526 301.86 55.0526 304.199 56.5337 304.199Z"
				fill="white"
			/>
			<path
				d="M216.567 329.494C239.406 329.494 262.246 329.494 285.086 329.494C286.606 329.494 286.606 327.155 285.086 327.155C262.246 327.155 239.406 327.155 216.567 327.155C215.047 327.155 215.047 329.494 216.567 329.494Z"
				fill="white"
			/>
			<path
				d="M23.523 304.355C17.3259 300.262 12.6878 294.338 9.37488 287.751C7.50406 284.088 6.06197 280.268 4.85373 276.37C4.30807 274.694 3.41163 272.668 3.29471 270.914C3.25573 270.134 2.9829 270.251 3.80139 270.212C4.23012 270.212 4.85373 270.524 5.24348 270.641C6.33479 270.953 7.38713 271.304 8.47845 271.615C9.21898 271.849 9.49181 270.719 8.79025 270.485C7.46508 270.095 6.13992 269.667 4.81475 269.277C4.23012 269.082 3.33368 268.653 2.6711 268.887C1.57978 269.238 1.93056 270.368 2.12544 271.226C3.33368 275.981 4.8927 280.658 6.84148 285.14C10.3493 293.169 15.572 300.457 22.9384 305.368C23.562 305.797 24.1466 304.784 23.523 304.355Z"
				fill="#D0D5DD"
			/>
			<path
				d="M7.81541 270.29C2.00807 256.103 1.77421 240.63 1.57933 225.507C1.22855 225.663 0.916751 225.78 0.565971 225.936C1.65729 227.027 2.78757 228.158 3.87889 229.249C4.42455 229.795 5.24303 228.937 4.69737 228.431C3.60606 227.339 2.47577 226.209 1.38446 225.118C1.03368 224.767 0.371094 225.04 0.371094 225.546C0.526996 240.747 0.799824 256.337 6.64615 270.641C6.95795 271.304 8.08824 270.992 7.81541 270.29Z"
				fill="#D0D5DD"
			/>
			<path
				d="M4.93098 228.119C5.00893 218.492 6.06127 208.904 8.16595 199.511C7.89312 199.628 7.58132 199.745 7.30849 199.862C18.4555 207.774 22.8207 221.844 26.8742 234.238C31.6292 248.815 35.4098 263.82 37.0468 279.099C37.5145 283.347 37.7873 287.595 37.9043 291.883C38.0212 296.326 38.255 300.964 37.0468 305.29C36.8519 306.031 37.9822 306.343 38.1771 305.602C39.1904 301.86 39.1515 297.924 39.1125 294.104C39.0735 290.207 38.8786 286.309 38.5279 282.412C37.8263 274.578 36.5791 266.782 34.9031 259.104C33.1492 251.075 30.9666 243.163 28.4332 235.368C26.2896 228.703 24.068 221.961 21.0668 215.608C17.9488 209.099 13.8174 203.097 7.89312 198.887C7.58132 198.692 7.11361 198.887 7.03566 199.238C4.93098 208.748 3.83967 218.414 3.76172 228.158C3.76172 228.898 4.93098 228.898 4.93098 228.119Z"
				fill="#D0D5DD"
			/>
			<path
				d="M31.6297 247.529C37.3591 230.613 49.7533 217.089 56.8469 200.836C56.4961 200.719 56.1063 200.641 55.7556 200.524C56.4571 208.124 57.0418 215.764 57.5874 223.403C57.8602 227.222 58.0551 231.042 58.289 234.862C58.3669 236.654 58.4839 238.408 58.5618 240.201C58.6398 241.565 58.7177 241.916 57.6654 242.813C56.9248 243.436 55.9115 243.904 55.054 244.449C54.4304 244.839 55.015 245.853 55.6386 245.463C56.9248 244.644 58.25 243.826 59.5362 243.007C59.6921 242.89 59.848 242.696 59.809 242.501C59.1464 228.508 58.211 214.477 56.9248 200.524C56.8859 200.017 56.1063 199.628 55.8335 200.212C48.662 216.582 36.2678 230.184 30.4994 247.178C30.2656 247.957 31.3959 248.23 31.6297 247.529Z"
				fill="#D0D5DD"
			/>
			<path
				d="M55.0146 245.424C60.4711 245.112 56.8854 256.883 56.2618 259.767C55.2484 264.522 54.0402 269.238 52.6371 273.876C49.7919 283.269 46.0892 292.35 41.646 301.081C41.2952 301.743 42.3086 302.328 42.6593 301.666C47.8041 291.571 51.9355 281.008 54.9756 270.095C56.4956 264.6 57.7429 259.026 58.6783 253.414C59.1849 250.296 60.0814 243.943 55.0535 244.255C54.274 244.294 54.274 245.463 55.0146 245.424Z"
				fill="#D0D5DD"
			/>
			<path
				d="M55.3266 202.2C52.4034 225.975 42.0359 247.84 35.7998 270.758C35.605 271.499 36.7352 271.81 36.9301 271.07C43.2052 248.035 53.5337 226.053 56.4958 202.2C56.5738 201.421 55.4045 201.46 55.3266 202.2Z"
				fill="#D0D5DD"
			/>
			<path
				d="M6.8803 199.706C15.9616 226.833 23.289 254.544 28.7845 282.607C30.3436 290.558 31.7467 298.586 33.0329 306.576C33.1498 307.317 34.2801 307.005 34.1632 306.265C29.7199 278.007 23.4449 250.023 15.338 222.584C13.0384 214.789 10.583 207.033 8.01059 199.355C7.77674 198.692 6.64645 199.004 6.8803 199.706Z"
				fill="#D0D5DD"
			/>
			<path
				d="M1.19007 235.407C11.4406 241.682 19.5865 250.88 24.6143 261.794C24.9261 262.495 25.9395 261.872 25.6277 261.209C20.5219 250.14 12.1812 240.786 1.7747 234.394C1.15109 234.004 0.527484 235.017 1.19007 235.407Z"
				fill="#D0D5DD"
			/>
			<path
				d="M4.11225 277.15C14.2849 280.736 25.3929 284.867 30.9274 294.806C31.2782 295.468 32.2915 294.884 31.9408 294.221C26.2114 284.01 14.8695 279.722 4.42405 276.02C3.7225 275.747 3.41069 276.877 4.11225 277.15Z"
				fill="#D0D5DD"
			/>
			<path
				d="M18.2612 212.879C17.3258 218.843 16.7801 224.806 16.7021 230.847C16.7021 231.588 17.8714 231.588 17.8714 230.847C17.9494 224.923 18.456 219.037 19.3915 213.191C19.5084 212.451 18.3781 212.139 18.2612 212.879Z"
				fill="#D0D5DD"
			/>
			<path
				d="M38.7624 230.73C39.7757 237.746 40.7501 244.761 41.7635 251.816C41.8804 252.556 43.0107 252.245 42.8938 251.504C41.8804 244.488 40.906 237.473 39.8927 230.418C39.7757 229.639 38.6455 229.99 38.7624 230.73Z"
				fill="#D0D5DD"
			/>
			<path
				d="M48.8563 235.173C52.5979 231.821 55.7939 227.963 58.3273 223.598C58.7171 222.935 57.7037 222.35 57.314 223.013C54.8196 227.261 51.7015 231.081 48.0378 234.355C47.4532 234.862 48.3106 235.68 48.8563 235.173Z"
				fill="#D0D5DD"
			/>
			<path
				d="M399.325 294.533C395.739 283.971 393.712 273.019 393.244 261.872C392.933 262.027 392.66 262.222 392.348 262.378C394.297 263.509 396.207 264.678 398.155 265.808C398.584 266.042 399.169 265.652 399.013 265.145C397.259 260.196 396.947 254.895 398.272 249.789C399.636 244.644 402.638 239.967 405.171 235.329C410.433 225.624 415.733 215.919 420.995 206.215C420.644 206.176 420.293 206.137 419.943 206.059C421.19 213.737 422.437 221.454 423.684 229.132C424.308 232.913 424.932 236.693 425.516 240.474C425.828 242.345 426.685 244.722 425.555 246.437C424.62 247.84 422.905 248.62 421.385 249.282C420.878 249.516 421.112 250.179 421.541 250.335C423.217 251.036 425.243 251.933 426.062 253.648C426.958 255.596 425.867 258.403 425.36 260.429C424.386 264.405 423.217 268.341 421.852 272.239C419.085 280.073 415.538 287.556 411.29 294.689C410.9 295.352 411.914 295.936 412.303 295.274C417.175 287.05 421.151 278.319 424.074 269.199C424.776 266.977 425.438 264.717 426.023 262.456C426.569 260.313 427.309 258.013 427.504 255.791C427.816 252.167 424.698 250.452 421.814 249.243C421.852 249.594 421.891 249.945 421.969 250.296C424.269 249.282 426.958 247.84 427.231 245.034C427.387 243.202 426.802 241.176 426.53 239.422C426.179 237.161 425.789 234.901 425.438 232.601C423.996 223.637 422.515 214.711 421.073 205.747C420.995 205.24 420.216 205.201 420.021 205.591C414.369 215.958 408.718 226.326 403.066 236.732C400.572 241.331 397.805 245.892 396.791 251.114C395.856 255.908 396.285 260.819 397.922 265.418C398.194 265.184 398.506 264.99 398.779 264.756C396.83 263.625 394.92 262.456 392.972 261.326C392.582 261.092 392.075 261.365 392.075 261.833C392.504 273.058 394.57 284.166 398.194 294.806C398.428 295.546 399.558 295.235 399.325 294.533Z"
				fill="#D0D5DD"
			/>
			<path
				d="M426.1 232.25C434.207 223.753 442.275 215.179 450.226 206.526C449.914 206.331 449.564 206.137 449.252 205.942C448.005 212.1 447.459 218.414 447.771 224.689C447.927 227.924 448.394 231.081 448.784 234.277C448.979 235.719 449.135 237.2 449.174 238.642C449.213 239.422 449.33 240.63 448.472 241.137C447.771 241.526 446.017 241.448 445.159 241.526C444.731 241.565 444.302 242.189 444.731 242.54C449.291 246.164 445.354 252.79 443.445 256.844C441.379 261.248 439.157 265.535 436.741 269.745C431.83 278.319 426.178 286.465 419.903 294.104C419.436 294.689 420.254 295.507 420.722 294.923C427.659 286.504 433.818 277.462 439.118 267.952C441.808 263.119 444.302 258.13 446.485 253.063C448.044 249.399 449.213 244.644 445.588 241.76C445.432 242.111 445.315 242.423 445.159 242.774C447.342 242.54 450.07 242.89 450.343 240.201C450.655 236.615 449.681 232.796 449.291 229.249C448.433 221.61 448.823 213.893 450.382 206.331C450.499 205.786 449.837 205.318 449.408 205.747C441.418 214.399 433.389 222.974 425.282 231.471C424.736 231.977 425.555 232.796 426.1 232.25Z"
				fill="#D0D5DD"
			/>
			<path
				d="M444.73 213.269C439.001 225.001 437.987 238.486 433.973 250.803C429.725 263.898 423.722 276.331 417.291 288.453C416.941 289.115 417.954 289.7 418.305 289.037C424.541 277.228 430.387 265.146 434.636 252.478C436.74 246.164 438.299 239.772 439.741 233.264C441.183 226.638 442.742 219.973 445.744 213.854C446.055 213.191 445.081 212.607 444.73 213.269Z"
				fill="#D0D5DD"
			/>
			<path
				d="M418.618 213.191C417.526 238.369 407.51 262.027 405.366 287.05C405.288 287.79 406.457 287.79 406.535 287.05C408.679 262.027 418.696 238.369 419.787 213.191C419.787 212.451 418.618 212.451 418.618 213.191Z"
				fill="#D0D5DD"
			/>
			<path
				d="M399.013 244.995C401.469 251.855 404.509 258.442 408.133 264.756C408.523 265.418 409.536 264.834 409.147 264.171C405.561 257.935 402.56 251.426 400.143 244.683C399.91 243.982 398.779 244.294 399.013 244.995Z"
				fill="#D0D5DD"
			/>
			<path
				d="M410.783 223.247C412.186 228.548 413.55 233.848 414.954 239.149C415.148 239.889 416.279 239.578 416.084 238.837C414.681 233.536 413.317 228.236 411.914 222.935C411.719 222.194 410.588 222.506 410.783 223.247Z"
				fill="#D0D5DD"
			/>
			<path
				d="M422.203 220.285C420.449 223.52 418.734 226.794 416.98 230.029C416.629 230.691 417.643 231.276 417.993 230.613C419.747 227.378 421.462 224.104 423.216 220.869C423.567 220.207 422.553 219.622 422.203 220.285Z"
				fill="#D0D5DD"
			/>
			<path
				d="M430.544 225.78C431.129 234.355 431.986 242.929 433.155 251.465C433.272 252.206 434.364 251.894 434.286 251.153C433.155 242.735 432.298 234.277 431.713 225.78C431.674 225.04 430.505 225.04 430.544 225.78Z"
				fill="#D0D5DD"
			/>
			<path
				d="M439.275 234.394C439.664 232.133 441.808 230.379 443.289 228.703C444.809 226.989 446.368 225.274 447.888 223.559C448.395 223.013 447.576 222.156 447.07 222.74C445.199 224.806 443.367 226.872 441.496 228.937C440.132 230.457 438.534 231.938 438.144 234.043C438.027 234.823 439.158 235.134 439.275 234.394Z"
				fill="#D0D5DD"
			/>
			<path
				d="M190.455 217.517C225.533 214.127 260.299 207.968 294.402 199.043C311.552 194.561 328.389 189.104 345.266 183.687C353.411 181.075 361.674 178.62 370.093 177.061C379.174 175.346 388.333 174.956 397.571 175.541C407.821 176.203 418.072 177.529 428.283 178.659C438.534 179.789 448.823 180.88 459.074 182.011C459.815 182.089 459.815 180.919 459.074 180.841C440.755 178.854 422.437 176.632 404.08 174.878C395.115 174.021 386.112 173.67 377.186 174.8C368.768 175.814 360.466 177.879 352.359 180.296C335.405 185.324 318.723 191.248 301.652 195.925C284.62 200.602 267.431 204.578 250.087 207.852C230.366 211.593 210.449 214.438 190.455 216.348C189.714 216.426 189.714 217.595 190.455 217.517Z"
				fill="#D0D5DD"
			/>
			<path
				d="M201.017 142.178C208.11 138.436 215.672 135.123 223.7 134.149C231.729 133.174 239.408 135.201 246.93 137.774C254.413 140.307 261.78 142.879 269.536 144.438C277.097 145.958 284.775 146.777 292.492 146.894C308.316 147.128 323.868 144.243 338.717 138.787C353.645 133.291 367.754 125.808 382.409 119.689C399.051 112.712 416.98 107.451 435.182 109.399C435.922 109.477 435.922 108.308 435.182 108.23C402.286 104.722 373.327 123.664 344.096 135.474C328.934 141.593 313.149 145.413 296.78 145.725C288.712 145.88 280.605 145.257 272.654 143.854C264.742 142.451 257.18 140.112 249.619 137.462C241.473 134.617 233.133 132.044 224.402 132.941C215.905 133.798 207.954 137.267 200.432 141.203C199.77 141.515 200.354 142.529 201.017 142.178Z"
				fill="#D0D5DD"
			/>
			<path
				d="M299.002 300.418C299.002 303.419 301.808 305.602 305.043 308.174C308.083 310.552 312.955 313.592 319.854 314.878C320.984 318.776 323.323 326.298 324.882 325.908C326.168 325.596 326.753 319.555 326.012 314.644C325.739 312.812 325.466 311.097 324.492 309.344C322.192 305.329 319.191 306.654 315.956 303.497C311.903 299.522 313.462 294.299 310.344 292.428C306.329 290.012 299.002 295.507 299.002 300.418Z"
				fill="white"
			/>
			<path
				d="M299.04 306.265C298.689 309.188 301.262 311.877 304.224 315.034C309.135 320.257 314.201 322.868 317.826 324.193C318.606 329.689 320.009 337.679 321.568 337.64C323.01 337.601 324.53 330.975 324.218 325.207C324.101 323.063 324.023 321.465 323.283 319.711C321.373 315.151 317.982 316.242 315.449 312.384C312.603 307.979 315.02 303.653 312.642 300.964C309.213 297.066 299.664 301.081 299.04 306.265Z"
				fill="white"
			/>
			<path
				d="M260.922 295.39C259.48 295.235 258.817 298.236 256.206 302.952C254.764 305.602 251.178 312.111 245.722 315.814C241.434 318.698 237.693 318.737 237.732 320.101C237.771 321.114 239.875 321.777 241.006 321.933C243.773 322.323 245.839 320.491 246.501 319.906C250.905 317.1 254.374 314.216 256.83 311.955C262.91 306.382 263.611 303.965 263.728 302.172C263.923 299.015 262.442 295.546 260.922 295.39Z"
				fill="white"
			/>
			<path
				d="M261.039 291.298C263.144 292.428 266.223 293.87 270.043 294.962C272.459 295.624 279.903 297.456 291.284 295.468C303.873 293.247 307.069 288.803 310.499 292.077C314.085 295.507 314.787 304.628 312.097 310.084C310.46 313.397 307.108 314.683 300.444 317.295C295.221 319.321 291.323 320.841 285.711 320.958C279.124 321.114 274.291 319.204 271.446 318.113C265.522 315.736 260.338 313.67 258.311 307.707C257.454 305.212 257.415 302.796 257.532 301.198C258.74 297.885 259.909 294.572 261.039 291.298Z"
				fill="white"
			/>
			<path
				d="M300.717 296.482C301.029 294.221 303.64 293.636 305.901 292.194C311.864 288.336 311.046 280.969 315.138 279.956C318.607 279.099 320.088 284.204 323.596 283.23C327.727 282.061 328.896 274.11 330.338 274.655C331.391 275.045 332.014 279.956 330.338 283.035C328.624 286.153 325.038 286.504 320.945 286.894C316.853 287.283 316.541 286.348 314.826 287.283C311.669 289.076 312.254 292.545 308.395 296.248C306.213 298.352 302.783 300.418 301.341 298.898C300.834 298.352 300.6 297.339 300.717 296.482Z"
				fill="white"
			/>
			<path
				d="M262.715 299.95C261.273 299.795 260.61 302.796 257.999 307.512C256.557 310.162 252.971 316.671 247.515 320.374C243.227 323.258 239.486 323.297 239.525 324.661C239.564 325.674 241.668 326.337 242.799 326.493C245.566 326.883 247.632 325.051 248.294 324.466C252.698 321.66 256.167 318.776 258.623 316.515C264.703 310.942 265.404 308.525 265.521 306.732C265.716 303.575 264.235 300.106 262.715 299.95Z"
				fill="white"
			/>
			<path
				d="M242.642 294.221C243.578 300.068 242.72 301.276 244.162 303.186C246.345 306.109 250.437 306.498 251.139 306.576C254.179 306.888 254.647 305.641 257.921 305.992C261.584 306.382 262.169 307.98 264.001 307.395C266.69 306.537 267.47 302.289 267.704 300.964C267.743 300.847 267.743 300.73 267.743 300.652C268.054 298.041 267.431 295.702 266.573 293.87C261.507 291.688 258.505 288.959 256.674 286.894C254.725 284.672 253.517 282.412 252.464 282.684C251.295 282.996 251.139 286.231 251.178 288.765C249.775 289.154 248.372 289.544 246.969 289.934C243.344 286.27 242.097 285.997 241.59 286.309C240.772 286.777 241.824 288.959 242.642 294.221Z"
				fill="white"
			/>
			<path
				d="M249.268 299.444C249.58 300.691 250.75 301.51 252.036 301.432C252.231 301.432 252.231 301.12 252.036 301.12C250.905 301.237 249.853 300.496 249.58 299.366C249.502 299.21 249.229 299.288 249.268 299.444Z"
				fill="#050911"
			/>
			<path
				d="M249.152 299.366C249.152 299.834 249.152 300.34 249.035 300.808C248.996 301.042 248.918 301.237 248.801 301.432C248.801 301.432 248.256 302.211 248.178 302.094C248.1 301.938 247.827 302.094 247.944 302.25C248.178 302.679 248.762 302.016 248.918 301.821C249.425 301.12 249.464 300.184 249.464 299.366C249.425 299.171 249.152 299.171 249.152 299.366Z"
				fill="#050911"
			/>
			<path
				d="M249.23 299.21C249.152 299.327 249.347 299.249 249.308 299.288C249.191 299.366 249.191 299.21 249.308 299.171C249.425 299.171 249.425 299.327 249.386 299.366C249.347 299.405 249.347 299.405 249.308 299.405C249.269 299.405 249.23 299.405 249.23 299.366V299.327C249.191 299.327 249.152 299.327 249.152 299.288C248.996 299.171 248.84 299.444 248.996 299.522C249.191 299.639 249.541 299.639 249.541 299.327C249.541 299.093 249.23 298.937 249.035 299.132C248.84 299.327 248.957 299.639 249.191 299.678C249.464 299.756 249.697 299.561 249.697 299.288C249.697 299.054 249.541 298.82 249.269 298.859C249.035 298.898 248.84 299.171 248.957 299.405C249.074 299.6 249.347 299.6 249.502 299.483C249.658 299.366 249.658 299.093 249.464 298.976C249.269 298.859 248.957 298.976 248.957 299.21C248.957 299.483 249.386 299.522 249.502 299.288C249.58 299.171 249.308 299.015 249.23 299.21Z"
				fill="#050911"
			/>
			<path
				d="M245.449 297.144C245.917 296.443 246.969 296.287 247.592 296.872C247.748 296.988 247.943 296.794 247.787 296.677C247.008 295.975 245.761 296.17 245.176 297.027C245.098 297.144 245.371 297.3 245.449 297.144Z"
				fill="#050911"
			/>
			<path
				d="M251.061 295.975C251.645 295.195 252.698 294.962 253.594 295.351C253.75 295.429 253.906 295.195 253.75 295.118C252.698 294.65 251.528 294.962 250.827 295.858C250.71 295.975 250.944 296.092 251.061 295.975Z"
				fill="#050911"
			/>
			<path
				d="M253.4 284.906C253.906 285.88 254.257 286.933 254.413 288.024C254.452 288.219 254.725 288.141 254.686 287.946C254.491 286.816 254.14 285.763 253.634 284.75C253.595 284.594 253.322 284.75 253.4 284.906Z"
				fill="#050911"
			/>
			<path
				d="M252.153 287.985C252.27 288.297 252.465 288.609 252.699 288.843C252.816 288.959 253.011 288.804 252.933 288.648C252.777 288.336 252.699 287.985 252.699 287.634C252.66 287.673 252.582 287.751 252.543 287.79C252.777 287.79 253.011 287.751 253.206 287.751C253.284 287.751 253.362 287.673 253.362 287.556C253.284 287.322 253.245 287.05 253.167 286.816C253.128 286.894 253.089 286.933 253.011 287.011C253.284 287.011 253.518 287.011 253.79 287.011C253.985 287.011 253.985 286.699 253.79 286.699C253.518 286.699 253.284 286.699 253.011 286.699C252.933 286.699 252.855 286.777 252.855 286.894C252.933 287.128 252.972 287.4 253.05 287.634C253.089 287.556 253.128 287.517 253.206 287.439C252.972 287.439 252.738 287.478 252.543 287.478C252.465 287.478 252.387 287.556 252.387 287.634C252.387 288.063 252.465 288.414 252.66 288.804C252.738 288.726 252.816 288.687 252.894 288.609C252.699 288.375 252.504 288.102 252.387 287.79C252.309 287.673 252.075 287.829 252.153 287.985Z"
				fill="#050911"
			/>
			<path
				d="M247.631 300.106C246.852 299.795 246.072 299.405 245.371 298.937C245.215 298.82 245.059 299.093 245.215 299.171C245.956 299.639 246.735 300.028 247.553 300.379C247.748 300.457 247.826 300.184 247.631 300.106Z"
				fill="#050911"
			/>
			<path
				d="M247.008 300.691C246.579 300.769 246.19 300.886 245.839 301.081C245.683 301.159 245.8 301.432 245.995 301.315C246.346 301.12 246.696 301.003 247.086 300.964C247.242 300.925 247.164 300.652 247.008 300.691Z"
				fill="#050911"
			/>
			<path
				d="M251.412 299.171C252.192 298.547 253.01 298.002 253.868 297.456C254.023 297.378 253.868 297.105 253.712 297.222C252.815 297.768 251.997 298.353 251.178 298.976C251.061 299.093 251.256 299.288 251.412 299.171Z"
				fill="#050911"
			/>
			<path
				d="M252.075 299.834C252.971 299.6 253.867 299.366 254.725 299.171C254.92 299.132 254.842 298.859 254.647 298.898C253.751 299.132 252.854 299.366 251.997 299.561C251.802 299.6 251.88 299.912 252.075 299.834Z"
				fill="#050911"
			/>
			<path
				d="M247.749 302.445C249.074 301.977 249.386 300.574 249.425 299.327C249.425 299.132 249.152 299.132 249.113 299.327C249.035 300.418 248.801 301.704 247.632 302.133C247.476 302.211 247.554 302.523 247.749 302.445Z"
				fill="#050911"
			/>
			<path
				d="M247.748 302.445C248.489 302.172 249.035 301.549 249.19 300.769C249.229 300.574 248.957 300.496 248.918 300.691C248.801 301.393 248.372 301.938 247.709 302.172C247.514 302.25 247.592 302.523 247.748 302.445Z"
				fill="#050911"
			/>
			<path
				d="M242.37 288.336C243.266 289.31 243.929 290.401 244.397 291.649C244.475 291.805 244.748 291.766 244.67 291.571C244.202 290.285 243.5 289.154 242.565 288.141C242.448 288.024 242.253 288.219 242.37 288.336Z"
				fill="#050911"
			/>
			<path
				d="M243.929 290.479C243.695 290.518 243.461 290.518 243.227 290.557C243.11 290.557 243.033 290.713 243.11 290.791C243.266 290.908 243.383 291.142 243.305 291.337C243.266 291.493 243.11 291.571 243.11 291.727C243.11 291.805 243.149 291.922 243.227 291.961C243.305 292.038 243.461 292.077 243.461 292.155C243.461 292.35 243.773 292.35 243.773 292.155C243.773 291.999 243.695 291.961 243.617 291.844C243.578 291.805 243.461 291.766 243.461 291.727C243.422 291.61 243.539 291.532 243.578 291.454C243.695 291.142 243.617 290.752 243.344 290.557C243.305 290.635 243.266 290.713 243.227 290.791C243.461 290.752 243.695 290.752 243.929 290.713C244.124 290.752 244.124 290.44 243.929 290.479Z"
				fill="#050911"
			/>
			<path
				d="M287.231 255.44C296.118 257.935 305.004 260.468 313.891 262.963C316.424 265.418 320.361 268.77 325.856 271.498C335.327 276.214 346.786 277.812 347.721 275.591C348.15 274.616 345.734 272.434 340.901 268.068C336.769 264.366 334.937 263.235 332.014 260.546C329.871 258.558 326.909 255.557 323.829 251.309C320.283 251.27 315.995 250.919 311.24 249.906C303.562 248.23 297.482 245.424 293.273 243.007C291.246 247.178 289.219 251.309 287.231 255.44Z"
				fill="white"
			/>
			<path
				d="M201.486 156.949C195.522 166.187 199.888 177.684 204.72 190.312C211.619 208.475 222.22 220.479 227.638 226.092C251.881 251.27 281.658 262.027 298.807 266.704C300.561 258.675 302.276 250.607 304.03 242.578C292.182 236.148 283.451 229.132 277.488 223.675C267.471 214.516 250.712 199.199 243.696 175.424C239.37 160.691 242.098 154.026 235.667 149.271C226.703 142.723 208.423 146.153 201.486 156.949Z"
				fill="#FF692A"
			/>
			<path
				opacity="0.14"
				d="M240.656 161.315C237.382 166.615 233.251 175.229 234.459 185.168C235.862 196.665 243.657 203.954 262.21 220.323C278.501 234.666 292.026 246.32 301.497 254.388C302.316 250.452 303.095 246.554 303.914 242.617C297.755 239.149 289.765 234.082 281.152 226.988C276.397 223.052 269.459 217.283 262.015 208.163C256.87 201.849 249.971 193.274 245.177 180.218C242.41 172.656 241.241 165.992 240.656 161.315Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.14"
				d="M251.335 193.859C252.387 197.484 254.258 202.707 257.649 208.436C260.611 213.425 264.782 218.881 279.358 231.276C285.166 236.187 292.883 242.462 302.432 249.36C302.978 247.139 303.484 244.917 304.03 242.695C298.651 239.733 294.325 236.927 291.246 234.783C281.697 228.158 275.149 221.883 271.212 218.063C268.796 215.724 266.146 213.113 262.833 209.371C257.688 203.408 253.946 197.952 251.335 193.859Z"
				fill="#6D6C6C"
			/>
			<path
				d="M165.94 245.502C170.89 249.945 175.84 254.388 180.79 258.831C175.373 278.475 173.736 288.687 175.918 289.583C178.53 290.635 186.636 278.085 200.278 251.933C191.469 245.775 182.7 239.616 173.891 233.497C171.202 237.473 168.552 241.487 165.94 245.502Z"
				fill="white"
			/>
			<path
				d="M201.057 148.102C183.674 160.496 170.851 169.694 164.459 174.255C141.035 191.014 129.303 199.589 128.251 208.046C126.224 224.533 144.893 241.604 149.96 246.242C154.715 250.608 159.236 253.609 162.432 255.479C165.005 254.934 172.137 253.141 178.1 246.632C183.323 240.981 184.843 234.783 185.35 232.172C178.763 226.014 172.137 219.817 165.55 213.659C178.568 207.267 189.247 202.356 196.575 199.082C206.903 194.444 210.528 193.08 216.102 189.143C223.156 184.193 232.549 177.451 235.2 165.836C235.862 162.913 237.655 155.196 233.602 149.739C228.535 142.879 215.283 141.749 201.057 148.102Z"
				fill="#FF692A"
			/>
			<path
				opacity="0.12"
				d="M220.856 156.716C222.182 157.651 233.835 166.148 231.887 174.294C231.653 175.19 231.068 177.061 225.612 181.816C218.323 188.208 211.463 191.871 208.034 193.625C200.706 197.406 195.405 199.667 190.222 201.927C184.181 204.539 175.489 208.436 164.888 213.464C171.591 219.661 178.256 225.858 184.96 232.055C184.726 233.848 184.22 236.109 183.206 238.564C181.647 242.384 179.503 245.151 177.944 246.866C175.723 242.618 172.644 237.629 168.395 232.562C161.224 223.948 153.546 218.414 147.816 214.984C161.848 208.982 180.829 198.731 199.264 181.348C208.228 172.851 215.322 164.355 220.856 156.716Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.19"
				d="M230.835 172.618C228.886 170.513 216.375 179.01 210.294 183.063C198.836 190.741 182.037 201.46 159.354 214.438C168.123 220.48 176.892 226.56 185.662 232.601C178.958 226.209 172.215 219.817 165.512 213.425C167.655 212.373 171.28 210.619 175.801 208.475C183.401 204.85 191.586 201.07 202.577 195.925C204.487 195.029 210.1 192.378 217.193 188.403C221.831 185.791 226.391 183.063 229.354 177.723C230.211 176.203 231.692 173.553 230.835 172.618Z"
				fill="#6D6C6C"
			/>
			<path
				d="M214.932 73.464C212.984 71.3593 208.93 72.2168 206.904 72.6455C199.927 74.0876 195.99 78.258 189.481 83.9874C185.974 87.0664 180.868 91.3148 174.281 96.1477C172.332 87.3782 170.422 78.6477 168.474 69.8782C169.253 68.9039 170.539 66.9941 170.968 64.3437C171.202 62.9406 171.787 57.9907 168.396 54.8727C164.654 51.4039 157.872 51.9105 154.754 55.4963C151.597 59.121 153.234 64.6555 154.014 67.3059C154.754 69.8003 155.885 71.7101 156.781 72.9573C156.508 75.7246 156.235 79.739 156.508 84.572C156.781 89.7557 157.093 95.0564 159.548 100.903C161.536 105.619 163.914 111.27 168.941 113.336C174.398 115.597 180.049 112.362 191.079 105.853C208.385 95.641 212.516 87.1834 213.49 85.1177C214.543 82.8961 217.544 76.3092 214.932 73.464Z"
				fill="white"
				stroke="#050911"
				strokeWidth="0.77951"
			/>
			<path
				d="M167.265 66.0586C165.94 64.9283 164.536 63.6421 164.069 61.8882C163.874 61.1087 163.718 60.0174 165.16 60.2123C165.511 60.2513 166.018 60.68 166.29 60.8749C167.109 61.4205 167.849 62.1221 168.512 62.8236C168.785 63.0965 169.175 62.6677 168.941 62.3949C168.083 61.4595 164.614 57.8737 163.562 60.1733C162.51 62.5118 165.355 65.1622 166.875 66.4094C167.148 66.7212 167.538 66.2925 167.265 66.0586Z"
				fill="#050911"
			/>
			<path
				d="M154.247 56.2367C154.442 59.0819 155.338 63.4082 159.158 62.7846C159.548 62.7067 159.353 62.161 159.002 62.2389C155.611 62.7846 154.988 58.6532 154.832 56.2367C154.793 55.8859 154.208 55.8859 154.247 56.2367Z"
				fill="#050911"
			/>
			<path
				d="M158.144 53.8202C159.82 56.1588 160.21 58.965 159.275 61.6933C159.158 62.0441 159.703 62.2 159.82 61.8492C160.795 59.004 160.366 55.9639 158.612 53.5084C158.417 53.1966 157.911 53.5084 158.144 53.8202Z"
				fill="#050911"
			/>
			<path
				d="M162.51 52.8459C163.991 55.5742 163.64 58.8091 161.614 61.1866C161.38 61.4595 161.77 61.8882 162.042 61.6154C164.225 59.082 164.654 55.5352 163.017 52.5731C162.861 52.2223 162.354 52.5341 162.51 52.8459Z"
				fill="#050911"
			/>
			<path
				d="M167.343 54.8336C168.006 56.3147 167.655 57.9906 166.486 59.082C166.213 59.3548 166.642 59.7445 166.915 59.5107C168.279 58.2245 168.668 56.2367 167.889 54.5218C167.694 54.171 167.187 54.4828 167.343 54.8336Z"
				fill="#050911"
			/>
			<path
				opacity="0.17"
				d="M158.028 53.1966C158.69 53.0017 159.509 52.7679 160.288 53.0797C162.393 53.9371 163.328 58.2634 161.458 60.524C160.717 61.4594 159.665 61.8102 159.002 62.0051C159.041 62.6676 159.08 63.759 159.158 65.1231C159.158 65.1231 159.353 68.7478 159.587 71.8269C160.21 80.0897 160.834 87.9627 164.186 96.1476C165.238 98.759 167.031 102.423 170.071 106.554C171.63 106.242 174.787 105.463 178.49 103.904C199.732 94.9004 210.411 70.9694 214.191 72.9182C214.893 73.269 215.478 74.6331 215.556 74.867C216.257 76.5819 215.906 78.2578 215.361 80.0507C213.958 84.5719 211.814 87.8848 211.19 88.8202C208.891 92.25 206.708 94.4717 206.124 95.0563C203.551 97.6287 201.252 99.1877 197.003 101.916C189.052 106.983 185.077 109.516 182.387 110.608C176.931 112.829 172.293 114.739 168.005 112.712C165.433 111.504 164.147 109.438 162.276 106.32C158.301 99.6164 157.56 93.0685 156.975 86.9884C156.625 83.5195 156.391 78.7645 156.781 73.0741C154.598 69.5663 153.74 66.4093 153.351 64.4215C152.844 61.8102 152.454 59.6276 153.546 57.25C153.857 56.7823 155.183 54.0151 158.028 53.1966Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.2"
				d="M215.633 76.8158C215.204 76.6209 213.879 78.6476 212.476 80.7523C210.449 83.8314 209.436 85.3904 207.76 88.8982C203.044 98.798 187.337 106.788 185.31 107.84C182.114 109.438 177.515 111.426 171.591 112.829C168.862 110.335 163.835 105.151 160.989 97.8236C159.43 93.8091 158.846 89.8336 158.729 89.171C158.3 86.4038 158.261 84.2601 158.222 81.8046C158.183 78.4138 158.3 75.4516 158.534 73.0741C157.677 71.4761 156.858 69.6443 156.079 67.6955C154.753 64.3046 153.896 61.1476 153.35 58.3804C153.194 60.563 153.233 63.798 154.325 67.5006C155.026 69.8392 155.923 71.749 156.78 73.1911C156.04 79.5051 156.312 84.7668 156.78 88.5084C156.78 88.5084 157.832 97.161 162.354 106.749C163.094 108.308 164.146 110.296 166.29 111.855C169.057 113.843 172.02 113.998 172.955 113.998C174.514 114.037 176.307 113.804 183.439 110.062C189.636 106.827 193.924 104.06 195.522 103.007C198.523 101.059 200.004 100.045 201.602 98.798C204.876 96.1866 210.215 91.8993 213.489 84.8837C215.165 81.22 216.179 77.0886 215.633 76.8158Z"
				fill="#6D6C6C"
			/>
			<path
				d="M218.44 64.2657C210.879 69.4495 201.759 77.6343 197.939 89.8726C195.133 98.876 196.965 104.917 196.575 119.455C196.341 127.757 195.483 139.8 192.443 154.533C194.236 156.443 204.916 167.317 221.363 166.927C231.575 166.693 238.902 162.211 242.02 160.029C239.682 145.374 240.305 133.681 241.319 125.613C243.15 111.309 247.905 103.007 247.867 86.7156C247.867 78.7646 246.775 72.2557 245.84 67.9294C243.774 66.3704 239.838 63.837 234.303 62.7457C226.898 61.3815 220.934 63.2913 218.44 64.2657Z"
				fill="white"
				stroke="#050911"
				strokeWidth="0.77951"
			/>
			<path
				opacity="0.13"
				d="M230.015 62.8236C230.093 66.6432 230.093 72.2557 229.47 78.9595C228.768 86.9494 227.131 97.9405 220.232 119.416C216.569 130.836 211.229 145.686 203.59 162.757C207.838 165.056 211.619 166.031 214.152 166.459C223.545 168.057 231.146 165.212 234.77 163.809C237.966 162.562 240.461 161.159 242.176 160.106C241.513 156.092 240.811 150.596 240.578 143.971C240.461 140.853 240.266 130.68 242.682 116.493C244.904 103.397 246.658 102.696 247.71 94.004C248.996 83.1298 247.476 73.8927 245.878 67.5786C243.929 66.2924 240.889 64.5775 236.875 63.6031C234.225 62.9795 231.847 62.8236 230.015 62.8236Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.15"
				d="M236.602 63.876C236.992 71.1644 237.148 80.6354 236.33 91.6265C235.94 97.0051 235.082 107.723 232.354 119.65C229.899 130.485 224.793 146.933 213.334 165.641C216.179 166.304 220.311 166.927 225.222 166.421C233.173 165.602 238.941 162.328 241.864 160.34C239.526 143.737 240.617 130.719 242.137 121.755C243.579 113.258 245.255 109.049 246.385 99.8114C247.983 86.4428 246.853 75.2958 245.606 67.8125C244.709 66.994 243.228 65.7858 241.124 64.8893C239.331 64.2268 237.733 63.9929 236.602 63.876Z"
				fill="#6D6C6C"
			/>
			<path
				d="M240.266 69.0206C245.878 69.0206 253.205 69.6053 261.507 71.7879C272.109 74.5941 280.254 78.9594 285.672 82.4672C287.465 87.3001 290.739 98.2133 287.699 111.309C286.179 117.935 283.528 123.041 281.268 126.548C281.112 130.524 282.008 133.135 282.905 134.811C283.918 136.799 285.477 138.592 284.854 140.268C284.542 141.125 283.762 141.632 283.178 141.944C283.139 142.061 282.671 142.957 281.619 143.23C280.41 143.503 279.553 142.723 279.475 142.645C279.241 142.84 278.228 143.815 276.63 143.815C274.876 143.854 273.784 142.723 273.59 142.528C273.239 142.684 272.381 142.918 271.329 142.723C269.887 142.45 269.107 141.437 268.874 141.164C268.055 140.502 265.95 138.514 265.756 135.591C265.678 134.499 265.911 133.486 266.535 131.966C267.315 130.095 268.913 127.016 272.303 123.781C273.356 118.87 274.135 112.517 273.707 105.151C273.473 101.37 272.966 97.9794 272.342 94.9783C266.418 92.7957 259.091 90.73 250.555 89.6776C247.125 89.2489 243.851 89.054 240.811 89.0151C235.627 86.1699 233.055 80.6743 234.263 75.9193C235.511 71.5151 239.525 69.4104 240.266 69.0206Z"
				fill="white"
				stroke="#050911"
				strokeWidth="0.77951"
			/>
			<path
				d="M267.665 132.512C267.003 135.552 266.925 138.67 268.835 141.281C269.069 141.593 269.575 141.281 269.341 140.969C267.51 138.475 267.588 135.552 268.211 132.668C268.289 132.317 267.743 132.122 267.665 132.512Z"
				fill="#050911"
			/>
			<path
				d="M271.095 133.876C271.134 136.799 271.601 140.268 273.55 142.567C273.784 142.84 274.213 142.45 273.979 142.139C272.147 139.995 271.718 136.604 271.679 133.876C271.679 133.486 271.095 133.486 271.095 133.876Z"
				fill="#050911"
			/>
			<path
				d="M275.226 134.383C275.499 137.306 276.63 141.086 279.241 142.762C279.553 142.957 279.865 142.45 279.553 142.256C277.136 140.697 276.084 137.072 275.811 134.383C275.772 133.993 275.187 133.993 275.226 134.383Z"
				fill="#050911"
			/>
			<path
				d="M278.656 134.733C279.124 137.657 280.605 140.229 282.866 142.139C283.138 142.373 283.567 141.983 283.294 141.71C281.073 139.878 279.709 137.384 279.241 134.577C279.163 134.188 278.617 134.344 278.656 134.733Z"
				fill="#050911"
			/>
			<path
				opacity="0.17"
				d="M235.043 74.6721C241.669 74.1264 253.011 74.1264 265.756 78.9984C273.239 81.8826 279.046 85.5852 283.139 88.7423C285.516 111.153 282.164 120.702 278.384 125.184C277.565 126.12 274.33 129.706 273.941 134.85C273.629 138.748 274.993 143.503 276.903 143.776C277.682 143.893 278.423 143.23 279.007 142.567C280.138 143.386 280.995 143.347 281.307 143.269C281.58 143.23 281.969 143.113 283.451 141.983C284.62 141.086 285.204 140.619 285.321 140.385C286.062 138.943 284.035 136.994 282.71 134.461C281.853 132.824 280.995 130.329 281.112 126.665C283.801 122.378 288.868 112.868 288.907 100.006C288.946 92.5229 287.231 86.4427 285.75 82.4282C282.281 80.1287 279.202 78.5697 276.942 77.5173C276.357 77.2445 276.279 77.1665 266.691 73.6198C262.677 72.1387 261.858 71.8269 260.338 71.4761C258.233 70.9694 258.116 71.1253 253.284 70.3068C250.789 69.8781 248.295 69.5663 245.8 69.1376C245.138 69.0207 242.643 68.6309 240.461 69.2935C240.149 69.4104 239.408 69.6053 238.59 70.1509C238.395 70.3068 237.81 70.6966 236.992 71.593C236.485 72.2946 235.745 73.269 235.043 74.6721Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.14"
				d="M285.36 82.9739C286.101 89.8725 286.178 95.7189 286.101 100.084C285.984 105.58 285.906 108.737 284.892 113.024C283.567 118.675 281.268 123.664 281.034 124.171C279.825 126.704 279.631 126.704 278.968 128.497C278.033 131.031 276.162 135.981 278.383 139.8C278.656 140.307 280.215 142.996 282.398 142.762C284.425 142.567 285.477 139.917 285.516 139.8C284.58 138.202 283.567 136.136 282.71 133.72C281.969 131.576 281.501 129.589 281.229 127.913C283.489 124.288 286.256 118.948 287.776 112.011C290.622 98.72 287.192 87.8068 285.36 82.9739Z"
				fill="#6D6C6C"
			/>
			<path
				d="M209.319 37.6453C200.745 39.0094 198.172 51.0918 197.9 52.3C197.315 55.0283 195.795 62.1609 200.511 67.4615C205.149 72.6842 212.32 72.0996 213.217 72.0217C215.01 71.8657 219.258 71.1252 222.493 67.7733C228.184 61.966 227.56 51.6764 223 45.1286C222.454 44.388 216.764 36.476 209.319 37.6453Z"
				fill="#F79222"
			/>
			<path
				d="M213.997 53.0406C213.802 54.7165 214.62 55.574 216.803 60.1341C218.518 63.7199 219.648 66.721 220.35 68.6308C221.753 68.9426 225.455 69.6052 229.587 67.8513C232.393 66.682 234.186 64.8891 235.121 63.7978C233.874 61.7711 232.198 58.77 230.834 54.9114C228.69 48.9871 229.197 46.6876 227.248 45.5183C223.351 43.2967 214.542 48.0127 213.997 53.0406Z"
				fill="white"
				stroke="#050911"
				strokeWidth="0.77951"
			/>
			<path
				d="M206.669 9.89477C203.629 11.9994 202.538 15.1565 200.433 21.2756C198.055 28.2133 196.496 34.2545 196.107 38.5418C196.029 39.1654 196.418 39.789 197.042 40.0228C197.86 40.3346 199.225 40.3346 200.004 40.4516C200.082 42.3614 200.433 45.0117 201.641 47.8959C204.019 53.6253 208.228 56.8213 210.177 58.0685C212.593 58.3023 218.673 58.5752 224.676 54.9115C228.651 52.456 230.717 49.4159 231.185 48.6754C234.225 44.0373 234.497 39.3992 234.497 37.4115C235.433 37.3335 236.914 37.0997 238.356 36.1253C241.318 34.1375 243.54 29.5384 241.669 27.005C240.266 25.1732 236.758 24.6665 233.445 26.3035C233.367 24.8614 233.094 22.8736 232.354 20.5741C232.159 20.0284 230.795 15.819 228.261 12.9738C223.35 7.51727 212.827 5.56849 206.669 9.89477Z"
				fill="white"
				stroke="#050911"
				strokeWidth="0.77951"
			/>
			<path
				d="M216.452 59.2377C220.311 58.887 223.779 57.289 226.547 54.5607C227.092 54.015 226.235 53.1965 225.728 53.7422C223.195 56.2756 220.038 57.7567 216.452 58.1075C215.711 58.1075 215.672 59.2767 216.452 59.2377Z"
				fill="#050911"
			/>
			<path
				d="M217.037 60.2121C221.051 59.0818 224.598 56.9771 227.482 53.937C227.989 53.3914 227.17 52.5729 226.663 53.1185C223.935 56.0027 220.583 58.0295 216.764 59.0818C215.984 59.2767 216.296 60.407 217.037 60.2121Z"
				fill="#050911"
			/>
			<path
				d="M198.679 26.0306C198.133 25.5239 193.885 21.3536 194.509 15.2344C195.21 8.72549 200.862 5.60745 201.797 5.10077C206.786 2.37249 211.697 3.50277 212.827 3.81458C214.581 2.72326 217.738 1.08629 221.908 0.579613C228.105 -0.199897 232.938 1.82683 234.887 2.80122C237.46 3.58073 241.123 5.10077 244.163 8.37471C248.412 12.9348 247.437 16.7155 251.803 24.3936C253.518 27.4337 253.44 26.4593 259.948 35.1899C265.795 43.0239 268.718 46.9994 269.186 49.8836C270.16 56.1977 266.769 62.7455 262.56 66.6041C254.375 74.0874 239.798 74.3992 232.861 66.8769C227.131 60.6798 228.417 51.1698 228.807 48.1297C229.509 42.946 231.457 39.0095 232.861 36.7099C233.523 36.8268 234.536 36.9827 235.667 36.7099C237.81 36.2422 240.383 34.4883 240.85 31.6821C240.889 31.4872 241.435 27.6676 238.707 26.2255C236.992 25.3291 234.731 25.7188 233.172 27.2389C231.224 26.5763 227.989 25.2901 224.793 22.5618C221.597 19.8335 219.765 16.9103 218.829 15.0785C217.426 14.4549 214.659 13.5585 211.268 13.9872C204.603 14.8446 201.018 20.2622 200.472 21.1197C199.887 22.7177 199.264 24.3547 198.679 26.0306Z"
				fill="#F79222"
			/>
			<path
				d="M207.371 29.6553C207.254 29.2655 207.176 28.9147 207.176 28.525C207.176 28.2911 207.176 28.0573 207.215 27.8234C207.254 27.5896 207.371 27.4727 207.176 27.3168C206.903 27.1219 207.059 27.5116 207.098 27.6675C207.098 27.8624 207.098 28.0573 207.098 28.2911C207.098 28.486 207.098 29.3825 206.903 29.4994C207.059 29.4604 207.215 29.4604 207.371 29.4214C207.488 29.5773 207.527 29.5773 207.488 29.4214C207.488 29.3045 207.449 29.2266 207.449 29.1096C207.41 28.8758 207.371 28.6809 207.371 28.447C207.371 28.0183 207.41 27.5896 207.644 27.2388C208.033 26.5762 207.02 25.9916 206.63 26.6542C206.319 27.1998 206.202 27.8234 206.202 28.486C206.202 29.0317 206.241 29.8502 206.591 30.2789C206.903 30.7076 207.488 30.6686 207.839 30.2789C208.267 29.8112 208.306 28.9147 208.345 28.2911C208.384 27.7455 208.423 27.0049 208.072 26.5372C207.761 26.1085 207.215 26.0306 206.786 26.3424C205.812 27.0829 206.007 28.9927 206.319 30.0061C206.435 30.6686 207.566 30.3568 207.371 29.6553Z"
				fill="#050911"
			/>
			<path
				d="M204.642 24.6664C206.435 23.2633 209.124 23.6531 210.41 25.4849C210.722 25.9526 211.502 25.4849 211.151 25.0562C209.475 22.7177 206.24 22.2889 203.979 24.0428C203.59 24.3936 204.213 25.0172 204.642 24.6664Z"
				fill="#050911"
			/>
			<path
				d="M201.134 45.3625C203.629 44.583 205.772 43.1799 207.448 41.2311C207.799 40.8024 207.214 40.1788 206.825 40.6075C205.227 42.4783 203.239 43.8035 200.9 44.544C200.355 44.6609 200.589 45.5184 201.134 45.3625Z"
				fill="#050911"
			/>
			<path
				d="M207.331 33.4359C207.331 33.319 207.292 33.358 207.214 33.5918C207.136 33.6308 207.059 33.6308 206.981 33.6698C206.981 33.6698 206.981 33.6698 207.02 33.6698C206.942 33.6308 206.864 33.6308 206.786 33.5918L206.747 33.5528C206.708 33.4749 206.669 33.4359 206.63 33.358C206.63 33.3969 206.63 33.3969 206.63 33.319C206.669 33.241 206.669 33.1631 206.708 33.0851C206.747 33.0462 206.825 32.9682 206.864 32.9292C206.981 32.9292 207.097 32.8903 207.214 32.8903C207.214 32.8903 207.214 32.8903 207.175 32.8903C206.63 32.7733 206.396 33.5918 206.942 33.7477C207.448 33.8646 207.721 33.241 207.37 32.8903C207.214 32.7344 206.942 32.7344 206.747 32.8123C206.552 32.9292 206.474 33.1631 206.474 33.3969C206.435 34.0206 207.331 34.0206 207.331 33.4359Z"
				fill="#050911"
			/>
			<path
				d="M211.19 30.201C211.19 30.201 211.229 30.201 211.229 30.162C211.307 30.162 211.385 30.162 211.463 30.162C211.658 30.201 211.736 30.4348 211.619 30.5907C211.58 30.6297 211.502 30.7077 211.463 30.7466C211.424 30.7466 211.385 30.7856 211.307 30.7856C211.229 30.7466 211.151 30.7466 211.073 30.7077L211.034 30.6687C210.995 30.5907 210.956 30.5518 210.917 30.4738C210.917 30.3569 210.917 30.2789 210.995 30.201C211.073 30.162 211.112 30.123 211.19 30.0841C211.229 30.0841 211.229 30.0841 211.268 30.0841C211.034 30.0451 210.839 30.3179 210.839 30.5128C210.839 30.7856 211.034 30.9025 211.268 30.9415C211.58 30.9805 211.814 30.6687 211.814 30.3569C211.814 30.0061 211.424 29.7723 211.112 29.8892C210.45 30.162 211.034 31.2923 211.619 30.8636C211.814 30.7466 211.892 30.4738 211.775 30.2789C211.697 30.162 211.385 30.0841 211.19 30.201Z"
				fill="#050911"
			/>
			<path
				d="M211.113 34.4103C211.269 34.4883 211.269 34.7221 211.113 34.8001C210.879 34.6831 210.684 34.5662 210.45 34.4103C210.45 34.5272 210.45 34.6442 210.45 34.7611C210.645 34.6052 210.801 34.4883 210.996 34.3324C211.035 34.4103 211.035 34.4493 211.074 34.4883C211.035 34.2544 210.879 34.0595 210.645 34.0595C210.411 34.0595 210.177 34.2544 210.216 34.4883C210.255 34.8001 210.45 35.1119 210.762 35.1508C211.035 35.1898 211.308 35.0339 211.308 34.7221C211.308 34.6052 211.308 34.4883 211.308 34.3713C211.308 34.0206 210.918 33.8257 210.645 33.9816C210.216 34.2154 210.216 34.839 210.645 35.1119C211.191 35.4237 211.619 34.6831 211.113 34.4103Z"
				fill="#050911"
			/>
			<path
				opacity="0.15"
				d="M218.557 14.9615C219.219 17.0662 220.038 20.1063 220.7 23.809C222.103 31.5651 223.467 38.5807 220.7 45.5963C218.323 51.6765 214.035 55.7299 210.645 58.1464C213.373 58.0295 216.686 57.6397 220.349 56.5874C222.103 56.0807 223.701 55.4961 225.143 54.9114C225.689 56.9382 226.235 59.6664 226.235 62.9404C226.235 65.2399 225.962 67.2667 225.65 68.9036C226.391 68.9426 227.794 68.9426 229.353 68.319C230.756 67.7344 231.691 66.9159 232.237 66.4092C233.055 67.2667 234.342 68.358 236.057 69.4103C242.254 73.074 248.724 72.0996 250.516 71.7878C252.075 71.515 260.065 70.0339 265.249 62.8235C266.691 60.8357 269.537 56.8212 269.069 51.2088C268.64 45.9471 265.483 42.5172 264.392 41.3869C263.807 42.829 262.209 46.103 258.662 48.5584C258 49.0261 252.504 52.6898 245.995 50.9359C239.954 49.3379 235.355 43.6475 234.498 36.7099C235.082 36.7099 236.057 36.6709 237.148 36.2422C237.694 36.0083 239.603 35.1119 240.5 32.8903C240.656 32.4615 241.396 30.5907 240.5 28.564C240.344 28.2132 239.369 26.0306 237.031 25.6798C234.926 25.368 233.367 26.8101 233.211 27.005C230.912 26.2644 226.897 24.6664 223.156 21.0417C220.895 18.8981 219.453 16.6765 218.557 14.9615Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.23"
				d="M237.109 36.1252C235.628 35.7745 234.147 35.4237 232.666 35.0339C232.082 35.8524 231.263 37.0607 230.406 38.6197C228.301 42.4003 227.872 44.7388 226.703 47.3502C225.768 49.4549 224.092 52.3001 220.974 55.2622C222.767 54.6386 224.559 53.976 226.352 53.3524C227.21 55.1063 228.613 58.5751 228.262 63.0573C228.106 65.201 227.599 66.9938 227.054 68.319C228.808 67.7344 230.562 67.1108 232.316 66.5261C231.458 65.3959 230.289 63.642 229.431 61.2645C228.223 57.8736 228.301 55.0673 228.34 52.5339C228.379 51.4036 228.535 47.8569 229.899 43.4526C230.756 40.7633 231.77 38.5807 232.666 36.9827C233.251 37.0607 234.147 37.0996 235.161 36.8658C235.979 36.6709 236.642 36.3981 237.109 36.1252Z"
				fill="#6D6C6C"
			/>
			<path
				opacity="0.25"
				d="M199.887 46.4148C199.537 47.3112 197.822 51.9883 200.238 56.6264C202.07 60.1731 206.474 63.9927 210.995 62.8235C214.035 62.044 215.672 59.3936 216.218 58.4972C217.699 62.044 219.18 65.5907 220.661 69.1375C220.272 69.4493 219.648 69.917 218.868 70.3458C213.295 73.5417 207.137 71.515 206.669 71.3591C205.461 70.9304 201.68 69.5662 199.225 65.7077C196.925 62.044 197.159 58.3413 197.354 55.4961C197.588 51.4037 198.913 48.2856 199.887 46.4148Z"
				fill="#6D6C6C"
			/>
			<path
				d="M2.08561 142.217C15.2204 141.554 27.6535 146.192 39.4241 151.493C51.3506 156.871 62.9653 162.718 75.5934 166.303C83.0377 168.447 90.6769 169.889 98.3941 170.786C99.1346 170.864 99.1346 169.694 98.3941 169.616C84.3629 168.018 70.7215 164.277 57.7816 158.625C46.2059 153.558 35.0199 147.439 22.8596 143.815C16.1168 141.788 9.14017 140.697 2.08561 141.047C1.3061 141.047 1.3061 142.217 2.08561 142.217Z"
				fill="#D0D5DD"
			/>
			<path
				d="M78.01 148.92C101.123 159.249 127.08 157.963 151.479 153.675C159.118 152.35 166.718 150.752 174.28 149.115C175.02 148.959 174.708 147.829 173.968 147.985C149.218 153.247 123.611 158.586 98.3552 153.948C91.5735 152.701 84.9087 150.713 78.6336 147.907C77.9321 147.634 77.3474 148.648 78.01 148.92Z"
				fill="#D0D5DD"
			/>
			<path
				d="M367.559 193.859C389.97 187.272 414.135 187.857 436.195 195.535C436.896 195.769 437.208 194.639 436.506 194.405C414.251 186.688 389.853 186.103 367.247 192.729C366.545 192.924 366.857 194.054 367.559 193.859Z"
				fill="#D0D5DD"
			/>
		</svg>
	);
};

export default Jogging;
