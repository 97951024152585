import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutPreview from "../components/AboutPreview";
import WaitlistPreview from "../components/WaitlistPreview";
import PreviewFooter from "../components/PreviewFooter";
import BlogItem from "../components/BlogItem";
import PageFooter from "../components/PageFooter";
import blog1 from "../assets/blog1.png";

const Post = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const [post, setPost] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchPosts = async () => {
		setLoading(true);
		const response = await fetch(`http://localhost:8010/feed/p/${id}`);
		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong");
		} else {
			setPost(data.post);
		}
		setLoading(false);
	};

	const handleBack = (e) => {
		e.preventDefault();
		navigate(-1);
	};

	useEffect(() => {
		fetchPosts();
	}, []);

	return (
		<>
			<div className="content">
				<Container className="post-container pt-5 pb-5">
					<Row>
						<Col className="col-lg-8 col-12">
							<div className="post-back-container full">
								<a href="#" className="flex-row-left" onClick={handleBack}>
									<i className="back-arrow ri-arrow-left-line opacity-5" />
									<span className="opacity-5">Back to Blog</span>
								</a>
							</div>
							<div
								className="blog-highlight-img rounded bg-cover"
								style={{ background: `url(${post.img_url})` }}
							/>
							<div className="blog-item-header flex-row-left">
								<span className="blog-item-date label-md opacity-5 upper">
									{post.created_at}
								</span>
							</div>
							<div className="blog-item-title full">
								<span className="title-md text-medium-rubik">{post.title}</span>
							</div>
							<div className="blog-content">
								<div
									dangerouslySetInnerHTML={{
										__html: DOMPurify.sanitize(post.content),
									}}
								/>
							</div>
							<div className="recommended-posts">
								<h2 className="title-md text-medium-rubik recommended-title">
									Recommendations
								</h2>
								<BlogItem
									tags={["New"]}
									createdAt="January 1, 2023"
									title="The Classroom Model"
									desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
									url="#"
									img={blog1}
								/>
								<BlogItem
									tags={["New"]}
									createdAt="January 1, 2023"
									title="The Classroom Model"
									desc="The traditional classroom model that we are familiar with today
								has been around for centuries, but is it really the best way to
								educate students? While the classroom model has its benefits, it
								could be argued that it doesn’t model that we are familiar with
								today has been around for centuries, but is it really the best
								way to educate students? While the classroom model has its
								benefits, it could be argued that it doesn’t"
									url="#"
									img={blog1}
								/>
							</div>
						</Col>
						<Col className="preview-col col-lg-4 col-12">
							<AboutPreview />
							<WaitlistPreview />
							<PreviewFooter className="m-hidden" />
						</Col>
					</Row>
				</Container>
			</div>
			<PageFooter className="hidden" />
		</>
	);
};

export default Post;
