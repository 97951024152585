import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";

const WaitlistForm = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [sending, setSending] = useState(false);

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSending(true);

		const response = await fetch(
			`${process.env.REACT_APP_SENDGRID_URI}/v3/marketing/contacts`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_KEY}`,
				},
				body: JSON.stringify({
					contacts: [{ email: email }],
					list_ids: [process.env.REACT_APP_SENDGRID_LIST_ID],
				}),
			}
		);
		if (!response.ok) {
			dangerSnackbar("Something went wrong, please try again.");
		} else {
			setEmail("");
			navigate("/waitlist/success");
		}
		setSending(false);
	};

	return (
		<Form
			className="hero-form bg-white rounded border flex-row-left"
			onSubmit={handleSubmit}
		>
			<Form.Control
				className="hero-input"
				placeholder="Enter email address"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<Button
				type="submit"
				className="hero-form-btn btn-default rounded"
				disabled={sending}
			>
				Join waitlist
			</Button>
		</Form>
	);
};

export default WaitlistForm;
