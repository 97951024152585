const FacebookIcon = ({ className }) => {
	return (
		<svg
			className={className}
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_134_857)">
				<path
					d="M10.5001 1.9611C5.89758 1.9611 2.16675 5.69193 2.16675 10.2944C2.16675 14.4536 5.21425 17.9011 9.19841 18.5269V12.7028H7.08175V10.2944H9.19841V8.4586C9.19841 6.37026 10.4417 5.21693 12.3459 5.21693C13.2576 5.21693 14.2109 5.37943 14.2109 5.37943V7.42943H13.1609C12.1251 7.42943 11.8026 8.07193 11.8026 8.7311V10.2944H14.1134L13.7442 12.7028H11.8026V18.5269C15.7859 17.9019 18.8334 14.4528 18.8334 10.2944C18.8334 5.69193 15.1026 1.9611 10.5001 1.9611Z"
					fill="#050911"
					fillOpacity="0.5"
				/>
			</g>
			<defs>
				<clipPath id="clip0_134_857">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="translate(0.5 0.294434)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default FacebookIcon;
